<div>
  <div class="card">
    <div class="container">
      <h3>  Ajuste da Imagem </h3>
      <image-cropper [imageChangedEvent]="event" [maintainAspectRatio]="true" [resizeToWidth]="redWidth"
        [aspectRatio]="aspect" format="png" (imageCropped)="imageCropped($event)" [resizeToHeight]="redHeight"
        (imageLoaded)="imageLoaded()" [onlyScaleDown]="true" (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()">
      </image-cropper>
      <h4>Tamanho Recomendado: {{ redWidth }}x{{ redHeight }}</h4>
    </div>

  </div>
  <div class="btn-container">
    <button mat-flat-button class="btn1" (click)="exit()">Cancelar</button>
    <button mat-flat-button class="btn2" (click)="close()">Salvar</button>
  </div>
</div>