import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { ENV } from 'src/environments/environment-variables.token';
import { FormBuilder, Validators } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { UtilsProvider } from 'src/commons/utils';
import { VaquinhaService } from 'src/providers/vaquinha.service';
import { PoliticalService } from 'src/providers/political.service';

@Component({
  selector: 'app-search-candidates',
  templateUrl: './search-candidates.component.html',
  styleUrls: ['./search-candidates.component.scss'],
})
export class SearchCandidatesComponent implements OnInit {
  title = 'Quero Apoiar - Home';
  userForm = this.formBuilder.group({
    email: ['', [Validators.required]],
  });
  public elements = ['cadastro', 'comofunciona', 'contato'];
  public candidatos: any = [];

  constructor(
    private router: Router,
    private vaquinhaService: VaquinhaService,
    private formBuilder: FormBuilder,
    public utils: UtilsProvider,
    private politicalService: PoliticalService,
    public dialog: MatDialog,
    @Inject(DOCUMENT) private document: Document,
    @Inject(ENV) public ENV: any
  ) {}

  public limit = 8;
  public finalSearch: boolean = false;
  public keyword: any;
  private skip: any = -1;
  public listYear = [];
  public selectedYear: any;
  public alreadyList: any = [];
  public changeModelSearch: boolean = false;
  public numberOfNewRequest: number = 0;
  public restOfSkip: any;

  ngOnInit() {
    window.scrollTo(0, 0);
    const self = this;

    self.politicalService.getListElectionPeriod().then((res: any) => {
      const filter = res.itens.filter(
        (item) => item.year == new Date().getFullYear()
      );
      if (filter.length === 0) {
        this.selectedYear = res.itens[0].year;
      } else {
        this.selectedYear = filter[0].year;
      }
      this.listYear = res.itens;
    });
    this.makeRequest({ newRequest: true });
  }

  async expand() {
    this.makeRequest({ newRequest: false });
  }

  search() {
    this.skip = -1;
    this.finalSearch = false;
    this.changeModelSearch = false;
    this.alreadyList = [];
    this.numberOfNewRequest = 0;
    this.restOfSkip = null;
    this.makeRequest({ newRequest: true });
  }

  scrollTo(el: any) {
    if (this[el]) {
      this[el].nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  navigate(candidato: any) {
    this.router.navigate([candidato.url], candidato);
  }

  makeRequest({ newRequest }) {
    return new Promise(async (resolve, reject) => {
      this.skip += 1;

      const [err, request] = await this.utils.tryCatch(
        this.vaquinhaService.getElectionVaquinhaList({
          skip: this.skip,
          limit: this.limit,
          params: this.keyword,
          year: this.selectedYear,
          alreadySearch: this.alreadyList,
          changeModelSearch: this.changeModelSearch,
          restOfSkip: this.restOfSkip,
        })
      );

      if (request?.isNewSearch) {
        this.numberOfNewRequest += 1;
        this.changeModelSearch = true;
      }

      if (this.numberOfNewRequest <= 1) {
        if (request?.isNewSearch) this.skip = -1;
        this.alreadyList = [...this.candidatos, ...request.vaquinha]
          .filter((item) => item.totalCollected != 0)
          .map((item) => {
            return item._id;
          });
      }

      if (request?.rest) this.restOfSkip = request?.rest;

      if (err) {
        console.log('err make request', err);
        reject(err);
      }

      if (
        request.vaquinha.length == 0 ||
        request.vaquinha.length != this.limit
      ) {
        this.finalSearch = true;
      }

      if (newRequest) {
        this.candidatos = request.vaquinha;
      } else {
        this.candidatos.push(...request.vaquinha);
      }

      resolve(request);
    });
  }

  // REQUESIÇÃO ANTIGA ----------

  // search() {
  //   this.skip = 0;
  //   this.finalSearch = false;
  //   this.makeRequest({ newRequest: true });
  // }

  // async expand() {
  //   this.skip++;
  //   this.makeRequest({ newRequest: false });
  // }

  // makeRequest({ newRequest }) {
  //   return new Promise(async (resolve, reject) => {
  //     const [err, request] = await this.utils.tryCatch(
  //       this.vaquinhaService.getElectionVaquinha({
  //         skip: this.skip,
  //         limit: this.limit,
  //         params: this.keyword,
  //         year: this.selectedYear,
  //       })
  //     );
  //     console.log("selectedYear", this.selectedYear)

  //     if (err) {
  //       console.log('err make request', err);
  //       reject(err);
  //     }

  //     if (request.length == 0 || request.length != this.limit) {
  //       this.finalSearch = true;
  //     }

  //     if (newRequest) {
  //       this.candidatos = request;
  //     } else {
  //       this.candidatos.push(...request);
  //     }

  //     resolve(request);
  //   });
  // }
}
