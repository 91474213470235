import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { VaquinhaService } from 'src/providers/vaquinha.service';
import { MediaService } from 'src/providers/media.service';
import { UtilsProvider } from 'src/commons/utils';
import * as moment from 'moment';
import { AccountService } from 'src/providers/account.service.';
import { PoliticalService } from 'src/providers/political.service';
import { CandidateService } from 'src/providers/candidate.service';
import { AnalysisModalComponent } from 'src/app/analysis-modal/analysis-modal.component';

@Component({
  selector: 'app-main-vaquinha',
  templateUrl: './main-vaquinha.component.html',
  styleUrls: ['../../app.component.scss', './main-vaquinha.component.scss'],
})
export class MainVaquinhaComponent implements OnInit {
  public currentStep = 1;
  public stepper: Array<{}> = [
    // { index: 1, title: 'Tipo de Vaquinha' },
    // { index: 2, title: 'Foto de capa' },
    // { index: 3, title: 'Vaquinha' },
    // { index: 4, title: 'Dados Bancários' },
    // { index: 5, title: 'Perfil do candidato' },
  ];

  public lastStepAccount: any = false;
  public selectedImage: any;
  // public croppedImage: any = '../../assets/no-avatar.png';
  public body: any = {
    type: {
      text: '',
      value: '',
      _id: '',
    },
    image:
      // 'https://s3.us-east-2.amazonaws.com/media.generic.moux/media/2023/1/31/4fyhan9oldknlp25.png',
      '../../assets/no-avatar.png',
    account: {
      agencia: '',
      banco: '',
      conta: '',
      digAgencia: '',
      digConta: '',
      tipoConta: '',
      tipoPessoa: '',
      docIdentidade: '',
      cartaoCnpj: '',
      endereco: {
        cep: '',
        bairro: '',
        cidade: '',
        complemento: '',
        logradouro: '',
        numero: '',
        pais: '',
      },
    },
    vaquinha: {
      description: '',
      endAt: null,
      initAt: null,
      paymentTypes: [],
      transparency: [],
      thankMessage: '',
      url: '',
    },
    cadidate: {},
  };

  constructor(
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<MainVaquinhaComponent>,
    public dialog: MatDialog,
    public utils: UtilsProvider,
    public vaquinhaProvider: VaquinhaService,
    public accountProvider: AccountService,
    public candidateProvider: CandidateService,
    public politicalProvicer: PoliticalService,
    public mediaProvider: MediaService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    if(this.data.eleitoral == false){
      this.currentStep = 2;
      this.stepper =  [
        // { index: 1, title: 'Tipo de Vaquinha' },
        { index: 1, title: 'Foto de capa' },
        { index: 2, title: 'Vaquinha' },
        // { index: 3, title: 'Dados Bancários' },

      ]
    } else if (this.data.eleitoral == true) {
      this.data.name = 'Eleitoral'
      this.currentStep = 2;
      this.stepper =  [
        // { index: 1, title: 'Tipo de Vaquinha' },
        { index: 1, title: 'Foto de capa' },
        { index: 2, title: 'Vaquinha' },
        // { index: 3, title: 'Dados Bancários' },
        { index: 5, title: 'Perfil do candidato' }
      ]
    }
    console.log('data init 2', this.data);
    if (this.data.edit) {
      // this.stepper.shift();
      this.currentStep = 2;
      if (this.data.name != 'Normal') {
        // this.stepper.push({ index: 5, title: 'Perfil do candidato' });
        this.lastStepAccount = false;
      } else {
        this.lastStepAccount = true;
      }
    }

    this.getAccountAndCandidateProfile();
    this.step();
  }

  closeDialog() {
    this.dialogRef.close();
  }
  async back({ next }) {
    this.currentStep -= 1;
  }

  step() {
    console.log("this.data", this.data);
    console.log("this.data.name", this.data.name);
    if (this.data.name == 'Normal' && this.stepper.length == 4) {
      this.stepper.pop();
    }

    if (this.data.name == 'Eleitoral' && this.stepper.length <= 3) {
      // this.stepper.push({ index: 5, title: 'Perfil do candidato' });
    }
    if (this.data.name != 'Normal') this.lastStepAccount = false;
    if (this.data.name != 'Eleitoral') this.lastStepAccount = true;

    this.currentStep += 0;
    this.body.type = this.data.name;
  }

  getElectoralType(data) {
    if (this.data.name == 'Normal' && this.stepper.length == 5) {
      this.stepper.pop();
    }

    if (this.data.name == 'Eleitoral' && this.stepper.length <= 4) {
      this.stepper.push({ index: 5, title: 'Perfil do candidato' });
    }
    if (this.data.name != 'Normal') this.lastStepAccount = false;
    if (this.data.name != 'Eleitoral') this.lastStepAccount = true;

    this.currentStep += 1;
    this.body.type = data;
  }

  getImageVaquinha(data) {
    if (data.back) {
      return (this.currentStep -= 2);
    }
    this.currentStep += 1;
    this.body.image = data.file;
    if (this.data.edit) {
      this.data.image.link = data.file;
    }
  }

  getFormVaquinha(data) {
    console.log("this.body", data)
    if (data.message) {
      this.closeDialog();
      return this.utils.toast({
        message: data.message,
        action: '❌',
      });
    }
    if (data.back) {
      return (this.currentStep -= 1);
    }
    this.currentStep += 2;

    if (this.body.type == 'Normal') {
      this.body.vaquinha = {
        ...data,
        initAt: data.initAt,
        endAt: data.endAt,
      };
    }
    this.body.vaquinha = { ...data };
    if (this.data.edit) {
      this.data = {
        ...this.data,
        ...data,
      };
      console.log("this.bodya", this.data)
      console.log("this.body.vaquinha", this.body.vaquinha)
    }
  }

  getAccount(data) {
    if (data.back) {
      return (this.currentStep -= 1);
    }

    this.body.account = { ...data };
    if (this.data.edit) this.data.account = { ...data };

    if (this.lastStepAccount) {
      this.onSubmit(false);
    } else this.currentStep += 1;
  }

  getCandidateProfile(data) {
    if (data.back) {
      return (this.currentStep -= 2);
    }
    this.currentStep += 1;
    this.body.candidate = { ...data };

    this.onSubmit(true);
  }

  async onSubmit(candidate) {
    if (this.data.edit) {
      await this.update(candidate);
    } else {
      await this.create(candidate);
    }

    this.utils.toast({
      message: `Vaquinha ${this.data.edit ? 'editada' : 'criada'} com sucesso.`,
      action: '✔️',
      duration: 8000,
    });

    this.closeDialog();

    // if( this.data.edit == false) {
    //   const dialogRef = this.dialog.open(AnalysisModalComponent, {});
    //   dialogRef.afterClosed().subscribe((result) => {});
    // }


  }

  async uploadDocImage(type, file) {
    return new Promise(async (resolve, reject) => {
      const [errUpload, upload] = await this.utils.tryCatch(
        this.mediaProvider.uploadImage({ file: file })
      );
      if (errUpload) {
        this.utils.toast({
          message: 'Erro ao enviar anexo de documento.',
          action: '❌',
        });
        reject();
      }
      console.log('upload_id', upload);
      resolve(upload.image._id);
    });
  }

  async update(candidate) {
    return new Promise(async (resolve, reject) => {
      console.log('this init edit', this.body);
      console.log('this init edit', this.data);
      const img = this.data.image.host + this.data.image.full;
      const params = {
        image: '',
        ...this.body.vaquinha,
        _id: this.data._id,
      };

      let [errUpload, upload] = ['', ''];
      if (this.body.image != img) {
        [errUpload, upload] = await this.utils.tryCatch(
          this.uploadImage(this.body.image)
        );
        if (errUpload) {
          this.utils.toast({
            message:
              'Erro ao editar a foto da vaquinha por favor selecione outra imagem ou tente novamente mais tarde.',
            action: '❌',
          });
        }
        params.image = upload;
      } else {
        delete params.image;
        delete this.body.image;
      }
      console.log('params vaquinha', params);
      const [errEditVaquinha, editVaquinha] = await this.utils.tryCatch(
        this.vaquinhaProvider.edit(params)
      );
      if (errEditVaquinha) {
        this.utils.toast({
          message: 'Erro ao editar a vaquinha, tente novamente mais tarde.',
          action: '❌',
        });
        reject();
      }

      // if (
      //   // typeof this.body.account.docIdentidade == 'string' &&
      //   this.body.account.docIdentidade.startsWith('data:image')
      // )
      //   this.body.account.docIdentidade = await this.uploadDocImage(
      //     'cpf',
      //     this.body.account.docIdentidade
      //   );

      // if (
      //   this.body.account.tipoPessoa == 'Jurídica' &&
      //   this.body.account.cartaoCnpj.startsWith('data:image')
      // ) {
      //   this.body.account.cartaoCnpj = await this.uploadDocImage(
      //     'cnpj',
      //     this.body.account.cartaoCnpj
      //   );
      // }
      // const [errEditAccount, editAccount] = await this.utils.tryCatch(
      //   this.accountProvider.editV2({
      //     ...this.body.account,
      //     vaquinhaRef: this.data._id,
      //   })
      // );
      // if (errEditAccount) {
      //   this.utils.toast({
      //     message:
      //       'Erro ao editar os dados bancários, tente novamente mais tarde.',
      //     action: '❌',
      //   });
      //   reject();
      // }

      if (candidate) {
        let [errUploadCandidate, uploadCandidate] = ['', ''];
        const paramsCandidate = {
          image: '',
          ...this.body.candidate,
          _id: this.data.candidate._id,
        };

        const img =
          this.data.candidate.image.host + this.data.candidate.image.full;
        if (this.body.candidate.image != img) {
          [errUploadCandidate, uploadCandidate] = await this.utils.tryCatch(
            this.uploadImage(this.body.candidate.image)
          );
          if (errUploadCandidate) {
            this.utils.toast({
              message:
                'Erro ao enviar foto do candidato, por favor selecione outra imagem ou tente novamente mais tarde.',
              action: '❌',
            });
            reject();
          }
          paramsCandidate.image = uploadCandidate;
        } else {
          delete paramsCandidate.image;
          delete this.body.cadidate.image;
        }

        const [errCandidate, updateCandidate] = await this.utils.tryCatch(
          this.politicalProvicer.editCandidateProfile(paramsCandidate)
        );
        if (errCandidate) {
          this.utils.toast({
            message:
              'Erro ao editar perfil do candidato, tente novamente mais tarde.',
            action: '❌',
          });
          reject();
        }
      }
      resolve(1);
    });
  }

  async create(candidate) {
    return new Promise(async (resolve, reject) => {
      const _user = JSON.parse(localStorage.getItem('user'));
      const [errUpload, upload] = await this.utils.tryCatch(
        this.uploadImage(this.body.image)
      );
      if (errUpload) {
        this.utils.toast({
          message:
            'Erro ao enviar foto da vaquinha por favor selecione outra imagem ou tente novamente mais tarde.',
          action: '❌',
        });
        reject();
      }

      const body = {
        ...this.body.vaquinha,
        _id: this.data._id,
        type: this.body.type,
        image: upload,
        typeRef: this.data.vaqType[0]._id
      };

      console.log("body", body)

      const [errVaquinha, vaquinha] = await this.utils.tryCatch(
        this.vaquinhaProvider.create(body)
      );
      if (errVaquinha) {
        this.utils.toast({
          message: 'Erro ao criar vaquinha, tente novamente mais tarde.',
          action: '❌',
        });
        reject();
      }

      // this.body.account.docIdentidade = await this.uploadDocImage(
      //   'cpf',
      //   this.body.account.docIdentidade
      // );

      // if (this.body.account.tipoPessoa == 'Jurídica') {
      //   this.body.account.cartaoCnpj = await this.uploadDocImage(
      //     'cnpj',
      //     this.body.account.cartaoCnpj
      //   );
      // }

      // const [errAccount, account] = await this.utils.tryCatch(
      //   this.accountProvider.createAccountV2({
      //     ...this.body.account,
      //     vaquinhaRef: vaquinha._id,
      //     user: _user.user._id,
      //   })
      // );
      // if (errAccount) {
      //   this.utils.toast({
      //     message: 'Erro ao criar dados bancários, tente novamente mais tarde.',
      //     action: '❌',
      //   });
      //   reject();
      // }

      if (candidate) {
        const [errUploadCandidate, uploadCandidate] = await this.utils.tryCatch(
          this.uploadImage(this.body.candidate.image)
        );
        if (errUploadCandidate) {
          this.utils.toast({
            message:
              'Erro ao criar foto do perfil do candidato, por favor selecione outra imagem ou tente novamente mais tarde.',
            action: '❌',
          });
          reject();
        }

        const [errCandidate, candidate] = await this.utils.tryCatch(
          this.politicalProvicer.createCandidateProfile({
            ...this.body.candidate,
            image: uploadCandidate,
            vaquinhaRef: vaquinha._id,
          })
        );
        if (errCandidate) {
          this.utils.toast({
            message:
              'Erro ao criar perfil do candidato, tente novamente mais tarde.',
            action: '❌',
          });
          reject();
        }
      }
      resolve(1);
    });
  }

  uploadImage(image) {
    return new Promise(async (resolve, reject) => {
      if (image) {
        const [err, res] = await this.utils.tryCatch(
          this.mediaProvider.uploadImage({ file: image })
        );
        if (err) {
          return this.utils.toast({
            message:
              'Erro ao enviar foto, por favor selecione outra imagem ou tente novamente mais tarde.',
            action: 'Ok',
            duration: 8000,
          });
        }
        const savedImg = res && res.image ? res.image._id : undefined;
        this.selectedImage = savedImg;
        resolve(savedImg);
      } else {
        reject();
      }
    });
  }

  async getAccountAndCandidateProfile() {
    if (!this.data.create) {
      const [errAccount, account] = await this.utils.tryCatch(
        this.accountProvider.getAccountV2({ vaquinhaRef: this.data._id })
      );
      if (errAccount) {
        this.utils.toast({
          message: 'Erro ao buscar dados da conta bancária.',
          action: '❌',
        });
      }

      const [errCandidate, candidateProfile] = await this.utils.tryCatch(
        this.candidateProvider.findOneV2(this.data._id)
      );
      if (errCandidate) {
        this.utils.toast({
          message: 'Erro ao buscar dados do candidato.',
          action: '❌',
        });
      }

      this.data.account = { ...account, edit: this.data.edit };
      this.data.candidate = { ...candidateProfile, edit: this.data.edit };
    }
  }
}
