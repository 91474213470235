import { Component, OnInit, Inject } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ENV } from '../environments/environment-variables.token';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Quero Apoiar';
  guideline: any;
  show = true;
  customLoadingTemplate: any;
  constructor(
    private metaTagService: Meta,
    @Inject(ENV) public ENV: any
  ){
    console.log(this.ENV);
    const href = location.pathname;
    console.log(href);
    if (href === '/guideline') {
      this.guideline = true;
    }
  }

  ngOnInit() {
    const image = '../../assets/image/no-avatar-width.jpg'
    this.metaTagService.addTags([
      { name: 'keywords', content: 'Angular SEO Integration, Music CRUD, Angular Universal' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Digamber Singh' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'date', content: '2019-10-31', scheme: 'YYYY-MM-DD' },
      { charset: 'UTF-8' },
      { itemprop: 'name', content: 'Quero Apoiar' },
      { itemprop: 'description', content: 'Quero Apoiar' },
      { property: 'fb:app_id', content: '257185048199787'},
			{ property: 'og:url', content: 'https://queroapoiar.com.br/'},
			{ property: 'og:type', content: 'website' },
			{ property: 'og:title', content: 'Quero Apoiar' },
      { property: 'og:description', content: 'Visite o site para apoiar seu candidato nessas eleições'},
      { property: 'og:image', content: '../assets/image/porquinho.png'},
      { property: 'og:image:type', content: 'image/png'},
      { property: 'og:image:width', content: '100'},
      { property: 'og:image:height', content: '100'},
			{ name: 'twitter:card', content: 'summary' },
			{ name: 'twitter.site', content: '@carlosmcdo' },
			{ name: 'twitter:creator', content: '@carlosmcdo'},
			{ name: 'twitter:title', content: 'Quero Apoiar'},
			{ name: 'twitter:description', content: 'Visite o site para apoiar seu candidato nessas eleições'}
    ]);
  }
}
