<!-- <div>
  <div class="card">
    <div class="container">
      <div class="header">
        <img class="logo" src="../../assets/logo/logo.svg" />
        <h3> Confirme o endereço de cobrança: </h3>
      </div>
      <div class="form">
        <default-form (keyup.enter)="performClick(register)" [forms]="formAddress" [step]="0"></default-form>
        <mat-checkbox (keyup.enter)="performClick(register)" class="example-margin" [(ngModel)]="terms"><p>Eu concordo com os <a> termos de uso da maquininha.</a></p></mat-checkbox>
      </div>
    </div>
  </div>
  <div class="btn-container">
    <button mat-flat-button #register class="btn2" (click)="onSubmit()">Pagar</button>
  </div>
</div> -->
<div>
  <div class="card">
    <div class="container full">
      <div>
        <img class="logo" src="../../assets/logo/logo.svg" />
        <h3> Notamos que você não possui um cpf cadastrado! </h3>
        <h3> Ele é um parametro obrigatório, por favor digite seu CPF abaixo: </h3>
      </div>
      
      <default-form [forms]="formPersonal"  [step]="0" *ngIf="formPersonal"></default-form>
      <br>
      <div class="btn-container">     
          <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <button mat-flat-button class="confirm-button full" (click)="onSubmit()">
                      Enviar</button>
              </div>
          </div>
      </div>
    </div>
    
  </div>
</div>