import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from '@angular/common';
import { MaquininhaComponent } from './maquininha.component';
import { MatExpansionModule } from '@angular/material/expansion';



@NgModule({
    imports: [CommonModule,
    MatExpansionModule],
    declarations: [MaquininhaComponent],
    providers: [],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})

export class MaquininhaModule {}