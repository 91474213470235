import { Injectable, Inject, ViewChild } from '@angular/core';
import { ENV } from '../environments/environment-variables.token';
// import { HttpProvider } from "./http";
// import { ExternalProvider } from "./external";

// import { NativeStorage } from "@ionic-native/native-storage";
/*
	Generated class for the AuthProvider provider.

	See https://angular.io/docs/ts/latest/guide/dependency-injection.html
	for more info on providers and Angular DI.
*/

@Injectable({
	providedIn: 'root'
})
export class GlobalProvider {
	public active_page;
	public application: boolean;
	public notifications_badges = 0;
	public banco: any;
	public saldo = 600;
	public resagates = true;
	private rootPages: any;
	public  showContent = true;
	public  showGrid = false;
	public showMyBox = false;

	// public Storage;
	public theme = {
		img: 'assets/imgs/unimed.jpg',
		title: 'Unimed',
		colors: {
			headerPrimary: '#224193',
			headerSecondary: '#1b284b',
			headerText: '#fff',
			footerBg: '#f7f7f7',
			footerText: '#222222',
			contentPrimary: '#224193',
			contentText: '#222222',
			footerTextActive: '#000000'
		}
	};
	public headerConfig = {
		showHeader: false
	};
	public profileInfos = [];
	constructor(
		// private httpProvider: HttpProvider,
		// private storage: Storage,
		// private externalProvider: ExternalProvider,
		// private modalCtrl: ModalController,
		@Inject(ENV) public ENV
	) {

		console.log('Hello Auth Provider');
		// this.ENV.headerConfig = this.headerConfig;
		// this.ENV.THEME = this.theme;
		// if(!this.ENV.USER._id){
		// 	this.storage.get("user").then((user) => {
		// 		this.ENV.USER = user;

		// 	}).catch(err => console.log(err));
		// }
		console.log('this.ENV.NAV', this.ENV.NAV);
	}

	init() { }

	// await without try, catch
	to(promise) {
		return promise.then(data => {
			return [null, data];
		})
		.catch(err => [err]);
	}
	setHeaderProp(params) {
		this.ENV.headerConfig = Object.assign(this.headerConfig, params);
	}
	showLoading() {
		this.ENV.loading = true;
	}
	hideLoading() {
		this.ENV.loading = false;
	}
}
