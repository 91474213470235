<div class="align-center">
  <div>
      <img class="img" [src]="croppedImage" >
  </div>
  <br>
  <input [hidden]="true" type="file" #fileInput accept="image/*" onclick="this.value = null"
      (change)="fileChangeEvent($event)">
  <button mat-flat-button class="btn2" (click)="fileInput.click()">{{croppedImage.startsWith('h') ? 'Alterar' : 'Selecionar'}}  Foto do candidato</button>
  <br>
  <span>Tamanho Máximo do Arquivo: 3MB</span><br><br>
</div>


<div class="form">
  <default-form  [forms]="formCandidate" [step]="0"></default-form>
</div>
<!-- <div class="card-body">
  <span>Sua Biografia</span>
  <ckeditor [editor]="Editor" [(ngModel)]="model"></ckeditor>
  <br>
</div> -->

<div class="btn-container">
  <button mat-flat-button class="btn1"  (click)="back()">Voltar</button>

  <button mat-flat-button class="btn2"  (click)="next()">Salvar</button>
</div>

