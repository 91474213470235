import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
// import { IonicPageModule } from "ionic-angular"
import { CommonModule } from '@angular/common';
import { DefaultFormComponent } from './default-form/default-form';
// import { CustomFormsModule } from "ng2-validation";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { Ng2FileInputModule } from "ng2-file-input";
// import {NgxMaskModule} from "ngx-mask";
// import { DefaultTableComponent } from "./default-table/default-table";
// import { LoadingComponent } from "./loading/loading";
// import { ColorPickerModule } from "ngx-color-picker";
// import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
// import { BrMasker4Module } from 'brmasker4';
import { BrMaskerModule } from 'br-mask';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
	declarations: [DefaultFormComponent],
	imports: [
		BrMaskerModule,
		// NgxMaskModule.forRoot(),
		NgbModule,
		// CustomFormsModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatButtonModule,
		MatInputModule,
		MatSelectModule,
		MatTooltipModule,
		MatAutocompleteModule,
		MatCheckboxModule,
		MatRadioModule,
		// Ng2FileInputModule,
		// ColorPickerModule,
		// NgMultiSelectDropDownModule.forRoot()

	],
	exports: [DefaultFormComponent],
	schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})


export class ComponentsModule { }
