import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(private http: HttpService) {}

  getAllDonations(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/pagamentos/getAllDonations', true, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  getAllHandling(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/pagamentos/getAllHandling', true, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  getAllHandlingV2(data: object): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/pagamentosV2/getAllHandlingV2', true, data)
        .then((res) => {
          resolve(res);
        });
    });
  }

  exportPayments(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(`api/pagamentos/exportPayments?user=${data.user}`, false)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  payment(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/paymentsVindi/paymentFlow', true, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }
  credito(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/pagamentos/credito', false, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }
  creditoV2(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/pagamentosV2/credito', false, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }
  creditoSafe2pay(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/pagamentosV2/creditoSafe2pay', false, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  debito(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/pagamentos/debito', false, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  boleto(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/pagamentos/boleto', false, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  boletoV2(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/pagamentosV2/boleto', false, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  boletoSafe2Pay(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/pagamentosV2/boletoSafe2Pay', false, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  pix(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/pagamentos/pix', false, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  pixV2(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/pagamentosV2/pix', false, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  pixSafe2Pay(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/pagamentosV2/pixSafe2Pay', false, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  downloadFile(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/pagamentos/downloadFile', true, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }
  getPayment(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get('api/paymentTypes/getAll', true)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  SubscriptionTransaction(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/subscribeV2/payment/transaction', true, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }
}
