<div>
  <div class="card">
    <div class="container">
      <div class="header">
        <img class="logo" src="../../assets/logo/logo.svg" />
        <h3> Confirme seu endereço para o envio da Maquininha: </h3>
      </div>
      <div class="form">
        <default-form (keyup.enter)="performClick(register)" [forms]="formAddress" [step]="0"></default-form>
        <mat-checkbox (keyup.enter)="performClick(register)" class="example-margin" [(ngModel)]="terms"><p>Eu concordo com os <a> termos de uso da maquininha.</a></p></mat-checkbox>
      </div>
    </div>
  </div>
  <div class="btn-container">
    <button mat-flat-button class="btn1" (click)="close()">Cancelar</button>
    <button mat-flat-button #register class="btn2" (click)="onSubmit()">Enviar Solicitação</button>
  </div>
</div>