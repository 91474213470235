import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root',
})
export class PoliticalService {
  constructor(private http: HttpService, private authService: AuthService) {}

  getPositions(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/positions/list/exec', true, { data: '' })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  getPoliticalParties(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/politicalParties/list/exec', true, { data: '' })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  createCandidateProfile(data: any) {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/candidateProfile/create/exec', true, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }
  editCandidateProfile(data: any) {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/candidateProfile/edit/exec', true, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  getElectionPeriod() {
    return new Promise((resolve, reject) => {
      this.http
        .get('api/electionPeriod/getOne/exec', true)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }
  getListElectionPeriod() {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/electionPeriod/getElectionPeriod/getElectionPeriod', false, {
          data: {
            find: '',
            page: 0,
            perPage: 10,
            sort: '',
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }
}
