import { Component, OnInit, Inject, ElementRef, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { MatButton } from '@angular/material/button';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { DomainProvider } from 'src/providers/domains';
import { MaquininhaService } from 'src/providers/maquininha.service';
import { ENV } from 'src/environments/environment-variables.token';
import { UtilsProvider } from 'src/commons/utils';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { TransferService } from 'src/providers/transfer.service';
import { AccountService } from 'src/providers/account.service.';

@Component({
  selector: "transfer-modal",
  templateUrl: "./transfer-modal.component.html",
  styleUrls: ["../app.component.scss", "./transfer-modal.component.scss"]
})
export class TransferModalComponent implements OnInit {

  public title: string;
  public content: string;
  public event: any;
  public formAddress: any;
  public state: any;
  public terms = false;
  public error = false;
  private transfer: any;
  private account: any;
  public docIdentidade: any;
  public cartaoCnpj: any;

  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('fileInputCnpj') fileInputCnpj: ElementRef;
  constructor(
    public formBuilder: FormBuilder,
    private domain: DomainProvider,
    private maquininha: MaquininhaService,
    private transferProvider: TransferService,
    private accountProvider: AccountService,
    public utils: UtilsProvider,
    public matDialogRef: MatDialogRef<TransferModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(ENV) public ENV: any) { }


  ngOnInit() {
    const self = this;
    const account = this.data.account
    this.createFormAddress();
    this.formAddress[0].builder.controls.valor.disable()
    this.formAddress[0].builder.controls.nomeEmpresa.value = `${account.nomeEmpresa != undefined ? account.nomeEmpresa : account.nameAccount} (${account.cnpj})`
    this.formAddress[0].builder.controls.nomeEmpresa.disable()
    this.formAddress[0].builder.controls.conta.value = `${account.conta} ${account.digConta ? '- ' + account.digConta : ''}`
    this.formAddress[0].builder.controls.conta.disable()
    this.formAddress[0].builder.controls.agencia.value = `${account.agencia} ${account.digAgencia ? '- ' + account.digAgencia : ''}`
    this.formAddress[0].builder.controls.agencia.disable()
    this.formAddress[0].builder.controls.chavePix.value = `${account.chavePix}`
    this.formAddress[0].builder.controls.chavePix.disable()

    if (this.data.antecipado && this.data.antecipado > 0) {
      this.formAddress[0].builder.controls.valor.value = parseFloat(this.data.antecipado.toFixed(2))
    } else if (this.data.available && this.data.available > 0) {
      console.log(this.data.available)
      this.formAddress[0].builder.controls.valor.value = parseFloat(this.data.available)
    }
  }

  createFormAddress() {
    const self = this;

    const valor = new FormControl('', Validators.compose([Validators.required]));
    const agencia = new FormControl('', Validators.compose([Validators.required]));
    const nomeEmpresa = new FormControl('', Validators.compose([Validators.required]));
    const conta = new FormControl('', Validators.compose([Validators.required]));
    const chavePix = new FormControl('', Validators.compose([Validators.required]));
    const description = new FormControl('');

    this.formAddress = [
      {
        fields: [
          {
            name: 'valor',
            type: 'number',
            label: 'Valor do Saque (R$)',
            placeholder: 'R$0,00',
            cols: 'col-lg-3 col-md-3 cols-sm-12 col-xs-12',
            blurCallback() { }
          },
          {
            name: 'agencia',
            type: 'text',
            label: 'Agência',
            cols: 'col-lg-4 col-md-4 col-sm-4 col-xs-4',
            blurCallback() { }
          },
          {
            name: 'conta',
            type: 'text',
            label: 'Conta',
            cols: 'col-lg-4 col-md-4 col-sm-8 col-xs-8',
            blurCallback() { }
          },
          {
            name: 'nomeEmpresa',
            type: 'text',
            label: 'Destinatário',
            placeholder: 'Nome da Empresa',
            cols: 'col-lg-7 col-md-7 col-sm-12 col-xs-12',
            blurCallback() { }
          },
          {
            name: 'chavePix',
            type: 'text',
            label: 'Chave do pix',
            placeholder: 'chave do pix',
            cols: 'col-lg-5 col-md-5 col-sm-5 col-xs-5',
            blurCallback() { }
          },
          {
            name: 'description',
            type: 'text',
            label: 'Descrição (Opcional)',
            placeholder: 'Descrição da transferência',
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            blurCallback() { }
          }
        ],
        builder: this.formBuilder.group({
          valor,
          agencia,
          conta,
          description,
          nomeEmpresa,
          chavePix
        }),
        validation_messages: {}
      }
    ];
  }

  close() {
    this.matDialogRef.close()
  }

  onSubmit() {
    var value = this.formAddress[0].builder.controls.valor.value
    if (Number(this.formAddress[0].builder.controls.valor.value) < 10) {
      return this.utils.toast({
        message: 'O valor disponível precisa ser de no mínimo R$10 para que a transferência possa ser solicitada.',
        action: 'OK',
        duration: 10000
      });
    }

    if (!this.isValidAddress()) {
      return this.utils.toast({
        message: 'Por favor, preencha o formulário corretamente.',
        action: 'OK',
        duration: 10000
      });
    } else {
      if (!this.data.antecipado) {
        console.log("AVAILABLE: ", this.data.available)
        if (
          this.formAddress[0].builder.controls.valor.value > this.data.available ||
          this.formAddress[0].builder.controls.valor.value <= 0) {
          return this.error = true;
        }
      }

      this.error = false;
      const data = this.formAddress[0].builder.value

      const _user = JSON.parse(localStorage.getItem('user'));

      this.transferProvider.createTransferV2({
        vaquinhaRef: this.data.vaquinhaRef,
        userRef: this.data.userRef,
        isAntecipado: this.data.isAntecipado,
        email: this.data.email,
      }).then(success => {
        this.utils.toast({
          message: 'Solicitação enviada com sucesso!',
          action: 'SUCESSO',
          duration: 10000
        });
        return this.matDialogRef.close()
      }).catch(err => {
        if(err.error.errCreateInvoice.errors[0]){
          return this.utils.toast({
            message: "Erro ao fazer a solicitação de saque.",
            action: 'OK',
            duration: 10000
          });
        } else {
          return this.utils.toast({
            message: "Você já tem uma solicitação pendente, por favor aguarde o retorno da mesma ou tente novamente mais tarde.",
            action: 'OK',
            duration: 10000
          });
        }
      })
    }
  }

  performClick(el: MatButton) {
    el._elementRef.nativeElement.click()
  }

  isValidAddress() {
    if (this.formAddress[0]) { return this.formAddress[0].builder.valid; }
    return false;
  }
}
