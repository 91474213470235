import { Component, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';

@Component({
    selector: "default-footer",
    templateUrl: "default-footer.html",
    styleUrls: ["./default-footer.scss"],
    providers: [],
})

export class DefaultFooterComponent{
    public parceiros: any;
    public href: string = "";
    faFacebook = faFacebook;
	faTwitter = faTwitter;
	faYoutube = faYoutube;
	faInstagram = faInstagram;
    mySubscription: any;
    notHome: any
    constructor(
        public route: ActivatedRoute,
        private viewportScroller: ViewportScroller,
        public router: Router,
    ) {
        console.log("load footer")
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };
        this.mySubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                // Trick the Router into believing it's last link wasn't previously loaded
                this.router.navigated = false;
                this.href = location.pathname;
                console.log("url", this.href);
                if(this.href === '/maquininha') {
                    console.log("other pages footer")
                    this.notHome = true
                } else {
                    this.notHome = false
                    console.log("home footer")
                }
            }
        });
    }
}