import { MainHomeComponent } from './mainHome/mainHome.component';
import { ForgetEmailComponent } from './forget-email/forget-email.component';
import { ModalTermDonationModule } from './modal-term-donation/modal-term-donation.module';
import { ModalPaidFormModule } from './modal-paid-form/modal-paid-form.module';
import { ModalPaidFormComponent } from './modal-paid-form/modal-paid-form.component';
import { ModalPaidComponent } from './modal-paid/modal-paid.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MenuModule } from 'src/components/header-component.module';
import { MenuAdminModule } from 'src/components/header-admin-component.module';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MaquininhaComponent } from './maquininha/maquininha.component';
import { ContatoComponent } from './contato/contato.component';
import { SearchCandidatesComponent } from './search-candidates/search-candidates.component';
import { HttpClientModule } from '@angular/common/http';
import { MatMenuModule } from '@angular/material/menu';
import { FooterModule } from 'src/components/footer-component.module';
import { LoginComponent } from './login/login.component';
import { AdminComponent } from './admin/admin.component';
import { RegisterComponent } from './register/register.component';
import { MatListModule } from '@angular/material/list';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { ComponentsModule } from 'src/components/form-components.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { ENV } from 'src/environments/environment-variables.token';
import { HttpService } from 'src/providers/http.service';
import { UserService } from 'src/providers/user.service';
import { CandidateComponent } from './candidate/candidate.component';
import { CandidateExComponent } from './candidate-example/candidate-example.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MaquininhaModule } from './maquininha/maquininha.module';
import { GuidelineComponent } from './guideline/guideline.component';
import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { FormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { DonationsPageComponent } from './donations-page/donations-page.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ModalDefaultComponent } from './modal-default/modal-default.component';

import { MainVaquinhaComponent } from './modal-vaquinha/main-vaquinha/main-vaquinha.component';
import { ViewVaquinhaComponent } from './modal-vaquinha/view-vaquinha/view-vaquinha.component';
import { TypeVaquinhaComponent } from './modal-vaquinha/1-type-vaquinha/type-vaquinha.component';
import { AccountVaquinhaComponent } from './modal-vaquinha/4-account-vaquinha/account-vaquinha.component';
import { FormVaquinhaComponent } from './modal-vaquinha/3-form-vaquinha/form-vaquinha.component';
import { CandidateVaquinhaComponent } from './modal-vaquinha/5-candidate-vaquinha/candidate-vaquinha.component';
import { ImgVaquinhaComponent } from './modal-vaquinha/2-img-vaquinha/img-vaquinha.component';

import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { ProfileComponent } from './profile/profile.component';
import { DonationsPanelComponent } from './donations-panel/donations-panel.component';
import { MyVaquinhaComponent } from './my-vaquinha/myVaquinha.component';
import { PaymentsComponent } from './payments/payments.component';
import { CandidateProfileComponent } from './candidate-profile/candidate-profile.component';
// import { MatButtonModule } from '@angular/material/button';
import { FaleConoscoModalComponent } from './fale-conosco-modal/fale-conosco-modal.component';
import { ImageCropModalComponent } from './image-crop-modal/image-crop-modal.component';
import { MaquininhaModalComponent } from './maquininha-modal/maquininha-modal.component';
import { SaqueModalComponent } from './saque-modal/saque-modal.component';
import { QuestionsComponentModule } from './questions/questions.module';
import { HomeAdminComponent } from './home-admin/home-admin.component';
import { PaymentsModule } from './payments/payments.module';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { BrMaskerModule } from 'br-mask';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { AgmCoreModule } from '@agm/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MatPaginatorModule,
  MatPaginatorIntl,
} from '@angular/material/paginator';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatTableModule } from '@angular/material/table';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { LOCALE_ID } from '@angular/core';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localePt);
import { MatSidenavModule } from '@angular/material/sidenav';
import { DonatorsListComponent } from './donators-list/donators-list.component';
import { TransferModalComponent } from './transfer-modal/transfer-modal.component';
import { ExportsPanelComponent } from './exports-panel/exports-panel.component';
import { ExportsPanelModule } from './exports-panel/exports-panel.module';
import { getDutchPaginatorIntl } from '../commons/paginator';
import { ModalCpfFormModule } from './modal-cpf-form/modal-cpf-form.module';
import { ModalCpfFormComponent } from './modal-cpf-form/modal-cpf-form.component';
import { ModalTermDonationComponent } from './modal-term-donation/modal-term-donation.component';
import { SearchCandidates2020Component } from './search-candidates-2020/search-candidates-2020.component';
import { DatePipe } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { WithdrawVaquinhaComponent } from './withdraw-vaquinha/withdraw-vaquinha.component';
import { WithdrawPanelComponent } from './withdraw-panel/withdraw-panel.component';
import { AnalysisModalComponent } from './analysis-modal/analysis-modal.component';
import { OldVaquinhaComponent } from './oldVaquinhas/oldVaquinha.component';
import { OldDonationsComponent } from './oldDonations/oldDonations.component';

import { RecaptchaModule } from 'ng-recaptcha';

@NgModule({
  declarations: [
    WithdrawVaquinhaComponent,
    AppComponent,
    HomeComponent,
    HomeAdminComponent,
    ContatoComponent,
    LoginComponent,
    RegisterComponent,
    CandidateComponent,
    CandidateExComponent,
    DonationsPanelComponent,
    MyVaquinhaComponent,
    ExportsPanelComponent,
    GuidelineComponent,
    DonationsPageComponent,
    OldDonationsComponent,
    DonatorsListComponent,
    // ExportsPanelComponent,
    // PaymentsComponent,
    ForgetPasswordComponent,
    ForgetEmailComponent,
    MainHomeComponent,
    ModalDefaultComponent,
    WithdrawVaquinhaComponent,
    WithdrawPanelComponent,

    MainVaquinhaComponent,
    ViewVaquinhaComponent,
    TypeVaquinhaComponent,
    AccountVaquinhaComponent,
    FormVaquinhaComponent,
    CandidateVaquinhaComponent,
    ImgVaquinhaComponent,

    ModalPaidComponent,
    ProfileComponent,
    SearchCandidatesComponent,
    OldVaquinhaComponent,
    SearchCandidates2020Component,
    CandidateProfileComponent,
    FaleConoscoModalComponent,
    ImageCropModalComponent,
    MaquininhaModalComponent,
    SaqueModalComponent,
    AnalysisModalComponent,
    TransferModalComponent,
    AdminComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MenuModule,
    MenuAdminModule,
    MaquininhaModule,
    MatCarouselModule.forRoot(),
    MatInputModule,
    ImageCropperModule,
    MatMenuModule,
    MatDividerModule,
    MatSidenavModule,
    MatFormFieldModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatPaginatorModule,
    HttpClientModule,
    MatCheckboxModule,
    FooterModule,
    CommonModule,
    ComponentsModule,
    MatSnackBarModule,
    FontAwesomeModule,
    MatCardModule,
    FormsModule,
    MatDialogModule,
    MatListModule,
    MatButtonModule,
    QuestionsComponentModule,
    BrMaskerModule,
    MatStepperModule,
    PaymentsModule,
    ModalPaidFormModule,
    ModalTermDonationModule,
    ModalCpfFormModule,
    MatSelectModule,
    MatFormFieldModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCA7-Z1-aAW-fBiE8-lXk31JvXO1FOJVLI',
    }),
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circle,
      backdropBackgroundColour: 'rgba(255,255,255,0.5)',
      backdropBorderRadius: '10px',
      fullScreenBackdrop: true,
      primaryColour: 'rgba(255,255,255,0.0)',
      secondaryColour: 'rgba(255,255,255,0.0)',
      tertiaryColour: '#ffffff',
    }),
    // MatButtonModule,
    CKEditorModule,
    RecaptchaModule,
  ],
  providers: [
    CurrencyPipe,
    DatePipe,
    HttpService,
    UserService,
    { provide: ENV, useValue: environment },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: MatPaginatorIntl, useValue: getDutchPaginatorIntl() },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
