import { DomainProvider } from 'src/providers/domains';
import {
  Component,
  OnInit,
  Inject,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { UtilsProvider } from 'src/commons/utils';
import { ENV } from 'src/environments/environment-variables.token';
import { MediaService } from 'src/providers/media.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-account-vaquinha',
  templateUrl: './account-vaquinha.component.html',
  styleUrls: ['./account-vaquinha.component.scss'],
})
export class AccountVaquinhaComponent implements OnInit {
  @Input() last: any;
  @Input() data: any;
  @Output() Event = new EventEmitter();

  constructor(
    public utils: UtilsProvider,
    public formBuilder: FormBuilder,
    private domain: DomainProvider,
    public mediaProvider: MediaService,
    @Inject(ENV) public ENV: any
  ) {}

  public formAccount: any;
  public formPix: any;
  public razaoSocial: any;
  public checkTypeField: any = '';
  private bancos = [
    {
      value: '001',
      label: 'Banco do Brasil S.A.',
    },
    {
      value: '003',
      label: 'Banco da Amazônia S.A.',
    },
    {
      value: '004',
      label: 'Banco do Nordeste do Brasil S.A.',
    },
    {
      value: '007',
      label: 'Banco Nacional de Desenvolvimento Econômico e Social BNDES',
    },
    {
      value: '010',
      label: 'Credicoamo Crédito Rural Cooperativa',
    },
    {
      value: '011',
      label: 'Credit Suisse Hedging-Griffo Corretora de Valores S.A.',
    },
    {
      value: '012',
      label: 'Banco Inbursa S.A.',
    },
    {
      value: '014',
      label: 'Natixis Brasil S.A. Banco Múltiplo',
    },
    {
      value: '015',
      label:
        'UBS Brasil Corretora de Câmbio, Títulos e Valores Mobiliários S.A.',
    },
    {
      value: '016',
      label:
        'Coop de Créd. Mútuo dos Despachantes de Trânsito de SC e Rio Grande do Sul',
    },
    {
      value: '017',
      label: 'BNY Mellon Banco S.A.',
    },
    {
      value: '018',
      label: 'Banco Tricury S.A.',
    },
    {
      value: '021',
      label: 'Banestes S.A. Banco do Estado do Espírito Santo',
    },
    {
      value: '024',
      label: 'Banco Bandepe S.A.',
    },
    {
      value: '025',
      label: 'Banco Alfa S.A.',
    },
    {
      value: '029',
      label: 'Banco Itaú Consignado S.A.',
    },
    {
      value: '033',
      label: 'Banco Santander (Brasil) S. A.',
    },
    {
      value: '036',
      label: 'Banco Bradesco BBI S.A.',
    },
    {
      value: '037',
      label: 'Banco do Estado do Pará S.A.',
    },
    {
      value: '040',
      label: 'Banco Cargill S.A.',
    },
    {
      value: '041',
      label: 'Banco do Estado do Rio Grande do Sul S.A.',
    },
    {
      value: '047',
      label: 'Banco do Estado de Sergipe S.A.',
    },
    {
      value: '060',
      label: 'Confidence Corretora de Câmbio S.A.',
    },
    {
      value: '062',
      label: 'Hipercard Banco Múltiplo S.A.',
    },
    {
      value: '063',
      label: 'Banco Bradescard S.A.',
    },
    {
      value: '064',
      label: 'Goldman Sachs do Brasil  Banco Múltiplo S. A.',
    },
    {
      value: '065',
      label: 'Banco AndBank (Brasil) S.A.',
    },
    {
      value: '066',
      label: 'Banco Morgan Stanley S. A.',
    },
    {
      value: '069',
      label: 'Banco Crefisa S.A.',
    },
    {
      value: '070',
      label: 'Banco de Brasília S.A.',
    },
    {
      value: '074',
      label: 'Banco J. Safra S.A.',
    },
    {
      value: '075',
      label: 'Banco ABN Amro S.A.',
    },
    {
      value: '076',
      label: 'Banco KDB do Brasil S.A.',
    },
    {
      value: '077',
      label: 'Banco Inter S.A.',
    },
    {
      value: '078',
      label: 'Haitong Banco de Investimento do Brasil S.A.',
    },
    {
      value: '079',
      label: 'Banco Original do Agronegócio S.A.',
    },
    {
      value: '080',
      label: 'BT Corretora de Câmbio Ltda.',
    },
    {
      value: '081',
      label: 'BBN Banco Brasileiro de Negocios S.A.',
    },
    {
      value: '082',
      label: 'Banco Topazio S.A.',
    },
    {
      value: '083',
      label: 'Banco da China Brasil S.A.',
    },
    {
      value: '084',
      label: 'Uniprime Norte do Paraná - Cooperativa de Crédito Ltda.',
    },
    {
      value: '085',
      label: 'Cooperativa Central de Crédito Urbano - Cecred',
    },
    {
      value: '089',
      label: 'Cooperativa de Crédito Rural da Região da Mogiana',
    },
    {
      value: '091',
      label:
        'Central de Cooperativas de Economia e Crédito Mútuo do Est RS - Unicred',
    },
    {
      value: '092',
      label: 'BRK S.A. Crédito, Financiamento e Investimento',
    },
    {
      value: '093',
      label:
        'Pólocred Sociedade de Crédito ao Microempreendedor e à Empresa de Pequeno Porte',
    },
    {
      value: '094',
      label: 'Banco Finaxis S.A.',
    },
    {
      value: '095',
      label: 'Banco Confidence de Câmbio S.A.',
    },
    {
      value: '096',
      label: 'Banco BMFBovespa de Serviços de Liquidação e Custódia S/A',
    },
    {
      value: '097',
      label:
        'Cooperativa Central de Crédito Noroeste Brasileiro Ltda - CentralCredi',
    },
    {
      value: '098',
      label: 'Credialiança Cooperativa de Crédito Rural',
    },
    {
      value: '099',
      label:
        'Uniprime Central – Central Interestadual de Cooperativas de Crédito Ltda.',
    },
    {
      value: '100',
      label: 'Planner Corretora de Valores S.A.',
    },
    {
      value: '101',
      label: 'Renascença Distribuidora de Títulos e Valores Mobiliários Ltda.',
    },
    {
      value: '102',
      label:
        'XP Investimentos Corretora de Câmbio Títulos e Valores Mobiliários S.A.',
    },
    {
      value: '104',
      label: 'Caixa Econômica Federal',
    },
    {
      value: '105',
      label: 'Lecca Crédito, Financiamento e Investimento S/A',
    },
    {
      value: '107',
      label: 'Banco Bocom BBM S.A.',
    },
    {
      value: '108',
      label: 'PortoCred S.A. Crédito, Financiamento e Investimento',
    },
    {
      value: '111',
      label:
        'Oliveira Trust Distribuidora de Títulos e Valores Mobiliários S.A.',
    },
    {
      value: '113',
      label: 'Magliano S.A. Corretora de Cambio e Valores Mobiliarios',
    },
    {
      value: '114',
      label:
        'Central Cooperativa de Crédito no Estado do Espírito Santo - CECOOP',
    },
    {
      value: '117',
      label: 'Advanced Corretora de Câmbio Ltda.',
    },
    {
      value: '118',
      label: 'Standard Chartered Bank (Brasil) S.A. Banco de Investimento',
    },
    {
      value: '119',
      label: 'Banco Western Union do Brasil S.A.',
    },
    {
      value: '120',
      label: 'Banco Rodobens SA',
    },
    {
      value: '121',
      label: 'Banco Agibank S.A.',
    },
    {
      value: '122',
      label: 'Banco Bradesco BERJ S.A.',
    },
    {
      value: '124',
      label: 'Banco Woori Bank do Brasil S.A.',
    },
    {
      value: '125',
      label: 'Brasil Plural S.A. Banco Múltiplo',
    },
    {
      value: '126',
      label: 'BR Partners Banco de Investimento S.A.',
    },
    {
      value: '127',
      label: 'Codepe Corretora de Valores e Câmbio S.A.',
    },
    {
      value: '128',
      label: 'MS Bank S.A. Banco de Câmbio',
    },
    {
      value: '129',
      label: 'UBS Brasil Banco de Investimento S.A.',
    },
    {
      value: '130',
      label: 'Caruana S.A. Sociedade de Crédito, Financiamento e Investimento',
    },
    {
      value: '131',
      label: 'Tullett Prebon Brasil Corretora de Valores e Câmbio Ltda.',
    },
    {
      value: '132',
      label: 'ICBC do Brasil Banco Múltiplo S.A.',
    },
    {
      value: '133',
      label:
        'Confederação Nacional das Cooperativas Centrais de Crédito e Economia Familiar e',
    },
    {
      value: '134',
      label:
        'BGC Liquidez Distribuidora de Títulos e Valores Mobiliários Ltda.',
    },
    {
      value: '135',
      label: 'Gradual Corretora de Câmbio, Títulos e Valores Mobiliários S.A.',
    },
    {
      value: '136',
      label:
        'Confederação Nacional das Cooperativas Centrais Unicred Ltda – Unicred do Brasil',
    },
    {
      value: '137',
      label: 'Multimoney Corretora de Câmbio Ltda',
    },
    {
      value: '138',
      label: 'Get Money Corretora de Câmbio S.A.',
    },
    {
      value: '139',
      label: 'Intesa Sanpaolo Brasil S.A. - Banco Múltiplo',
    },
    {
      value: '140',
      label: 'Easynvest - Título Corretora de Valores SA',
    },
    {
      value: '142',
      label: 'Broker Brasil Corretora de Câmbio Ltda.',
    },
    {
      value: '143',
      label: 'Treviso Corretora de Câmbio S.A.',
    },
    {
      value: '144',
      label: 'Bexs Banco de Câmbio S.A.',
    },
    {
      value: '145',
      label: 'Levycam - Corretora de Câmbio e Valores Ltda.',
    },
    {
      value: '146',
      label: 'Guitta Corretora de Câmbio Ltda.',
    },
    {
      value: '149',
      label: 'Facta Financeira S.A. - Crédito Financiamento e Investimento',
    },
    {
      value: '157',
      label: 'ICAP do Brasil Corretora de Títulos e Valores Mobiliários Ltda.',
    },
    {
      value: '159',
      label: 'Casa do Crédito S.A. Sociedade de Crédito ao Microempreendedor',
    },
    {
      value: '163',
      label: 'Commerzbank Brasil S.A. - Banco Múltiplo',
    },
    {
      value: '169',
      label: 'Banco Olé Bonsucesso Consignado S.A.',
    },
    {
      value: '172',
      label: 'Albatross Corretora de Câmbio e Valores S.A',
    },
    {
      value: '173',
      label: 'BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.',
    },
    {
      value: '174',
      label:
        'Pernambucanas Financiadora S.A. Crédito, Financiamento e Investimento',
    },
    {
      value: '177',
      label: 'Guide Investimentos S.A. Corretora de Valores',
    },
    {
      value: '180',
      label:
        'CM Capital Markets Corretora de Câmbio, Títulos e Valores Mobiliários Ltda.',
    },
    {
      value: '182',
      label:
        'Dacasa Financeira S/A - Sociedade de Crédito, Financiamento e Investimento',
    },
    {
      value: '183',
      label: 'Socred S.A. - Sociedade de Crédito ao Microempreendedor',
    },
    {
      value: '184',
      label: 'Banco Itaú BBA S.A.',
    },
    {
      value: '188',
      label: 'Ativa Investimentos S.A. Corretora de Títulos Câmbio e Valores',
    },
    {
      value: '189',
      label: 'HS Financeira S/A Crédito, Financiamento e Investimentos',
    },
    {
      value: '190',
      label:
        'Cooperativa de Economia e Crédito Mútuo dos Servidores Públicos Estaduais do Rio',
    },
    {
      value: '191',
      label: 'Nova Futura Corretora de Títulos e Valores Mobiliários Ltda.',
    },
    {
      value: '194',
      label: 'Parmetal Distribuidora de Títulos e Valores Mobiliários Ltda.',
    },
    {
      value: '196',
      label: 'Fair Corretora de Câmbio S.A.',
    },
    {
      value: '197',
      label: 'Stone Pagamentos S.A.',
    },
    {
      value: '204',
      label: 'Banco Bradesco Cartões S.A.',
    },
    {
      value: '208',
      label: 'Banco BTG Pactual S.A.',
    },
    {
      value: '212',
      label: 'Banco Original S.A.',
    },
    {
      value: '213',
      label: 'Banco Arbi S.A.',
    },
    {
      value: '217',
      label: 'Banco John Deere S.A.',
    },
    {
      value: '218',
      label: 'Banco BS2 S.A.',
    },
    {
      value: '222',
      label: 'Banco Credit Agrícole Brasil S.A.',
    },
    {
      value: '224',
      label: 'Banco Fibra S.A.',
    },
    {
      value: '233',
      label: 'Banco Cifra S.A.',
    },
    {
      value: '237',
      label: 'Banco Bradesco S.A.',
    },
    {
      value: '241',
      label: 'Banco Clássico S.A.',
    },
    {
      value: '243',
      label: 'Banco Máxima S.A.',
    },
    {
      value: '246',
      label: 'Banco ABC Brasil S.A.',
    },
    {
      value: '249',
      label: 'Banco Investcred Unibanco S.A.',
    },
    {
      value: '250',
      label: 'BCV - Banco de Crédito e Varejo S/A',
    },
    {
      value: '253',
      label: 'Bexs Corretora de Câmbio S/A',
    },
    {
      value: '254',
      label: 'Parana Banco S. A.',
    },
    {
      value: '260',
      label: 'Nu Pagamentos S.A.',
    },
    {
      value: '265',
      label: 'Banco Fator S.A.',
    },
    {
      value: '266',
      label: 'Banco Cédula S.A.',
    },
    {
      value: '268',
      label: 'Barigui Companhia Hipotecária',
    },
    {
      value: '269',
      label: 'HSBC Brasil S.A. Banco de Investimento',
    },
    {
      value: '271',
      label: 'IB Corretora de Câmbio, Títulos e Valores Mobiliários Ltda.',
    },
    {
      value: '300',
      label: 'Banco de la Nacion Argentina',
    },
    {
      value: '318',
      label: 'Banco BMG S.A.',
    },
    {
      value: '320',
      label: 'China Construction Bank (Brasil) Banco Múltiplo S/A',
    },
    {
      value: '341',
      label: 'Itaú Unibanco  S.A.',
    },
    {
      value: '366',
      label: 'Banco Société Générale Brasil S.A.',
    },
    {
      value: '370',
      label: 'Banco Mizuho do Brasil S.A.',
    },
    {
      value: '376',
      label: 'Banco J. P. Morgan S. A.',
    },
    {
      value: '389',
      label: 'Banco Mercantil do Brasil S.A.',
    },
    {
      value: '394',
      label: 'Banco Bradesco Financiamentos S.A.',
    },
    {
      value: '399',
      label: 'Kirton Bank S.A. - Banco Múltiplo',
    },
    {
      value: '412',
      label: 'Banco Capital S. A.',
    },
    {
      value: '422',
      label: 'Banco Safra S.A.',
    },
    {
      value: '456',
      label: 'Banco MUFG Brasil S.A.',
    },
    {
      value: '464',
      label: 'Banco Sumitomo Mitsui Brasileiro S.A.',
    },
    {
      value: '473',
      label: 'Banco Caixa Geral - Brasil S.A.',
    },
    {
      value: '477',
      label: 'Citibank N.A.',
    },
    {
      value: '479',
      label: 'Banco ItauBank S.A.',
    },
    {
      value: '487',
      label: 'Deutsche Bank S.A. - Banco Alemão',
    },
    {
      value: '488',
      label: 'JPMorgan Chase Bank, National Association',
    },
    {
      value: '492',
      label: 'ING Bank N.V.',
    },
    {
      value: '494',
      label: 'Banco de La Republica Oriental del Uruguay',
    },
    {
      value: '495',
      label: 'Banco de La Provincia de Buenos Aires',
    },
    {
      value: '505',
      label: 'Banco Credit Suisse (Brasil) S.A.',
    },
    {
      value: '545',
      label: 'Senso Corretora de Câmbio e Valores Mobiliários S.A.',
    },
    {
      value: '600',
      label: 'Banco Luso Brasileiro S.A.',
    },
    {
      value: '604',
      label: 'Banco Industrial do Brasil S.A.',
    },
    {
      value: '610',
      label: 'Banco VR S.A.',
    },
    {
      value: '611',
      label: 'Banco Paulista S.A.',
    },
    {
      value: '612',
      label: 'Banco Guanabara S.A.',
    },
    {
      value: '613',
      label: 'Omni Banco S.A.',
    },
    {
      value: '623',
      label: 'Banco Pan S.A.',
    },
    {
      value: '626',
      label: 'Banco Ficsa S. A.',
    },
    {
      value: '630',
      label: 'Banco Intercap S.A.',
    },
    {
      value: '633',
      label: 'Banco Rendimento S.A.',
    },
    {
      value: '634',
      label: 'Banco Triângulo S.A.',
    },
    {
      value: '637',
      label: 'Banco Sofisa S. A.',
    },
    {
      value: '641',
      label: 'Banco Alvorada S.A.',
    },
    {
      value: '643',
      label: 'Banco Pine S.A.',
    },
    {
      value: '652',
      label: 'Itaú Unibanco Holding S.A.',
    },
    {
      value: '653',
      label: 'Banco Indusval S. A.',
    },
    {
      value: '654',
      label: 'Banco A. J. Renner S.A.',
    },
    {
      value: '655',
      label: 'Banco Votorantim S.A.',
    },
    {
      value: '707',
      label: 'Banco Daycoval S.A.',
    },
    {
      value: '712',
      label: 'Banco Ourinvest S.A.',
    },
    {
      value: '719',
      label: 'Banif - Bco Internacional do Funchal (Brasil) S.A.',
    },
    {
      value: '735',
      label: 'Banco Neon S.A.',
    },
    {
      value: '739',
      label: 'Banco Cetelem S.A.',
    },
    {
      value: '741',
      label: 'Banco Ribeirão Preto S.A.',
    },
    {
      value: '743',
      label: 'Banco Semear S.A.',
    },
    {
      value: '745',
      label: 'Banco Citibank S.A.',
    },
    {
      value: '746',
      label: 'Banco Modal S.A.',
    },
    {
      value: '747',
      label: 'Banco Rabobank International Brasil S.A.',
    },
    {
      value: '748',
      label: 'Banco Cooperativo Sicredi S. A.',
    },
    {
      value: '751',
      label: 'Scotiabank Brasil S.A. Banco Múltiplo',
    },
    {
      value: '752',
      label: 'Banco BNP Paribas Brasil S.A.',
    },
    {
      value: '753',
      label: 'Novo Banco Continental S.A. - Banco Múltiplo',
    },
    {
      value: '754',
      label: 'Banco Sistema S.A.',
    },
    {
      value: '755',
      label: 'Bank of America Merrill Lynch Banco Múltiplo S.A.',
    },
    {
      value: '756',
      label: 'Banco Cooperativo do Brasil S/A - Bancoob',
    },
    {
      value: '757',
      label: 'Banco Keb Hana do Brasil S. A.',
    },
  ];
  private selectedtypeAccount: any = '';
  private selectedBanco: any = '';
  private selectedTipoConta: any = '';
  private docImage: any = '';
  public docImages: Array<any> = [
    { inputName: 'myFileInput', file: '', text: 'Anexar Documento' },
    { inputName: 'myFileInputCNPJ', file: '', text: 'Anexar CNPJ' },
  ];
  private conta = [
    { title: 'Corrente', value: 'Corrente' },
    { title: 'Poupança', value: 'Poupança' },
  ];

  ngOnInit(): void {
    this.accountForm();
    console.log('essa é a data', this.data);
    if (this.data.edit) this.setDefaultValue();
  }

  setDefaultValue() {
    const form = this.formAccount[0].builder.controls;
    form.agencia.value = this.data.agencia;
    form.digAgencia.value = this.data.digAgencia;
    form.conta.value = this.data.conta;
    form.digConta.value = this.data.digConta;
    form.tipoPessoa.value = this.data.tipoPessoa;
    this.checkTypeField = this.data.tipoPessoa == 'Física' ? 'CPF' : 'CNPJ';
    const form2 = this.formPix[0].builder.controls;

    if (this.data.edit || this.data.cpf || this.data.cnpj) {
      form2.nameAccount.value = this.data.nameAccount;
      form2.chavePix.value = this.data.chavePix;
      form2.cnpj.value =
        this.data.hasOwnProperty('cpf') && this.data.tipoPessoa == 'Física'
          ? this.data.cpf
          : this.data.cnpj;
    }
    console.log('get cep without -', this.data.endereco.cep);
    this.buscarCep(this.data.endereco.cep.replace(/\W+/g, ''));
    form2.cep.value = this.data.endereco.cep;
    form2.numero.value = this.data.endereco.numero;
    form2.complemento.value = this.data.endereco.complemento;

    if (this.data.docIdentidade.name) {
      this.docImages[0].file =
        this.data.docIdentidade.host + this.data.docIdentidade.full;
    }

    if (this.data.cartaoCnpj.name) {
      this.docImages[1].file =
        this.data.cartaoCnpj.host + this.data.cartaoCnpj.full;
    }
  }

  selectFile(inputName: string) {
    const input = document.getElementById(inputName);
    input.click();
  }

  async docFile(file: FileList, i) {
    const selectedFile = file.item(0);
    const reader = new FileReader();
    reader.onload = async (event: any) => {
      this.docImages[i].file = event.target.result;
    };
    reader.readAsDataURL(selectedFile);
  }

  accountForm() {
    const self = this;

    this.selectedtypeAccount = this.data.tipoPessoa;
    this.selectedBanco = this.data.banco;
    this.selectedTipoConta = this.data.tipoConta;

    const fields = () =>
      new FormControl('', Validators.compose([Validators.required]));

    const tipoPessoa = fields();
    const banco = fields();
    const agencia = fields();
    const digAgencia = fields();
    const cnpj = fields();
    const nameAccount = fields();
    const conta = fields();
    const digConta = fields();
    const tipoConta = fields();

    const AccountTypes = [
      { title: 'Física', value: 'Física' },
      { title: 'Jurídica', value: 'Jurídica' },
    ];

    this.formAccount = [
      {
        fields: [
          {
            name: 'tipoPessoa',
            selected: this.selectedtypeAccount,
            type: 'select',
            label: 'Tipo de Pessoa',
            cols: 'col-lg-2 col-md-2 col-sm-12 col-xs-12',
            options: {
              fields: {
                value: 'value',
                text: 'title',
              },
              items: AccountTypes,
            },
            clickCallback: (er) => {
              this.checkTypeField = er == 'Física' ? 'CPF' : 'CNPJ';
              
              const mask =
              this.checkTypeField == 'CPF'
              ? { mask: '000.000.000-00', len: 14 }
              : { mask: '00.000.000/0000-00', len: 18 };
              
              const chavePix = new FormControl('');
              const cep = fields();
              const logradouro = fields();
              const numero = fields();
              const bairro = fields();
              const uf = fields();
              const cidade = fields();
              const pais = fields();
              const nomeEmpresa = fields();
              const complemento = new FormControl('');

              this.formPix = [
                {
                  fields: [
                    {
                      name: 'nameAccount',
                      type: 'text',
                      label: 'Nome da Conta',
                      placeholder: 'Nome da Conta',
                      cols: 'col-lg-4 col-md-4 col-sm-12 col-xs-12',
                      blurCallback() {},
                    },
                    {
                      name: 'cnpj',
                      type: 'mask',
                      label: this.checkTypeField,
                      placeholder:
                        this.checkTypeField == 'CPF'
                          ? '000.000.000-00'
                          : '00.000.000/0000-00',
                      mask: mask,
                      cols: 'col-lg-4 col-md-4 col-sm-12 col-xs-12',
                      blurCallback() {},
                    },
                    {
                      name: 'chavePix',
                      type: 'text',
                      label: 'Chave do pix',
                      disabled: false,
                      placeholder: 'Chave de pix',
                      cols: 'col-lg-4 col-md-4 col-sm-5 col-xs-5',
                      blurCallback() {},
                    },
                    {
                      name: 'cep',
                      type: 'maskCep',
                      label: 'CEP',
                      maxlength: 9,
                      mask: { mask: '00000-000', len: 9 },
                      cols: 'col-lg-3 col-md-3 cols-sm-12 col-xs-12',
                      blurCallback() {
                        // console.log("teste cep", self.institutionForm[0].builder.controls.cep.value);
                        let cepValue =
                          self.formPix[0].builder.controls.cep.value;
                        if (cepValue.length !== 0) {
                          cepValue = cepValue.replace(/\W+/g, '');
                          self.buscarCep(cepValue);
                        }
                      },
                    },
                    {
                      name: 'logradouro',
                      type: 'text',
                      label: 'Rua / Logradouro',
                      cols: 'col-lg-9 col-md-9 col-sm-12 col-xs-12',
                      blurCallback() {},
                    },
                    {
                      name: 'numero',
                      type: 'text',
                      label: 'Número',
                      cols: 'col-lg-3 col-md-3 col-sm-4 col-xs-4',
                      blurCallback() {},
                    },
                    {
                      name: 'complemento',
                      type: 'text',
                      label: 'complemento',
                      cols: 'col-lg-3 col-md-3 col-sm-8 col-xs-8',
                      blurCallback() {},
                    },
                    {
                      name: 'bairro',
                      type: 'text',
                      label: 'Bairro',
                      cols: 'col-lg-6 col-md-6 col-sm-12 col-xs-12',
                      blurCallback() {},
                    },
                    {
                      name: 'cidade',
                      type: 'text',
                      label: 'Cidade',
                      cols: 'col-lg-6 col-md-6 col-sm-12 col-xs-12',
                      blurCallback() {},
                    },
                    {
                      name: 'uf',
                      type: 'text',
                      label: 'UF',
                      cols: 'col-lg-3 col-md-3 col-sm-4 col-xs-4',
                      blurCallback() {},
                    },
                    {
                      name: 'pais',
                      type: 'text',
                      label: 'País',
                      cols: 'col-lg-3 col-md-3 col-sm-8 col-xs-8',
                      blurCallback() {},
                    },
                  ],
                  builder: this.formBuilder.group({
                    nameAccount,
                    cnpj,
                    chavePix,
                    cep,
                    logradouro,
                    numero,
                    complemento,
                    bairro,
                    cidade,
                    uf,
                    pais,
                  }),
                  validation_messages: {
                    nameAccount: [
                      {
                        type: 'required',
                        message:
                          'Preencha o campo nomde da conta corretamente.',
                      },
                    ],
                  },
                },
              ];
              this.razaoSocial = [
                {
                  fields: [
                    { 
                      name: 'nomeEmpresa',
                      type: 'text',
                      label: 'Nome da Empresa',
                      placeholder: 'Nome da Empresa',
                      cols: 'col-lg-4 col-md-4 col-sm-12 col-xs-12',
                      blurCallback() {},
                    }
                    
                  ],
                  builder: this.formBuilder.group({
                    nomeEmpresa
                  }),
                  validation_messages: {
                    nomeEmpresa: [
                      {
                        type: 'required',
                        message:
                          'Preencha o campo Nome da Empresa corretamente.',
                      },
                    ],
                  },
                },
              ];
            },
            blurCallback() {},
          },
          {
            name: 'banco',
            type: 'select',
            selected: this.selectedBanco,
            label: 'Nome do Banco',
            cols: 'col-lg-7 col-md-7 col-sm-12 col-xs-12',
            options: {
              fields: {
                value: 'value',
                text: 'label',
                secondText: 'value',
              },
              items: self.bancos,
            },
            blurCallback() {},
            clickCallback: (_) => {},
          },
          {
            name: 'tipoConta',
            type: 'select',
            selected: this.selectedTipoConta,
            label: 'Tipo de Conta',
            cols: 'col-lg-3 col-md-3 col-sm-12 col-xs-12',
            options: {
              fields: {
                value: 'value',
                text: 'title',
              },
              items: self.conta,
            },
            blurCallback() {},
            clickCallback: (_) => {},
          },
          {
            name: 'agencia',
            type: 'mask',
            label: 'Agência',
            placeholder: '0000',
            mask: { mask: '9999', len: 4 },
            cols: 'col-lg-4 col-md-4 col-sm-4 col-xs-4',
            blurCallback() {},
          },
          {
            name: 'digAgencia',
            type: 'mask',
            label: 'Dígito Agência',
            placeholder: '00',
            mask: { mask: '00', len: 2 },
            cols: 'col-lg-2 col-md-2 col-sm-3 col-xs-3',
            blurCallback() {},
          },
          {
            name: 'conta',
            type: 'mask',
            label: 'Conta',
            mask: { mask: '99999000000', len: 11 },
            placeholder: '0000000',
            cols: 'col-lg-4 col-md-4 col-sm-8 col-xs-8',
            blurCallback() {},
          },
          {
            name: 'digConta',
            type: 'mask',
            label: 'Dígito Conta',
            mask: { mask: '0', len: 1 },
            placeholder: '0',
            cols: 'col-lg-2 col-md-2 col-sm-3 col-xs-3',
            blurCallback() {},
          },
        ],
        builder: this.formBuilder.group({
          tipoPessoa,
          banco,
          agencia,
          digAgencia,
          conta,
          digConta,
          tipoConta,
          // description,

          // nomeEmpresa,
        }),
        validation_messages: {},
      },
    ];
    if (this.data.edit || this.data.cnpj || this.data.cpf) {
      const form = this.formAccount[0].builder.value;
      const verifyField = this.data.tipoPessoa == 'Física';
      const mask = verifyField
        ? { mask: '000.000.000-00', type: 'num', len: 14 }
        : { mask: '00.000.000/0000-00', type: 'num', len: 18 };

      const cep = fields();
      const logradouro = fields();
      const numero = fields();
      const bairro = fields();
      const uf = fields();
      const cidade = fields();
      const pais = fields();
      const complemento = new FormControl('');

      const chavePix = new FormControl('');
      this.formPix = [
        {
          fields: [
            {
              name: 'nameAccount',
              type: 'text',
              label: 'Nome da Conta',
              placeholder: 'Nome da Conta',
              cols: 'col-lg-4 col-md-4 col-sm-12 col-xs-12',
              blurCallback() {},
            },
            {
              name: 'cnpj',
              type: 'mask',
              label: verifyField ? 'CPF' : 'CNPJ',
              placeholder: verifyField
                ? '000.000.000-00'
                : '00.000.000/0000-00',
              mask: mask,
              cols: 'col-lg-4 col-md-4 col-sm-12 col-xs-12',
              blurCallback() {},
            },
            {
              name: 'chavePix',
              type: 'text',
              label: 'Chave do pix',
              disabled: false,
              placeholder: 'Chave de pix',
              cols: 'col-lg-4 col-md-4 col-sm-12 col-xs-12',
              blurCallback() {},
            },
            {
              name: 'cep',
              type: 'maskCep',
              label: 'CEP',
              maxlength: 9,
              mask: { mask: '00000-000', len: 9 },
              cols: 'col-lg-3 col-md-3 cols-sm-12 col-xs-12',
              blurCallback() {
                // console.log("teste cep", self.institutionForm[0].builder.controls.cep.value);
                let cepValue = self.formPix[0].builder.controls.cep.value;
                if (cepValue.length !== 0) {
                  cepValue = cepValue.replace(/\W+/g, '');
                  self.buscarCep(cepValue);
                }
              },
            },
            {
              name: 'logradouro',
              type: 'text',
              label: 'Rua / Logradouro',
              cols: 'col-lg-9 col-md-9 col-sm-12 col-xs-12',
              blurCallback() {},
            },
            {
              name: 'numero',
              type: 'text',
              label: 'Número',
              cols: 'col-lg-3 col-md-3 col-sm-4 col-xs-4',
              blurCallback() {},
            },
            {
              name: 'complemento',
              type: 'text',
              label: 'complemento',
              cols: 'col-lg-3 col-md-3 col-sm-8 col-xs-8',
              blurCallback() {},
            },
            {
              name: 'bairro',
              type: 'text',
              label: 'Bairro',
              cols: 'col-lg-6 col-md-6 col-sm-12 col-xs-12',
              blurCallback() {},
            },
            {
              name: 'cidade',
              type: 'text',
              label: 'Cidade',
              cols: 'col-lg-6 col-md-6 col-sm-12 col-xs-12',
              blurCallback() {},
            },
            {
              name: 'uf',
              type: 'text',
              label: 'UF',
              cols: 'col-lg-3 col-md-3 col-sm-4 col-xs-4',
              blurCallback() {},
            },
            {
              name: 'pais',
              type: 'text',
              label: 'País',
              cols: 'col-lg-3 col-md-3 col-sm-8 col-xs-8',
              blurCallback() {},
            },
          ],
          builder: this.formBuilder.group({
            nameAccount,
            cnpj,
            chavePix,
            cep,
            logradouro,
            numero,
            complemento,
            bairro,
            cidade,
            uf,
            pais,
          }),

          validation_messages: {
            nameAccount: [
              {
                type: 'required',
                message: 'Preencha o campo nome da conta corretamente.',
              },
            ],
          },
        },
      ];
    }

    //FORM PIX
  }

  buscarCep(cepValue) {
    if (cepValue.length === 8) {
      this.ENV.loading = true;
      this.domain.getCEP(cepValue).then((data: any) => {
        this.formPix[0].builder.patchValue({
          logradouro: data.logradouro || '',
          bairro: data.bairro || '',
          cidade: data.localidade || '',
          uf: data.uf || '',
          pais: 'Brasil',
        });
        this.ENV.loading = false;
      });
    }
  }

  back() {
    this.Event.emit({
      back: true,
    });
  }

  next() {
    const form = this.formAccount[0].builder;
    const verify = [
      // {
      //   field: 'cnpj',
      //   message: `Preencha o campo ${this.checkTypeField} corretamente`,
      // },
      { field: 'digConta', message: 'Preencha o dígito conta.' },
      { field: 'conta', message: 'Preencha o campo conta.' },
      { field: 'digAgencia', message: 'Preencha o campo dígito da agência.' },
      { field: 'agencia', message: 'Preencha o campo agência.' },
      { field: 'tipoConta', message: 'Preencha o campo tipo de conta.' },
      {
        field: 'banco',
        message: 'Selecione o banco.',
      },
      {
        field: 'tipoPessoa',
        message: 'Selecione o tipo de pessoa.',
      },
    ];

    const ok: Array<any> = verify.map((item) => {
      if (form['controls'][`${item.field}`]['invalid']) {
        this.utils.toast({
          message: item.message,
          action: '❌',
        });
        return true;
      }
    });

    const form2 = this.formPix[0].builder;
    let form3;
  
    if (this.checkTypeField != 'CPF'){
      form3 = this.razaoSocial[0].builder.value;

      console.log("checkTypeField != 'CPF'", this.checkTypeField);
    }

    if (form.value.tipoPessoa == 'Física') {
      const val = form2.value.cnpj.replace(/[\.\-]*/gi, '');
      if (!this.utils.cpfVerify(val)) {
        this.utils.toast({
          message: 'CPF inválido, verifique os dados inseridos.',
          action: '❌',
          duration: 10000,
        });
        return;
      }
    }

    if (form.value.tipoPessoa == 'Jurídica') {
      const val = form2.value.cnpj;
      if (!this.utils.cnpjValidation(val)) {
        this.utils.toast({
          message: 'CNPJ inválido, verifique os dados inseridos.',
          action: '❌',
          duration: 10000,
        });
        return;
      }
    }
    if (form2.value.nameAccount == '') {
      return this.utils.toast({
        message: 'Preencha o campo nome da conta.',
        action: '❌',
        duration: 10000,
      });
    }

    if (form2.controls.cnpj.invalid) {
      return this.utils.toast({
        message: `Preencha o campo ${this.checkTypeField} corretamente`,
        action: '❌',
      });
    }
    if (this.docImages[0].file == '') {
      return this.utils.toast({
        message: `Por favor, é necessário anexar um documento`,
        action: '❌',
      });
    }

    if (this.checkTypeField == 'CNPJ' && this.docImages[1].file == '') {
      return this.utils.toast({
        message: `Por favor, é necessário anexar um documento de CNPJ`,
        action: '❌',
      });
    }
    console.log('tente ', this.docImages);
    console.log('tente ', this.data.docIdentidade._id);
    const docImage = {
      docIdentidade: this.docImages[0].file.startsWith('http')
        ? this.data.docIdentidade._id
        : this.docImages[0].file,
      cartaoCnpj: this.docImages[1].file.startsWith('http')
        ? this.data.cartaoCnpj._id
        : this.docImages[1].file,
    };

    const verifyAddress = [
      { field: 'pais', message: 'Preencha o campo país.' },
      { field: 'uf', message: 'Preencha o campo UF.' },
      { field: 'cidade', message: 'Preencha o campo cidade.' },
      { field: 'bairro', message: 'Preencha o campo bairro.' },
      { field: 'numero', message: 'Preencha o campo número.' },
      {
        field: 'logradouro',
        message: 'Preencha o campo Rua / Logradouro.',
      },
      {
        field: 'cep',
        message: 'Preencha o campo CEP.',
      },
    ];

    const okAddress: Array<any> = verifyAddress.map((item) => {
      if (form2['controls'][`${item.field}`]['invalid']) {
        this.utils.toast({
          message: item.message,
          action: '❌',
        });
        return true;
      }
    });
    if (!ok.includes(true) && !okAddress.includes(true)) {
      this.Event.emit({
        ...form.value,
        ...form2.value,
        ...form3,
        ...docImage,
      });
    }
  }
}
