<div class="bg"></div>
<div class="card-container">
	<div class="card">
		<mat-card class="container-cd">
			<div class="form">
                <img class="logo" src="../../assets/logo/logo.svg"/>
				<h2>ESQUECI A MINHA SENHA</h2>
				<default-form [forms]="forgotPasswordForm" (keyup.enter)="performClick(senha)" [step]="forgotPasswordFormStep"></default-form>  
				<div class="btn-container">
            		<button [disabled]="checkDisabled()" #senha mat-flat-button class="btn confirm-button" (click)="onSubmit(forgotPasswordForm)">ENVIAR EMAIL</button>
					<h3>
						<a [routerLink]="['/entrar']">Voltar para Login</a>
					</h3>
				</div>
			</div>
		</mat-card>
	</div>
</div>
