<div class="{{ adminHelpPage ? 'all-content' : 'all-content-padding'}}" >
    <div class="content" fxFlex="50" wmAnimate="landing" speed="slow" aos>
        <div class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1">
            <div class="row component-container">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <div class="box">
                        <div class="row">
                            <div #ajuda class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <mat-card class="container-cd">
                                    <div class="form">
                                        <div #cadastro class="header">
                                            <img class="icon" src="../../assets/balao2.png" /><br>
                                            <h1>
                                                Tem alguma dúvida?<br />
                                                Fale conosco!
                                            </h1>
                                        </div>
                                        <p>Envie sua mensagem por email pelo formulário abaixo, ou se preferir, chama a gente pelo WhatsApp no botãozinho verde no canto direito inferior ;)</p>
                                        <default-form (keyup.enter)="performClick(enviar)" [forms]="contatoForm"
                                            [step]="contatoFormStep"></default-form>
                                        <div class="btn-container">
                                            <button [disabled]="isDisabled" #enviar mat-flat-button class="btn"
                                                (click)="onSubmit(contatoForm)">enviar</button>
                                        </div>
                                    </div>
                                </mat-card>
                            </div>
                            <div #pagina class="col-lg-6 col-md-6 col-sm-12 col-xs-12">

                            </div>
                        </div>
                         <mat-card class="image-card">
                            <div class="row" *ngIf="!showVideo">
                                <!-- <div class="col-md-6 col-sm-12 col-xs-12 text video-container">
                                    <h1>
                                        Dúvidas?
                                    </h1>
                                    <p>
                                        Assista nosso vídeo sobre financiamento coletivo de campanhas apresentando o Quero Apoiar.
                                    </p>
                                    <!-- <img class="img-duvidas" mat-card-image src="../../assets/image/home.gif" /> -->
                                <!-- <button mat-flat-button class="confirm-button" (click)="goToUrl()"><i
                                            class="fa fa-play-circle"></i>Assista agora!</button>
                                </div> -->
                                <!-- <div class="col-md-6 col-xs-12 video-img">
                                    <img class="img-duvidas" src="../../assets/image/home.gif" />
                                </div> *ngIf="showVideo" -->
                            </div>
                            <div>
                                <h1>
                                    Assista nosso vídeo com as principais perguntas.
                                </h1>
                                <iframe width="100%" height="315" src="https://www.youtube.com/embed/UO0b-Ly_rAU"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen></iframe>
                            </div>
                        </mat-card>
                    </div>
                </div>
                <div #duvidas class="col-xs-12 col-sm-12 col-md-6 col-lg-6">

                    <div #taxas class="row">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <mat-card class="container-cd">
                                <img class="icon right" src="../../assets/porquinho.png" />
                                <h1>
                                    Perguntas Frequentes
                                </h1>
                                <p>Veja as respostas para as perguntas mais frequentes em
                                    nosso site</p>

                                    <p class="second-text" font-weight: bold>Sou candidato ou quero me candidatar</p>
                                    <mat-accordion>
                                      <mat-expansion-panel *ngFor="let question of candidatos">
                                        <mat-expansion-panel-header>
                                          <mat-panel-title class="questions">
                                            {{question.title}}
                                          </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <mat-panel-description>{{question.answer}}</mat-panel-description>
                                      </mat-expansion-panel>
                                    </mat-accordion>

                                    <p class="second-text">Quero doar ou já doei</p>
                                    <mat-accordion>
                                      <mat-expansion-panel *ngFor="let question of doadores">
                                        <mat-expansion-panel-header>
                                          <mat-panel-title class="questions">
                                            {{question.title}}
                                          </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <mat-panel-description [innerHTML]="question.answer">
                                            <div class="a"></div>
                                        </mat-panel-description>
                                      </mat-expansion-panel>
                                    </mat-accordion>
                                   
                            </mat-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
