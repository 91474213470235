<div class="all-content">
    <div class="col-lg-10 col-lg-offset-1 col-md-10 col-md-offset-1 col-sm-12 col-xs-12">
        <div class="row">
            <mat-card class="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                <img mat-card-image class="card-image" src="../../assets/background-azul.gif" />
                <div class="info-container">
                    <div class="row">
                        <div class="info-header col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="row">
                                <div class="avatar col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <div class="avatar-img center-cropped" style="background-image: url(../../assets/image/exemplo2.png);">
                                    </div>
                                </div>
                                <div class="candidate-info col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                    <h1>Seu Nome de Urna</h1>
                                    <h3>
                                        <img class="icon-small gray" src="../../assets/map-marker.svg">
                                        Sua Cidade <span>-
                                            Candidato
                                            ao Cargo pelo Seu Partido
                                        </span>
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="info-body">
                                <div class="tags">
                                    <span>Bandeiras</span>
                                    <div class="flags">
                                        EDUCAÇÃO
                                    </div>
                                    <div class="flags">
                                        SANEAMENTO
                                    </div>
                                    <div class="flags">
                                        SAÚDE
                                    </div>
                                    <div class="flags">
                                        ECONOMIA
                                    </div>
                                </div>
                                <div class="social-media">
                                    <span>Redes</span>
                                    <ul>
                                        <li>
                                            <a target="_blank" [href]="">
                                                <fa-icon [icon]="faFacebook"></fa-icon>
                                            </a>
                                        </li>
                                        <li>
                                            <a target="_blank" [href]="">
                                                <fa-icon [icon]="faTwitter"></fa-icon>
                                            </a>
                                        </li>
                                        <li>
                                            <a target="_blank" [href]="">
                                                <fa-icon [icon]="faInstagram"></fa-icon>
                                            </a>
                                        </li>
                                        <li>
                                            <a target="_blank" [href]="">
                                                <fa-icon [icon]="faYoutube"></fa-icon>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="video">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/UO0b-Ly_rAU" frameborder="0" 
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            <div class="bios">
                                <p>Conte aqui sobre você e sobre o que você defende!
                                    Divulgue para todos sua página personalizada, conte a sua história e receba doações
                                    com
                                    a nossa plataforma.</p>
                                    <p>Essa página é completamente personalizável e está esperando por você para começar! 
                                        Clique no botão abaixo para se cadastrar e começar sua história conosco:
                                    </p>
                            </div>
                            <div class="success">
                                <h3> Se interessou? Crie sua conta agora: </h3>
                                <button class="redirect-button purple" mat-flat-button (click)="cadastrar()"
                                >Criar Minha Conta</button>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card>
            <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                <mat-card class="col-lg-12 col-md-12 col-sm-12 col-xs-12 donate">
                    <div class="info-container">
                        <div class="row">
                            <div class="info">
                                <div class="text-center">
                                    <h3><strong>Quero Apoiar</strong> essa campanha</h3><br>
                                    <img class="logo" src="../../assets/image/porquinho.png" />
                                </div>
                                <div class="value text-center">
                                    <h3>Arrecadado na Campanha</h3>
                                    <h1>R$284,50</h1>
                                    <h3><img class="icon gray" src="../../assets/porquinho3.png">20
                                        pessoas <strong>já
                                            doaram</strong></h3>
                                </div>
                                <div class="info-body">
                                    <div class="buttons">
                                        <div class="donates">
                                            <div class="row">
                                                <div *ngIf="!anotherValue"
                                                    class="col-xs-12 col-sm-12 col-md-3 col-xs-3">
                                                    <button (click)="donateValue = 10"
                                                        [ngClass]="{ active: donateValue == 10 }"
                                                        mat-flat-button>R$10</button>
                                                </div>
                                                <div *ngIf="!anotherValue"
                                                    class="col-xs-12 col-sm-12 col-md-3 col-xs-3">
                                                    <button (click)="donateValue = 20"
                                                        [ngClass]="{ active: donateValue == 20 }"
                                                        mat-flat-button>R$20</button>
                                                </div>
                                                <div *ngIf="!anotherValue"
                                                    class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                                    <button (click)="donateValue = 50"
                                                        [ngClass]="{ active: donateValue == 50 }"
                                                        mat-flat-button>R$50</button>
                                                </div>
                                                <div *ngIf="!anotherValue"
                                                    class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                                    <button (click)="donateValue = 100" style="margin-right: 0;"
                                                        [ngClass]="{ active: donateValue == 100 }"
                                                        mat-flat-button>R$100</button>
                                                </div>
                                            </div>
                                            <div *ngIf="anotherValue" class="row">
                                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <form class="value-form">
                                                        <mat-form-field appearance="outline" class="form-field">
                                                            <mat-label>Insira o valor aqui</mat-label>
                                                            <input [(ngModel)]="donateValue" type="number" matInput
                                                                placeholder="Valor Mínimo: R$10"
                                                                >
                                                        </mat-form-field>
                                                    </form>
                                                </div>
                                            </div>
                                            <div *ngIf="!anotherValue" class="row padding">
                                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <button (click)="anotherValue = true"
                                                        [ngClass]="{ active: anotherValue == true }"
                                                        style="margin-right: 0;" mat-flat-button>Outro
                                                        valor</button>
                                                </div>
                                            </div>
                                            <div *ngIf="anotherValue" class="row padding">
                                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <button (click)="anotherValue = false" class="active"
                                                        style="margin-right: 0;" mat-flat-button>Voltar</button>
                                                </div>
                                            </div>
                                        </div>
                                        <button class="redirect-button green" mat-flat-button
                                            ><img class="icon"
                                                src="../../assets/porquinho2.png">Doar Agora!</button>
                                        <button class="redirect-button new-btn" mat-flat-button
                                            (click)="getDonationsList()"> Ver lista de doações</button>
                                        <p class="link text-center">
                                            <a class="link" [routerLink]="'/duvidas'">Dúvidas? Saiba mais</a>
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </mat-card>
                <mat-card class="col-lg-12 col-md-12 col-sm-12 col-xs-12 share">
                    <div>
                        <div class="info">
                            <div class="text-center">
                                <h3><strong>Compartilhe!</strong></h3>
                                <div class="url">
                                    <h3>Sua URL personalizada!</h3>
                                    <button mat-flat-button class="copy"
                                        (click)='copyToClipboard()'><strong>copiar</strong></button>
                                </div>
                                <div class="links">
                                    <a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=http://www.queroapoiar.com.br">
                                        <fa-icon [icon]="faFacebook"></fa-icon>
                                    </a>
                                    <a target="_blank" href="https://twitter.com/intent/tweet?url=http://www.queroapoiar.com.br" data-show-count="false">
                                        <fa-icon [icon]="faTwitter"></fa-icon>
                                    </a>
                                    <a target="_blank"  href="https://api.whatsapp.com/send?text=http://www.queroapoiar.com.br" data-action="share/whatsapp/share">
                                        <fa-icon [icon]="faWhatsapp"></fa-icon>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
</div>