import { AuthService } from './../../providers/auth.service';
import { UserService } from 'src/providers/user.service';
import { MatDialog } from '@angular/material/dialog';
import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  HostListener,
} from '@angular/core';
// import { MatMenuComponent } from "@angular/material/menu"
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { DOCUMENT, ViewportScroller } from '@angular/common';
import { ENV } from '../../environments/environment-variables.token';
import { ScrollService } from 'src/providers/scroll.service';
import { UrnaService } from 'src/providers/urna.service';
import { ModalPaidComponent } from 'src/app/modal-paid/modal-paid.component';
import { ModalPaidFormComponent } from 'src/app/modal-paid-form/modal-paid-form.component';
import { ModalCpfFormComponent } from 'src/app/modal-cpf-form/modal-cpf-form.component';

@Component({
  selector: 'default-header-admin',
  templateUrl: 'default-header-admin.html',
  styleUrls: ['./default-header-admin.scss'],
  providers: [],
})
export class DefaultHeaderAdminComponent implements OnDestroy {
  public subscribeData: any;
  private prev = 0;
  public headerHide: any;
  public paid: boolean = false;
  public fragment: any;
  public href: string = '';
  public imageUrl = '../../assets/no-avatar.png';
  mySubscription: any;
  notHome: any;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    public route: ActivatedRoute,
    private userProvider: UserService,
    private viewportScroller: ViewportScroller,
    public router: Router,
    public authProvider: AuthService,
    private scrollService: ScrollService,
    private urna: UrnaService,
    public dialog: MatDialog,
    @Inject(ENV) public ENV: any
  ) {
    console.log('load header');

    // this.userProvider.checkPaid().then((res: any) =>{
    //     this.paid = res.paid
    // }).catch((err: any)=>{
    //     console.log(err)
    // })

    if (localStorage.getItem('user')) {
      this.ENV.logged = true;
      const _user = JSON.parse(localStorage.getItem('user'));
      console.log('USER: ', _user);
      if (_user.user.media && _user.user.media.length > 0) {
        const media = _user.user.media[0];
        this.imageUrl = media.host + media.full;
      } else {
        this.imageUrl = '../../assets/no-avatar.png';
      }
    }

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.href = location.pathname;
        console.log('url', this.href);
        if (this.href === '/maquininha') {
          console.log('other pages');
          this.notHome = true;
        } else {
          this.notHome = false;
          console.log('home');
        }
      }
    });
  }

  async verifyIfSubscriptionIsPaid() {
    this.subscribeData = await this.userProvider.checkPaymentExists();
    // console.log("test payment data", subscribeData)
    const { isPaid } = await this.userProvider.checkSubscriptionIsPaid();
    this.paid = isPaid;
    if (!isPaid) {
      this.openDialogMAquininha();
    }
  }

  ngOnInit() {
    this.verifyIfSubscriptionIsPaid();
  }

  openDialog() {
    const confirmDialog = this.dialog.open(ModalPaidComponent, {
      disableClose: false,
      data: {
        title: 'Bem-vindo (a) ao QueroApoiar! ',
        message:
          '<p>Perfil criado com sucesso! Para editar seus dados e começar a utilizar a plataforma, pague a taxa única de inscrição no valor de R$199,00. QueroApoiar, seu apoio começa aqui! </p>',
      },
    });
    confirmDialog.afterClosed().subscribe((result) => {
      console.log('RESULT: ', result);
      // if (result === 'false') { console.log("false") }
      // if(result === 'true') { console.log("true"), this.openDialogMAquininha() }
    });
  }
  openDialogMAquininha() {
    const _user = JSON.parse(localStorage.getItem('user'));
    console.log(_user.user.cpf);
    if (_user.user.cpf) {
      const dialogRef = this.dialog.open(ModalPaidFormComponent, {
        // width: "100%",
        height: "100%",
        // maxHeight: "100vw",
        maxWidth: "100vw",
        panelClass: "modalpagamentoform",
        data: {
          amount: 199,
          product_code: 'Subscription',
        },
      });
      // dialogRef.afterClosed().subscribe(result => {
      //     this.userProvider.checkPaid().then((res: any) =>{
      //         this.paid = res.paid
      //     }).catch((err: any)=>{
      //         console.log(err)
      //     })
      // });
    } else {
      const dialogRef = this.dialog.open(ModalCpfFormComponent, {
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.authProvider.me();
      });
    }
  }

  ngOnDestroy() {
    console.log('teste header');
    this.route.fragment.subscribe((fragment) => {
      this.fragment = fragment;
    });
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }

  isCandidate() {
    if (this.ENV.logged) {
      const _user = JSON.parse(localStorage.getItem('user'));
      if (
        _user.user.role &&
        _user.user.role.descricao &&
        String(_user.user.role.descricao) === 'candidato'
      ) {
        return true;
      }
      return false;
    }
  }

  changeOpen() {
    console.log('INFERNOOOOOOO: ', this.ENV.open);
    this.ENV.open = !this.ENV.open;
  }

  visualizar() {
    if (localStorage.getItem('urna')) {
      let _urna = localStorage.getItem('urna');
      return this.router.navigate(['/' + _urna]);
    }

    let _user = JSON.parse(localStorage.getItem('user'));
    let userRef = _user.user._id;
    this.urna
      .getOne({ userRef })
      .then((res2) => {
        if (res2 !== undefined && res2.urna[0]) {
          localStorage.setItem('urna', res2.urna[0].alias);
          this.router.navigate(['/' + res2.urna[0].alias]);
        } else {
          this.router.navigate(['/perfil']);
        }
      })
      .catch((err) => {
        this.router.navigate(['/perfil']);
      });
  }

  doacoes() {
    this.router.navigate(['/painel-doacoes']);
  }

  toggle() {}

  @HostListener('window:scroll', ['$event'])
  onScroll(ev) {
    const scrollPosition = window.pageYOffset;
    if (scrollPosition >= this.prev) {
      this.headerHide = true;
      this.prev = scrollPosition;
    } else {
      this.headerHide = false;
      this.prev = scrollPosition;
    }
  }

  ngAfterViewChecked(): void {
    try {
      document.querySelector('#' + this.fragment).scrollIntoView();
    } catch (e) {}
  }

  onClick(elementId: string): void {
    if (this.router.url == '/') this.scrollService.changeNav(elementId);
    else
      this.router.navigateByUrl('/').then(() => {
        setTimeout(() => {
          this.scrollService.changeNav(elementId);
        }, 500);
      });
  }

  logout() {
    this.ENV.logged = false;
    localStorage.clear();
    this.router.navigate(['/']);
  }
}
