<!-- <div>
  <div class="card">
    <div class="container">
      <div class="header">
        <img class="logo" src="../../assets/logo/logo.svg" />
        <h3> Confirme o endereço de cobrança: </h3>
      </div>
      <div class="form">
        <default-form (keyup.enter)="performClick(register)" [forms]="formAddress" [step]="0"></default-form>
        <mat-checkbox (keyup.enter)="performClick(register)" class="example-margin" [(ngModel)]="terms"><p>Eu concordo com os <a> termos de uso da maquininha.</a></p></mat-checkbox>
      </div>
    </div>
  </div>
  <div class="btn-container">
    <button mat-flat-button #register class="btn2" (click)="onSubmit()">Pagar</button>
  </div>
</div> -->
<div>
    <div class="card">
        <div class="container">
            <div class="header">
                <img class="logo" src="../../assets/logo/logo.svg" />
                <h3> Confirme os dados para taxa de inscrição: </h3>
                <div *ngIf="actualAmount">
                    <h1>{{ actualAmount | currency : "R$"}}</h1>
                </div>
            </div>
            <div class="form">
                <mat-horizontal-stepper [linear]="true" #stepper *ngIf="!completed && !debitComplete">
                    <mat-step [completed]="isValidAddress()">
                        <ng-template matStepLabel>Endereço de cobrança</ng-template>
                        <default-form (keyup.enter)="performClick(avancarEnd)" [forms]="formAddress" [step]="0"
                            *ngIf="formAddress"></default-form>
                        <br>

                        <div class="btn-container">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <button #avancarEnd mat-flat-button matStepperNext class="confirm-button full">
                                        Avançar</button>
                                </div>
                            </div>
                        </div>
                    </mat-step>
                    <mat-step [completed]="formPersonal[0].builder">
                        <ng-template matStepLabel>Dados pessoais</ng-template>
                        <default-form [forms]="formPersonal" (keyup.enter)="performClick(avancarDados)" [step]="0"
                            *ngIf="formPersonal"></default-form>
                        <br>
                        <br>
                        <div class="btn-container">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <button mat-flat-button matStepperPrevious class="confirm-button full grey">
                                        Voltar</button>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 align-right">
                                    <button #avancarDados mat-flat-button (click)="isValidPersonal(stepper)"
                                        class="confirm-button full">Avançar</button>
                                </div>
                            </div>
                        </div>
                    </mat-step>
                    <mat-step>
                        <ng-template matStepLabel>Escolha a forma de pagamento</ng-template>
                        <div class="full p20t">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="questions">
                                            Cartão de Crédito
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <mat-panel-description>
                                        <div class="full">
                                            <br><br>
                                            <default-form (keyup.enter)="performClick(enviarCredito)"
                                                [forms]="formCredito" [step]="0"></default-form>
                                            <div class="marginRecaptcha">
                                                <re-captcha #captchaElemCredit (resolved)="resolved($event,'credito')" type="image"
                                                    [siteKey]="siteKey"></re-captcha>
                                            </div>
                                                <button mat-flat-button [disabled]="checkDisabled()" #enviarCredito
                                                    class="confirm-button full" (click)="submitTransaction('credito')">
                                                    Enviar</button>
                                            </div>
                                    </mat-panel-description>
                                </mat-expansion-panel>

                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="questions">
                                            Boleto Bancário
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <mat-panel-description>
                                        <div class="full">
                                            <div>
                                                <default-form (keyup.enter)="performClick(enviarBoleto)"
                                                    [forms]="formBoleto" [step]="0"></default-form>
                                                <div class="marginRecaptcha">
                                                    <re-captcha #captchaElemBankslip (resolved)="resolved($event,'boleto')" type="image"
                                                        [siteKey]="siteKey"></re-captcha>
                                                </div>
                                                <button mat-flat-button [disabled]="checkDisabled()" #enviarBoleto
                                                    class="confirm-button full" (click)="submitTransaction('boleto')">
                                                    Gerar Boleto
                                                </button>
                                            </div>
                                        </div>
                                    </mat-panel-description>

                                </mat-expansion-panel>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="questions">
                                            PIX
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <mat-panel-description>
                                        <div class="full">
                                            <div>

                                                <default-form (keyup.enter)="performClick(enviarPIX)"
                                                    [forms]="formBoleto" [step]="0"></default-form>
                                                <div class="marginRecaptcha">
                                                    <re-captcha #captchaElemPix (resolved)="resolved($event,'pix')" type="image"
                                                        [siteKey]="siteKey"></re-captcha>
                                                </div>
                                                <button mat-flat-button [disabled]="checkDisabled()" #enviarPIX
                                                    class="confirm-button full" (click)="submitTransaction('pix')">
                                                    Gerar PIX
                                                </button>
                                            </div>
                                        </div>
                                    </mat-panel-description>
                                </mat-expansion-panel>
                            </mat-accordion>
                            </div>
                    </mat-step>
                </mat-horizontal-stepper>



        <div class="btn-container" *ngIf="completed">
            <div *ngIf="!paymentError && !BankSlipUrl && !pixSVG && !creditResponse"  class="containerResponse colorError">
                <!-- <h3>Pagamento em processamento, aguarde a confirmação por e-mail!</h3> -->
                <h3>{{errorResponse}}</h3>
            </div>
            <div *ngIf="creditResponse" class="containerResponse colorSuccess">
            <h3>{{creditResponse}}</h3>
            </div>
            <div *ngIf="paymentError">
                <p><b>Erro ao realizar o pagamento</b></p><br>
                <p>{{paymentError}}</p>
            </div>
            <br><br>
            <div *ngIf="barcode">
                <p class="barcodetext">
                    Código de barras:<br> <strong>{{barcode}}</strong>
                </p>
            </div>

                <div class="btn-container" *ngIf="completed">
                    <div *ngIf="!paymentError && !BankSlipUrl && !pixSVG && !creditResponse"
                        class="containerResponse colorError">
                        <!-- <h3>Pagamento em processamento, aguarde a confirmação por e-mail!</h3> -->
                        <h3>{{errorResponse}}</h3>
                    </div>
                    <div *ngIf="creditResponse" class="containerResponse colorSuccess">
                        <h3>{{creditResponse}}</h3>
                    </div>
                    <div *ngIf="paymentError">
                        <p><b>Erro ao realizar o pagamento</b></p><br>
                        <p>{{paymentError}}</p>
                    </div>
                    <br><br>
                    <div *ngIf="barcode">
                        <p>
                            Código de barras:<br> <strong>{{barcode}}</strong>
                        </p>
                    </div>

                    <div *ngIf="BankSlipUrl && !pixSVG" class="containerResponse colorSuccess">
                        <h3>Clique <u><a href="{{BankSlipUrl}}" target="_blank">aqui</a></u> para visualizar o boleto
                        </h3>
                    </div>
                    <div *ngIf="BankSlipUrl && !pixSVG" class="voltar">
                        <label>ou</label><br>
                        <div>
                            <a (click)="cancel()">
                                voltar para a página do candidato</a>
                        </div>
                    </div>
                    <br>
                    <div *ngIf="!BankSlipUrl && !pixSVG" class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <button mat-flat-button (click)="cancel()" class="confirm-button full">
                                voltar para a página do candidato</button>
                        </div>
                    </div>
                    <div *ngIf="pixSVG">
                        <div class="row">

                            <div class="col-lg-12 col-md-12" style="text-align: center;">
                                <img src="{{pixSVG}}" class="img-size">
                            </div>
                        </div>
                        <i *ngIf="copied" class="fa fa-solid fa-check green"> Copiado com sucesso</i>
                        <div (click)="copyMessage(pixCode)" class="containerResponse colorSuccess">
                            PIX Copia e Cola:<br> <strong>{{ pixCode }}</strong>
                        </div>
                        <p class="text"> Para pagar, escaneie o QR Code acima. Ou você pode, também, copiar o código do
                            PIX e colar no aplicativo da sua instituição financeira.</p>
                        <button mat-flat-button (click)="copyMessage(pixCode)"
                            class="confirm-button full download-button">
                            Copiar PIX</button>
                    </div>
                    <br>
                    <div *ngIf="pixSVG" class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <button mat-flat-button (click)="cancel()" class="confirm-button full">
                                Fechar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
