<!-- Generated template for the DefaultFormComponent component -->
<div *ngIf="forms && step !== undefined">
	<div *ngFor="let f of forms; let idx = index;">
		<div [ngSwitch]="step">
			<form [formGroup]="f.builder" *ngSwitchCase="idx">
				<!-- <nb-card-header *ngIf="f.title">{{ f.title }}</nb-card-header> -->
				<div no-lines class="row form-items">
					<div *ngFor="let field of f.fields" class="{{field.cols || 'col-lg-12'}}">
						<div class="form-group" class="form-item" [ngSwitch]="field.type">
							<label style="{{field.invisible == true? 'color: white' : ''}}" [ngClass]="{ disabled: field.readonly == true }">{{ field.label }}</label>
							<div class="input-group" *ngSwitchDefault>
								<span class="input-group-addon" *ngIf="field.icon"><i
										class="fa {{ field.icon }}"></i></span>
								<mat-form-field [ngClass]="{ disabled: field.readonly == true }" appearance="outline"
									class="default-full-width">
									<input matInput type="{{ field.type }}"
										(blur)="field?.blurCallback(field.value) || null"
										[class.form-control-success]="f.builder.get(field.name).valid && f.builder.get(field.name).touched==true"
										[class.form-control-danger]="f.builder.get(field.name).invalid && f.builder.get(field.name).touched==true"
										class="form-control" placeholder="{{ field.placeholder }}"
										autocomplete="{{field.autocomplete}}" formControlName="{{ field.name }}"
										value="{{field.value}}" [readonly]="field.readonly" (input)="field.onChange($event)"/>
								</mat-form-field>
							</div>
              <div class="input-group input-group-customText" *ngSwitchCase="'customText'">
								<span class="input-iconField error" *ngIf="validCode == 'invalido'"><i
										class="fa fa-times"></i></span>
								<span class="input-iconField success" *ngIf="validCode == 'valido'"><i
										class="fa fa-check"></i>&nbsp; &nbsp; {{customTextInfo}}</span>
                    <span class="message-customText success" *ngIf="validCode == 'valido'"></span>
								<mat-form-field [ngClass]="{ disabled: field.readonly == true }" appearance="outline"
									class="default-full-width">
									<input matInput type="{{ field.type }}"
										(blur)="field?.blurCallback(field.value) || null"

										[class.form-control-success]="f.builder.get(field.name).valid && f.builder.get(field.name).touched==true"
										[class.form-control-danger]="f.builder.get(field.name).invalid && f.builder.get(field.name).touched==true"
										class="form-control" placeholder="{{ field.placeholder }}"
										autocomplete="{{field.autocomplete}}" formControlName="{{ field.name }}"
										value="{{field.value}}" [readonly]="field.readonly" (input)="searchCouponCode($event,field)"/>
								</mat-form-field>
							</div>
							<div class="input-group" *ngSwitchCase="'number'">
								<span class="input-group-addon" *ngIf="field.icon"><i
										class="fa {{ field.icon }}"></i></span>
								<mat-form-field [ngClass]="{ disabled: field.readonly == true }" appearance="outline"
									class="default-full-width">
									<input matInput type="number"
										(blur)="field?.blurCallback(field.value) || null"
										[class.form-control-success]="f.builder.get(field.name).valid && f.builder.get(field.name).touched==true"
										[class.form-control-danger]="f.builder.get(field.name).invalid && f.builder.get(field.name).touched==true"
										class="form-control" placeholder="{{ field.placeholder }}"
										autocomplete="{{field.autocomplete}}" formControlName="{{ field.name }}"
										value="{{field.value}}" [readonly]="field.readonly" />
								</mat-form-field>
							</div>
							<div class="input-group hover" *ngSwitchCase="'textHover'">
								<mat-form-field appearance="outline" class="default-full-width">
									<input matInput type="{{ field.type }}"
										(blur)="field?.blurCallback(field.value) || null"
										[class.form-control-success]="f.builder.get(field.name).valid && f.builder.get(field.name).touched==true"
										[class.form-control-danger]="f.builder.get(field.name).invalid && f.builder.get(field.name).touched==true"
										class="form-control" placeholder="{{ field.placeholder }}"
										autocomplete="{{field.autocomplete}}" formControlName="{{ field.name }}"
										value="{{field.value}}" [readonly]="field.readonly" />
									<button *ngIf="field.icon" mat-icon-button matSuffix matTooltip="{{field.tooltip}}">
										<span class="input-group-addon material-icons">{{ field.icon }}</span>
									</button>
								</mat-form-field>
							</div>
							<div class="input-group urlInput" *ngSwitchCase="'urlInput'">
								<p style="white-space: nowrap;">{{ field.customString }}</p>
								<mat-form-field appearance="outline" class="default-full-width">
									<input matInput type="{{ field.type }}"
										(blur)="field?.blurCallback(field.value) || null"
										[class.form-control-success]="f.builder.get(field.name).valid && f.builder.get(field.name).touched==true"
										[class.form-control-danger]="f.builder.get(field.name).invalid && f.builder.get(field.name).touched==true"
										class="form-control" placeholder="{{ field.placeholder }}"
										autocomplete="{{field.autocomplete}}" formControlName="{{ field.name }}"
										value="{{field.value}}" [disabled]="field.disabled" />
								</mat-form-field>
							</div>
							<div class="input-group urlInput hover" *ngSwitchCase="'urlHover'">
								<p>{{ field.customString }}</p>
								<mat-form-field appearance="outline" class="default-full-width">
									<input matInput type="{{ field.type }}"
										(blur)="field?.blurCallback(field.value) || null"
										[class.form-control-success]="f.builder.get(field.name).valid && f.builder.get(field.name).touched==true"
										[class.form-control-danger]="f.builder.get(field.name).invalid && f.builder.get(field.name).touched==true"
										class="form-control" placeholder="{{ field.placeholder }}"
										autocomplete="{{field.autocomplete}}" formControlName="{{ field.name }}"
										value="{{field.value}}" [disabled]="field.disabled" />
									<button *ngIf="field.icon" mat-icon-button matSuffix matTooltip="{{field.tooltip}}">
										<span class="input-group-addon material-icons">{{ field.icon }}</span>
									</button>
								</mat-form-field>

							</div>
							<div class="input-group" *ngSwitchCase="'mask'">
								<!-- <mat-form-field  appearance="outline" class="default-full-width">
									<input matInput class="form-control" type="text"
										[class.form-control-success]="f.builder.get(field.name).valid && f.builder.get(field.name).touched==true"
										[class.form-control-danger]="f.builder.get(field.name).invalid && f.builder.get(field.name).touched==true"
										placeholder="{{ field.placeholder }}" [brmasker]="field.mask"
										(blur)="field.blurCallback(field.name) || null"
										formControlName="{{ field.name }}">
								</mat-form-field> -->
								<mat-form-field appearance="outline" class="default-full-width">
									<input matInput type="text" (blur)="field?.blurCallback() || null"
										[class.form-control-success]="f.builder.get(field.name).valid && f.builder.get(field.name).touched==true"
										[class.form-control-danger]="f.builder.get(field.name).invalid && f.builder.get(field.name).touched==true"
										class="form-control" placeholder="{{ field.placeholder }}"
										autocomplete="{{field.autocomplete}}" formControlName="{{ field.name }}"
										[brmasker]="field.mask" (keyup)="setValue($event, f.builder.get(field.name))"
										value="{{field.value}}"  />
								</mat-form-field>
							</div>
							<div class="input-group" *ngSwitchCase="'CPF/CNPJ'">
								<!-- <mat-form-field  appearance="outline" class="default-full-width">
									<input matInput class="form-control" type="text"
										[class.form-control-success]="f.builder.get(field.name).valid && f.builder.get(field.name).touched==true"
										[class.form-control-danger]="f.builder.get(field.name).invalid && f.builder.get(field.name).touched==true"
										placeholder="{{ field.placeholder }}" [brmasker]="field.mask"
										(blur)="field.blurCallback(field.name) || null"
										formControlName="{{ field.name }}">
								</mat-form-field> -->
								<mat-form-field appearance="outline" class="default-full-width">
									<input matInput type="text" (blur)="field?.blurCallback() || null"
										[class.form-control-success]="f.builder.get(field.name).valid && f.builder.get(field.name).touched==true"
										[class.form-control-danger]="f.builder.get(field.name).invalid && f.builder.get(field.name).touched==true"
										class="form-control" placeholder="{{ field.placeholder }}" maxlength="field?.maxlength" minlength="field?.minlength"
										autocomplete="{{field.autocomplete}}" formControlName="{{ field.name }}"
										[brmasker]="maskConfig" (input)="updateMask($event)" (keyup)="setValue($event, f.builder.get(field.name))"
										value="{{field.value}}"  />
								</mat-form-field>
							</div>
							<div class="input-group" *ngSwitchCase="'maskCep'">
								<!-- <mat-form-field class="default-full-width">
									<input matInput class="form-control" type="text"
										[class.form-control-success]="f.builder.get(field.name).valid && f.builder.get(field.name).touched==true"
										[class.form-control-danger]="f.builder.get(field.name).invalid && f.builder.get(field.name).touched==true"
										placeholder="{{ field.placeholder }}" [brmasker]="field.mask"
										(blur)="field.blurCallback(field.name) || null"
										formControlName="{{ field.name }}">
								</mat-form-field> -->
								<mat-form-field class="default-full-width" appearance="outline">
									<input matInput type="text" (blur)="field?.blurCallback() || null"
										[class.form-control-success]="f.builder.get(field.name).valid && f.builder.get(field.name).touched==true"
										[class.form-control-danger]="f.builder.get(field.name).invalid && f.builder.get(field.name).touched==true"
										class="form-control" placeholder="{{ field.placeholder }}"
										autocomplete="{{field.autocomplete}}" formControlName="{{ field.name }}"
										[brmasker]="{mask:'00000-000', len:9, type:'num'}"
										(keyup)="setValue($event, f.builder.get(field.name))" />
								</mat-form-field>
							</div>
							<!-- <div class="input-group" *ngSwitchCase="'text-btn'">
								<span class="input-group-addon" *ngIf="field.icon"><i  class="fa {{ field.icon }}" ></i></span>
								<input type="{{ field.type }}"
								[(ngModel)]="searchList"
								(paste)="getByEmailList(field.url,field.callback)"
								[class.form-control-success]="f.builder.get(field.name).valid && f.builder.get(field.name).touched==true"
								[class.form-control-danger]="f.builder.get(field.name).invalid && f.builder.get(field.name).touched==true"
								class="form-control" placeholder="{{ field.placeholder }}" formControlName="{{ field.name }}"/>
								<span class="input-group-btn" (click)="getByEmailList(field.url, field.callback)">
									<button class="btn btn-success"><i  class="fa fa-search" ></i></button>
								</span>
							</div> -->
							<div class="input-group" *ngSwitchCase="'date'">
								<!-- <span class="input-group-addon" (click)="d.toggle()"><i  class="fa fa-calendar-o" ></i></span> -->
								<mat-form-field appearance="outline" class="default-full-width">
									<input matInput type="text"
										[class.form-control-success]="f.builder.get(field.name).valid && f.builder.get(field.name).touched==true"
										[class.form-control-danger]="f.builder.get(field.name).invalid && f.builder.get(field.name).touched==true"
										class="form-control" placeholder="{{ field.placeholder }}"
										autocomplete="{{field.autocomplete}}" formControlName="{{ field.name }}"
										[brmasker]="{mask:'00/00/0000', len: 10}" dropSpecialCharacters="false" />
								</mat-form-field>
							</div>

							<div class="input-group" *ngSwitchCase="'datepicker'" >
                <mat-form-field appearance="outline" class="default-full-width">
					<input matInput type="date" min="{{ today }}" formControlName="{{ field.name }}" class="form-control" >
                </mat-form-field>
							</div>

							<div class="input-group" *ngSwitchCase="'dateHour'">
								<!-- <span class="input-group-addon" (click)="d.toggle()"><i  class="fa fa-calendar-o" ></i></span> -->
								<mat-form-field appearance="outline" class="default-full-width">
									<input matInput type="text"
										[class.form-control-success]="f.builder.get(field.name).valid && f.builder.get(field.name).touched==true"
										[class.form-control-danger]="f.builder.get(field.name).invalid && f.builder.get(field.name).touched==true"
										class="form-control" placeholder="{{ field.placeholder }}"
										autocomplete="{{field.autocomplete}}" formControlName="{{ field.name }}"
										[brmasker]="{mask:'00/00/0000 00:00', len: 16}" dropSpecialCharacters="false" />
								</mat-form-field>
							</div>
							<div class="input-group" *ngSwitchCase="'time'">
								<!-- <span class="input-group-addon" (click)="d.toggle()"><i  class="fa fa-clock-o" ></i></span> -->
								<mat-form-field appearance="outline" class="default-full-width">
									<input matInput type="text"
										[class.form-control-success]="f.builder.get(field.name).valid && f.builder.get(field.name).touched==true"
										[class.form-control-danger]="f.builder.get(field.name).invalid && f.builder.get(field.name).touched==true"
										class="form-control" placeholder="{{ field.placeholder }}"
										autocomplete="{{field.autocomplete}}" formControlName="{{ field.name }}"
										[brmasker]="{mask:'00:00'}" dropSpecialCharacters="false" />
								</mat-form-field>
							</div>
							<div class="input-group select-input" *ngSwitchCase="'select'">
								<mat-form-field appearance="outline" class="default-full-width">
									<mat-label>{{ field.placeholder }}</mat-label>
									<mat-select [(ngModel)]="field.selected" matNativeControl class="form-control" formControlName="{{ field.name }}"
										(selectionChange)="field?.clickCallback($event.value) || null" disabled="{{field.disabled ? true : false}}">

										<mat-option value="" selected></mat-option>
										<ng-container *ngFor="let option of field.options.items">
											<mat-option *ngIf="option[field.options.fields.secondText]"
												[value]="option[field.options.fields.value]">
												{{option[field.options.fields.secondText] }} -
												{{ option[field.options.fields.text] }}
											</mat-option>
											<mat-option *ngIf="!option[field.options.fields.secondText]"
												[value]="option[field.options.fields.value]">
												{{ option[field.options.fields.text] }}
											</mat-option>
										</ng-container>
									</mat-select>
								</mat-form-field>
							</div>
							<div class="input-group" *ngSwitchCase="'select2'">
								<mat-form-field appearance="outline" class="default-full-width">
									<mat-label>{{ field.placeholder }}</mat-label>
									<mat-select formControlName="{{ field.name }}"
										(selectionChange)="field.clickCallback($event.value)">
										<ng-container *ngFor="let option of field.options.items">
											<mat-option *ngIf="option[field.options.fields.secondText]"
												[value]="option[field.options.fields.value]" [ngClass]="option.class">
												{{option[field.options.fields.secondText] | date: "dd/MM/yyyy"}} -
												{{ option[field.options.fields.text] }}
											</mat-option>
											<mat-option *ngIf="!option[field.options.fields.secondText]"
												[value]="option[field.options.fields.value]" [ngClass]="option.class">
												{{option[field.options.fields.text]}}
											</mat-option>
										</ng-container>
									</mat-select>
								</mat-form-field>
							</div>
							<div class="dn" *ngSwitchCase="'hidden'">
								<input type="{{ field.type }}" formControlName="{{ field.name }}" />
							</div>
							<div class="input-group" *ngSwitchCase="'textarea'">
								<mat-form-field appearance="outline" class="default-full-width">
									<textarea matInput class="form-control"
										[class.form-control-success]="f.builder.get(field.name).valid && f.builder.get(field.name).touched==true"
										[class.form-control-danger]="f.builder.get(field.name).invalid && f.builder.get(field.name).touched==true"
										rows="{{ field.rows }}" placeholder="{{ field.placeholder }}"
										formControlName="{{ field.name }}"></textarea>
								</mat-form-field>
							</div>
							<!-- field.type === 'search' -->
							<div *ngSwitchCase="'search'" class="search-container">
								<mat-form-field appearance="outline" class="default-full-width">
									<input matInput formControlName="{{ field.name }}"
										(blur)="field.blurCallback() || null" [(ngModel)]="busca"
										(keyup)="searchCity(busca)" [disabled]="field.disabled || false"
										placeholder="{{ field.placeholder }}" />
								</mat-form-field>
								<div class="lista-cidades" *ngIf="arrCities">
									<div class="cities-search">
										<div *ngFor="let item of arrCities;">
											<div padding-left class="city-item"
												(click)="field.clickSearch(item);arrCities=undefined;busca=item.name">
												{{item.name}} - {{item.state[0].name}}
											</div>
										</div>
									</div>
								</div>
							</div>
							<!-- <div *ngSwitchCase="'imgng2'">
								<div *ngIf="field.images" class="images-container">
									<div class="ng2-container" *ngFor="let img of field.images" (click)="img.removeImg(img)">
										<span class="ng2-text">{{img.name}}</span>
										<img *ngIf="img.url" [src]="img.url" class="ng2-thumb">
										<span class="ng2-remove">
											<p>Remove</p>
										</span>
									</div>
								</div>
								<ng2-file-input id="{{field.name}}"
									[class.form-control-success]="f.builder.get(field.name).valid && f.builder.get(field.name).touched==true"
									[class.form-control-danger]="f.builder.get(field.name).invalid && f.builder.get(field.name).touched==true"
									[currentFiles]="field.images" [multiple]="field.multiple" (onAdded)="field.onAdded($event)"
									(onRemoved)="field.onRemoved($event)">
								</ng2-file-input>
							</div> -->
							<!-- <div class="input-group" style="width: 30%" *ngSwitchCase="'color-picker'">
								<input [value]=""
									[(colorPicker)]="field.name"
									[style.background]="field.name"
									type="{{ field.type }}"
									class="form-control"/>
							</div> -->

							<!-- <div *ngSwitchCase="'auto-complete'" >
								<div class="input-group">
									<span class="input-group-addon" (click)="getItensBySearch(field.url)"><i  class="fa fa-search" ></i></span>
									<input class="form-control"
										[(ngModel)]="search"
										(paste)="getItensBySearch(field.url)"
										(keyup)="getItensBySearch(field.url)"
										placeholder="{{ field.placeholder }}"
										formControlName="{{ field.name }}"/>
								</div>
								<div class="dropdown-container">
									<ul class="dropdown">
										<li class="dropdown-item selected" *ngIf="selectedItem">
											<img [src]="getImage(selectedItem)" class="avatar-list">
											<h3>{{selectedItem.profile.name}}</h3>
											{{selectedItem.email}}
										</li>
										<li *ngFor="let item of searchItens" (click)="selectItem(item, field.callback)" class="dropdown-item">
											<img [src]="getImage(item)" class="avatar-list">
											<h3>{{item.profile.name}}</h3>
											{{item.email}}
										</li>
									</ul>
								</div>
							</div>  -->
							<!-- <div *ngSwitchCase="'auto-complete'">
								<mat-form-field  appearance="outline" class="default-full-width">
									<input type="text" matInput placeholder="{{ field.placeholder }}" aria-label="Number"
										formControlName="{{ field.name }}" [matAutocomplete]="auto">
									<mat-autocomplete #auto="matAutocomplete">
										<mat-option *ngFor="let option of field.options.items" [value]="option">
											{{option}}
										</mat-option>
									</mat-autocomplete>
								</mat-form-field>
							</div> -->
							<div *ngSwitchCase="'checkbox'" class="{{ field.horizontal ? 'horizontal':  ''}}">
								<mat-label>{{ field.placeholder }}</mat-label>
								<div *ngFor="let option of field.options.items; let i = index" class="horizontal_margin">
									<div *ngIf="!option.hidden">
									<mat-checkbox class="" (change)="field.clickCallback(option, $event ,field.control)"
									[checked]="option.checked">
									{{ option[field.options.fields.text] }}
									</mat-checkbox>
								</div>
								</div>
							</div>
							<div *ngSwitchCase="'checkbox-qa'">
								<mat-label>{{ field.placeholder }}</mat-label>
								<div *ngFor="let option of field.options.items; let i = index">
									<mat-checkbox class="" (click)="field.clickCallback(option, field.control)"
										(change)="field.callback(option)"
										formControlName="{{ field.name }}"
										[checked]="option.checked">
										{{ option[field.options.fields.text] }}
									</mat-checkbox>
								</div>
							</div>
							<div *ngSwitchCase="'checkbox2'">
								<section class="exemple-teste">
									<mat-checkbox class="example-margin" (click)="field.callback()"
										[checked]="f.builder.get(field.name).value">
										{{field.checkTitle}}
									</mat-checkbox>
								</section>
							</div>
							<div *ngSwitchCase="'multiselect'">
								<!-- <ng-multiselect-dropdown
									[placeholder]="field.placeholder"
									[ngModelOptions]="{standalone: true}"
									[data]="field.data"
									[searchPlaceholderText]="'Buscar'"
									[(ngModel)]="field.selectedItems"
									[settings]="field.dropdownSettings"
									(onSelect)="field.onItemSelect($event, field.control)"
									(onSelectAll)="field.onSelectAll($event)"
									(onDeSelect)="field.onItemDeSelect($event, field.control)"
									formControlName="{{ field.name }}"
									></ng-multiselect-dropdown> -->
								<mat-form-field appearance="outline" class="default-full-width">
									<mat-label>{{ field.placeholder }}</mat-label>
									<mat-select [formControlName]="field.name" multiple>
										<mat-option *ngFor="let option of field.data; index as i" [value]="option._id"
											(onSelectionChange)="field.change($event, option)">
											{{option.title || option.type}}
										</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
							<div *ngSwitchCase="'radio'">
								<!-- <label class="custom-control custom-radio">
									<input type="radio" class="custom-control-input"
										[value]="option[field.options.fields.value]"
										(click)="field.callback(option, field.control)"
										[name]="option[field.options.nameModel]">
									<span class="custom-control-indicator"></span>
									<span class="custom-control-description"> {{ option[field.options.fields.text] }} </span>
								</label> -->
								<mat-radio-group aria-label="[field.placeholder]">
									<mat-radio-button *ngFor="let option of field.options.items; let i = index"
										[value]="option[field.options.fields.value]"
										(click)="field.callback(option, field.control)">
										{{ option[field.options.fields.text] }}
									</mat-radio-button>
								</mat-radio-group>
							</div>
							<div *ngSwitchCase="'searchselect'" class="multiselect-dropdown-container">
								<ng-multiselect-dropdown [placeholder]="field.placeholderSelect" [data]="field.data"
									[formControlName]="field.name" [settings]="field.dropdownSettings"
									(onSelect)="field.onItemSelect($event)" (onDeSelect)="field.onItemDeSelect($event)">
								</ng-multiselect-dropdown>
							</div>

							<!-- <div *ngSwitchCase="'search'">
								<div class="form-item">
									<mat-form-field  appearance="outline" class="default-full-width">
										<input matInput formControlName="{{ field.name }}"  placeholder="{{ field.placeholder }}"
												[(ngModel)]="busca" (keyup)="searchCity(busca)">
									</mat-form-field>
								</div>
								<div class="search-list" *ngIf="searchResult">
									<div  class="search">
										<div *ngFor="let item of searchResult;">
											<div class="search-item" (click)="field.clickSearch(item); searchResult=undefined">
												{{item.name}}
											</div>
										</div>
									</div>
								</div>
							</div> -->

						</div>
						<div class="form-validation"
							*ngIf="f.builder.controls[field.name]['touched'] == true && f.builder.controls[field.name]['valid'] == false">
							<ng-container *ngFor="let validation of f.validation_messages[field.name]">
								<p ion-text color="danger" *ngIf="f.builder.get(field.name).hasError(validation.type)">
									<small>{{ validation.message }}</small>
								</p>
							</ng-container>
						</div>
					</div>
					<!-- Validation messages for each input -->
				</div>
			</form>
		</div>
	</div>
</div>
