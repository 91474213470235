<div class="home-content">
    <div class="content" fxFlex="50" wmAnimate="landing" speed="slow" aos>
        <div class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1">
            <div class="row component-container">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 header">
                    <div class="row">
                        <div
                            class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12">
                            <form class="former">
                                <mat-form-field appearance="outline" class="form-field">
                                    <mat-label>Pesquise a vaquinha aqui</mat-label>
                                    <input type="text" matInput name="keyword" [(ngModel)]="keyword"
                                        (keyup.enter)="search()" placeholder="Nome, Partido, Cidade ou Bandeiras">

                                        <!-- <button mat-icon-button matSuffix matTooltip="Pesquisar" (click)="search()">
                                        <span class="input-icon material-icons">search</span>
                                    </button> -->
                                </mat-form-field>

                                  <!-- <mat-form-field appearance="outline" class="year-field">
                                  <mat-label>Year</mat-label>
                                  <mat-select selected="2023" [(value)]="selectedYear">
                                  <div *ngFor="let year of listYear; index as i">
                                      <mat-option [value]="year.year" > {{year.year}} </mat-option>
                                    </div>
                                  </mat-select>
                                </mat-form-field> -->

                                <button mat-icon-button matSuffix matTooltip="Pesquisar" (click)="search()" class="button-search">
                                  <span class="input-icon material-icons button-span">search</span>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="box">
                        <div class="row">
                            <div class="null" *ngIf="!candidatos[0]">
                                <p> Nenhum resultado foi encontrado para sua busca :( </p>
                            </div>
                            <div *ngFor="let i of candidatos" class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                <mat-card *ngIf="i.image" class="image-card">
                                    <div class="avatar">
                                        <div class="avatar-img center-cropped" (click)="navigate(i)"
                                            style="background-image: url({{ i.image.host+i.image.full }});">
                                        </div>
                                    </div>
                                    <div class="text page-person">
                                        <h1 class="click capitalize" (click)="navigate(i)">{{ i.hasOwnProperty('description') ? i.title : '' }}</h1>
                                        <p class="detalhes-candidato"><b>{{ i.partido }}, {{ i.where.toUpperCase() }}</b></p>
                                        <span class="font">total arrecadado:</span>
                                        <h3 *ngIf="i.totalCollected != 0" class="money">
                                            <b>{{ i.totalCollected | currency : 'R$'  }}</b></h3>
                                        <h3 *ngIf="i.totalCollected == 0" class="money"><b>Seja o <strong>primeiro</strong> a
                                                doar!</b></h3>
                                    </div>
                                    <mat-card-actions>
                                        <button class="redirect-button" mat-flat-button (click)="navigate(i)"><img class="icon"
                                                src="../../assets/porquinho2.png" >doar agora</button>
                                    </mat-card-actions>
                                </mat-card>
                            </div>
                        </div>
                    </div>
                </div>
                <div (click)="expand()" *ngIf="!finalSearch"
                    class="col-xs-12 col-sm-12 col-md-12 col-lg-12 expand">
                    <span class="input-group-addon material-icons">keyboard_arrow_down</span>
                    <a>Ver Mais</a>
                </div>
            </div>
            <br>
        </div>
    </div>
</div>
