<div>
  <div class="card">
    <div class="container">
      <div class="header">
        <img class="logo" src="../../assets/logo/logo.svg" />
        <h3> Para o Doador </h3>
      </div>
      <div class="form">
          Declaro sob as penas de Direito que:
          Não estou realizando a doação via Pessoa Jurídica; <br>
          Os Recursos não são provenientes Origem Estrangeira; <br>
          Que não sou pessoa física permissionária de serviço público; <br>
          Que sou o Maior, Capaz e titular da conta/cartão originário da doação
          Caso a doação seja realizada via cartão de crédito sou o titular deste
          Que estou ciente das limitações de doações estipuladas na res. 23.607/2019 e Lei 9.504/97.
          Declaro que estou ciente das regras no que tange a divulgação dos meus dados necessários para cumprimento das 
          regras de transparência na legislação eleitoral e autorizo, nos termos da LGPD a divulgação de tais informações pela plataforma de arrecadação.
      </div>
    </div>
  </div>
 
</div>