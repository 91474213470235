import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { DefaultHeaderAdminComponent } from './default-header-admin/default-header-admin';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import {MatToolbarModule} from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
@NgModule({
    declarations: [DefaultHeaderAdminComponent],
    imports: [CommonModule,
        MatMenuModule,
        MatToolbarModule,
        RouterModule,
        MatButtonModule
    ],
    exports: [DefaultHeaderAdminComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})

export class MenuAdminModule {}
