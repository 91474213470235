import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/providers/auth.service';
import { UtilsProvider } from 'src/commons/utils';
import { ENV } from 'src/environments/environment-variables.token';
import { UserService } from 'src/providers/user.service';
import { HttpService } from 'src/providers/http.service';
import { Router } from '@angular/router';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CandidateService } from 'src/providers/candidate.service';
import { ModalDefaultComponent } from '../modal-default/modal-default.component';
import { PaymentService } from '../../providers/payments.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SaqueModalComponent } from '../saque-modal/saque-modal.component';
import { TransferModalComponent } from '../transfer-modal/transfer-modal.component';
import { AdminComponent } from '../admin/admin.component';
import { AccountService } from 'src/providers/account.service.';
import * as moment from 'moment';
import { TransferService } from 'src/providers/transfer.service';

@Component({
  selector: 'app-exports-panel',
  templateUrl: './exports-panel.component.html',
  styleUrls: ['./exports-panel.component.scss'],
})
export class ExportsPanelComponent implements OnInit {
  public profileForm: any;
  public Editor = ClassicEditor;
  public profileData = '';
  displayedColumns: string[] = [
    'paymentCode',
    'donatorInfo',
    'status',
    'updatedAt',
    'donationValue',
    'recibo',
  ];

  name: string;
  cpf: number;
  date: string;
  donationMethod: string;
  value: string;
  candidato: any;
  saques: any;
  private total: any;
  private tax: any;
  public available = 0;
  public donationsList: any;
  public totalValue = 0;
  public availableValue: any;
  public liquidValue = 0;
  public diffValue = 0;
  public taxValue = 0;
  public grossValue = 0;
  public user: any;

  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public dataSource;

  // public isCandidate: any;
  constructor(
    public formBuilder: FormBuilder,
    public userService: UserService,
    public dialog: MatDialog,
    private httpService: HttpService,
    public authProvider: AuthService,
    @Inject(ENV) public ENV: any,
    public utils: UtilsProvider,
    private router: Router,
    private accountProvider: AccountService,
    private candidateProvider: CandidateService,
    private paymentsProvider: PaymentService,
    private transfersProvider: TransferService
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
    console.log(localStorage.getItem('user'));
    if (!localStorage.getItem('user')) {
      this.router.navigate(['/entrar']);
    }
    const _user = JSON.parse(localStorage.getItem('user'));
    this.user = _user.user;
    this.ENV.active = 'comprovante';
    const self = this;
    const data_inicial = new FormControl('', Validators.compose([]));
    const data_final = new FormControl('', Validators.compose([]));

    this.profileForm = [
      {
        fields: [
          {
            name: 'data_inicial',
            label: 'Data Inicial',
            placeholder: 'DD/MM/AAAA',
            type: 'date',
            cols: 'col-lg-5 col-md-5 col-sm-6 col-xs-12',
            blurCallback: () => {},
          },
          {
            name: 'data_final',
            label: 'Data Final',
            placeholder: 'DD/MM/AAAA',
            type: 'date',
            cols: 'col-lg-5 col-md-5 col-sm-6 col-xs-12',
            blurCallback: () => {},
          },
        ],
        builder: this.formBuilder.group({
          data_inicial,
          data_final,
        }),
        validation_message: {
          data_inicial: [],
          data_final: [],
        },
      },
    ];
    this.getUserInfo();
  }

  getUserInfo() {
    const _user = JSON.parse(localStorage.getItem('user'));
    console.log(_user);
    this.transfersProvider
      .getReceiptsV2({ user: _user.user._id })
      .then(async (res) => {
        console.log(res);
        this.donationsList = res;
        this.dataSource = new MatTableDataSource(this.donationsList);
        this.dataSource.paginator = this.paginator;
      })
      .catch((e) => {
        this.utils.toast({
          message: 'Não foi possível buscar informações do candidato',
        });
      });
  }

  isCandidate() {
    const _user = JSON.parse(localStorage.getItem('user'));
    if (
      _user.user.role &&
      _user.user.role.descricao &&
      String(_user.user.role.descricao) === 'candidato'
    ) {
      return true;
    }
    return false;
  }

  async showDonate(donate: any) {
    //window.open(`${donate.document.host}${donate.document.full}`)
    const file = await this.paymentsProvider.downloadFile({
      url: `${donate.document.host}${donate.document.full}`,
    });

    const data = Uint8Array.from(file.data);
    const content = new Blob([data.buffer], {
      type: 'application/octet-stream',
    });
    const encodedUri = window.URL.createObjectURL(content);

    const a = document.createElement('a');
    a.href = encodedUri; // application/octet-stream  text/plan
    a.download = donate.document.name;
    a.click();

    await this.transfersProvider.updateLog({ _id: donate._id });
    this.onSubmit();
  }

  exportFCC() {
    const _user = JSON.parse(localStorage.getItem('user'));
    const _id = _user.user._id;
    this.paymentsProvider.exportPayments({ user: _id });
  }

  getName(donate: any) {
    const name = String(donate.userName).toLowerCase().split(' ');
    return String(donate.userName).toLowerCase();
  }

  getValue(donate: any) {
    var money = 0;
    for (let transfer of donate.transfers) {
      money += transfer.value;
    }
    return money;
  }

  async onSubmit() {
    const _user = JSON.parse(localStorage.getItem('user'));
    const _id = _user.user._id;

    const dInicial = String(
      this.profileForm[0].builder.controls.data_inicial.value
    ).split('/');
    const inicial = dInicial[1] + '/' + dInicial[0] + '/' + dInicial[2];

    const dFinal = String(
      this.profileForm[0].builder.controls.data_final.value
    ).split('/');
    const final = dFinal[1] + '/' + dFinal[0] + '/' + dFinal[2];
    const dataInicial = new Date(inicial);
    const dataFinal = new Date(final);

    console.log(dataInicial);
    console.log(dataFinal);
    this.transfersProvider
      .getReceiptsV2({
        user: _id,
        dataInicial,
        dataFinal,
      })
      .then((res: any) => {
        this.donationsList = res;
        this.dataSource = new MatTableDataSource(this.donationsList);
        this.dataSource.paginator = this.paginator;
        console.log(this.donationsList);
      })
      .catch((e) => {
        this.utils.toast({ message: 'Erro ao filtrar resultados.' });
      });
  }
}
