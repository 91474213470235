import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";

@Component({
	selector: "app-modal-term-donation",
	templateUrl: "./modal-term-donation.component.html",
	styleUrls: ["../app.component.scss", "./modal-term-donation.component.scss"]
})
export class ModalTermDonationComponent implements OnInit {
	public modal: any;
	constructor(
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data: any
	) { }

	ngOnInit() {
	}

}
