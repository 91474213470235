<div>
  <div class="card">
    <div class="container">
      <div class="header">
        <img class="logo" src="../../assets/logo/logo.svg" />
        <h3> {{ data.title }} </h3>
      </div>
      <div class="form">
        <div class="normal-text" [innerHTML]="data.message"></div>
      </div>
    </div>
  </div>
  <div class="btn-container">
    <button mat-flat-button #register class="btn2"  [mat-dialog-close]="'true'">Avançar</button>
  </div>
</div>