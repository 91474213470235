import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { MatButton } from '@angular/material/button';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { DomainProvider } from 'src/providers/domains';
import { MaquininhaService } from 'src/providers/maquininha.service';
import { ENV } from 'src/environments/environment-variables.token';
import { UtilsProvider } from 'src/commons/utils';

@Component({
  selector: "maquininha-modal",
  templateUrl: "./maquininha-modal.component.html",
  styleUrls: ["../app.component.scss", "./maquininha-modal.component.scss"]
})
export class MaquininhaModalComponent implements OnInit {

  public title: string;
  public content: string;
  public event: any;
  public formAddress: any;
  public state: any;
  public terms = false;

  constructor(
    public formBuilder: FormBuilder,
    private domain: DomainProvider,
    private maquininha: MaquininhaService,
    public utils: UtilsProvider,
    public matDialogRef: MatDialogRef<MaquininhaModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(ENV) public ENV: any) { }

  ngOnInit() {
    const self = this;
    this.createFormAddress();

    const _user = JSON.parse(localStorage.getItem('user'));
    if (_user) {
      this.formAddress[0].builder.patchValue({
        ZipCode: _user.user.cep,
        Street: _user.user.endereco,
        StateInitials: _user.user.estado,
        CityName: _user.user.municipio
      });
    }
  }

  createFormAddress() {
    const self = this;

    const ZipCode = new FormControl('', Validators.compose([Validators.required]));
    const Street = new FormControl('', Validators.compose([Validators.required]));
    const Number = new FormControl('', Validators.compose([Validators.required]));
    const District = new FormControl('', Validators.compose([Validators.required]));
    const StateInitials = new FormControl('', Validators.compose([Validators.required]));
    const CityName = new FormControl('', Validators.compose([Validators.required]));
    const CountryName = new FormControl('', Validators.compose([Validators.required]));
    const Complement = new FormControl('', undefined);

    this.formAddress = [
      {
        fields: [
          {
            name: 'ZipCode',
            type: 'maskCep',
            label: 'CEP',
            maxlength: 9,
            mask: { mask: '00000-000', len: 9 },
            cols: 'col-lg-3 col-md-3 cols-sm-12 col-xs-12',
            blurCallback() {
              // console.log("teste cep", self.institutionForm[0].builder.controls.cep.value);
              let cepValue = self.formAddress[0].builder.controls.ZipCode.value;
              if (cepValue.length !== 0) {
                cepValue = cepValue.replace(/\W+/g, '');
                self.buscarCep(cepValue);
              }
            }
          },
          {
            name: 'Street',
            type: 'text',
            label: 'Rua / Logradouro',
            cols: 'col-lg-9 col-md-9 col-sm-12 col-xs-12',
            blurCallback() { }
          },
          {
            name: 'Number',
            type: 'text',
            label: 'Número',
            cols: 'col-lg-3 col-md-3 col-sm-4 col-xs-4',
            blurCallback() { }
          },
          {
            name: 'Complement',
            type: 'text',
            label: 'Complemento',
            cols: 'col-lg-3 col-md-3 col-sm-8 col-xs-8',
            blurCallback() { }
          },
          {
            name: 'District',
            type: 'text',
            label: 'Bairro',
            cols: 'col-lg-6 col-md-6 col-sm-12 col-xs-12',
            blurCallback() { }
          },
          {
            name: 'CityName',
            type: 'text',
            label: 'Cidade',
            cols: 'col-lg-6 col-md-6 col-sm-12 col-xs-12',
            blurCallback() { }
          },
          {
            name: 'StateInitials',
            type: 'text',
            label: 'UF',
            cols: 'col-lg-3 col-md-3 col-sm-4 col-xs-4',
            blurCallback() { }
          },
          {
            name: 'CountryName',
            type: 'text',
            label: 'País',
            cols: 'col-lg-3 col-md-3 col-sm-8 col-xs-8',
            blurCallback() { }
          },


        ],
        builder: this.formBuilder.group({
          ZipCode,
          Street,
          Number,
          Complement,
          District,
          CityName,
          StateInitials,
          CountryName
        }),
        validation_messages: {}
      }
    ];
  }

  close() {
    this.matDialogRef.close()
  }

  onSubmit() {
    if (!this.isValidAddress()) {
      return this.utils.toast({
        message: 'Por favor, preencha o formulário corretamente.',
        action: 'OK',
        duration: 10000
      });
    } else {
      if (!this.terms) {
        return this.utils.toast({
          message: 'Por favor, aceite nossos termos de uso.',
          action: 'OK',
          duration: 10000
        });
      }
      const data = this.formAddress[0].builder.value
      const _user = JSON.parse(localStorage.getItem('user'));
      this.maquininha.createRequest({
        userRef: _user.user._id,
        userName: _user.user.name,
        userEmail: _user.user.email,
        ...data
      }).then(success => {
        this.utils.toast({
          message: 'Solicitação enviada com sucesso!',
          action: 'SUCESSO',
          duration: 10000
        });
        return this.matDialogRef.close()
      }).catch(err => {
          this.utils.toast({
            message: "Você já tem uma solicitação pendente, por favor aguarde nosso retorno ou tente novamente mais tarde.",
            action: 'OK',
            duration: 10000
          });
      })

    }
  }

  performClick(el: MatButton) {
    el._elementRef.nativeElement.click()
  }

  buscarCep(cepValue) {
    if (cepValue.length === 8) {
      this.ENV.loading = true;
      this.domain.getCEP(cepValue).then((data: any) => {
        console.log(data);

        this.formAddress[0].builder.patchValue({
          Street: data.logradouro || '',
          District: data.bairro || '',
          CityName: data.localidade || '',
          StateInitials: data.uf || '',
          CountryName: 'Brasil',
        });
        this.ENV.loading = false;
      });

    }
  }

  isValidAddress() {
    if (this.formAddress[0]) { return this.formAddress[0].builder.valid; }
    return false;
  }


  selectCity(city) {
    this.state = city.state[0].initials;
    if (this.state) {
      this.formAddress[0].builder.patchValue({
        estado: this.state
      });
    }
  }
}
