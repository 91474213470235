import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root',
})
export class DonationService {
  constructor(private http: HttpService, private authService: AuthService) {}

  listDonationByVaquinha(id: any, { initAt = '', endAt = '' }): Promise<any> {
    return new Promise((resolve, reject) => {
      let link = `api/donation/listDonationByVaquinha/exec?vaquinhaRef=${id}`;

      if (initAt) link += `&initAt=${initAt}`;
      if (endAt) link += `&endAt=${endAt}`;

      this.http
        .get(link, true)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }
}
