import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
@Injectable({
  providedIn: 'root',
})
export class SubscriptionFeeService {
  constructor(private http: HttpService) {}

  getOne(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get('api/subscriptionFee/getOne/exec', true)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }
}
