import {
  Component,
  OnInit,
  Inject,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UtilsProvider } from 'src/commons/utils';
import { ENV } from 'src/environments/environment-variables.token';
import { ImageCropModalComponent } from 'src/app/image-crop-modal/image-crop-modal.component';

@Component({
  selector: 'app-img-vaquinha',
  templateUrl: './img-vaquinha.component.html',
  styleUrls: ['./img-vaquinha.component.scss'],
})
export class ImgVaquinhaComponent implements OnInit {
  constructor(
    public utils: UtilsProvider,
    public dialog: MatDialog,
    public formBuilder: FormBuilder,
    @Inject(ENV) public ENV: any
  ) {}

  @Input() data: String;
  @Input() edit: any;
  @Output() Event = new EventEmitter<{}>();
  public croppedImage: any;
  private fileToUpload: any = null;

  ngOnInit() {
    this.croppedImage = this.data;
    (this.fileToUpload = this.data), 'profilepic';
  }

  fileChangeEvent(event: any): void {
    const dialogRef = this.dialog.open(ImageCropModalComponent, {
      autoFocus: false,
      data: { event: event, aspect: 16 / 9 },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.croppedImage = result.image;
        (this.fileToUpload = result.image), 'profilepic';
        console.log('xxxd FILEEEEE: ', this.fileToUpload);
      }
    });
  }

  back() {
    this.Event.emit({
      back: true,
    });
  }
  next() {
    if (
      !this.fileToUpload ||
      this.fileToUpload == '../../assets/no-avatar.png'
    ) {
      return this.utils.toast({
        message: 'Selecione uma image',
        action: '❌',
      });
    }

    this.Event.emit({
      file: this.fileToUpload,
    });
  }
}
