import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
@Injectable({
  providedIn: 'root',
})
export class WithdrawService {
  constructor(private http: HttpService) {}

  listWithdraw(): Promise<any> {
    return new Promise((resolve, reject) => {
      const _user = JSON.parse(localStorage.getItem('user'));
      console.log('fsdfsdfsd', _user);
      let link = 'api/withdraw/list/exec';
      if (_user) link += `?userRef=${_user.user._id}`;

      this.http.get(link, true).then((res) => {
        resolve(res);
      });
    });
  }
}
