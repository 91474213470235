import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  OnDestroy,
  Inject,
  HostListener,
  Host,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ENV } from '../../environments/environment-variables.token';
import { DOCUMENT } from '@angular/common';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { FaleConoscoModalComponent } from '../fale-conosco-modal/fale-conosco-modal.component';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { HttpService } from 'src/providers/http.service';
import { UtilsProvider } from 'src/commons/utils';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'questions-home',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss'],
})
export class QuestionsComponent implements OnInit, OnDestroy {
  constructor(
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private router: Router,
    public formBuilder: FormBuilder,
    public http: HttpService,
    public utils: UtilsProvider,
    @Inject(ENV) public ENV: any,
    @Inject(DOCUMENT) private document: Document
  ) { }

  public isDisabled = false;
  public contatoForm: any;
  public contatoFormStep: any;
  public assuntos = [
    {
      title: 'Dificuldades Técnicas',
    },
    {
      title: 'Dúvidas Sobre a Maquininha',
    },
    {
      title: 'Financeiro',
    },
    {
      title: 'Parcerias',
    },
    {
      title: 'Indicações',
    },
  ];

  public headerHide: any;
  public showVideo = true;
  public candidatos = [
    {
      title: 'O que é financiamento coletivo eleitoral?',
      answer:
        'Essa modalidade, regulamentada pelo TSE, permite a arrecadação de recursos para o financiamento de campanhas eleitorais.',
    },
    {
      title: 'Qual o período de arrecadação?',
      answer:
        'A partir do dia 15 de maio de 2024 até o dia das eleições é permitido arrecadar via financiamento coletivo de campanha.',
    },
    {
      title: 'Eu posso me cadastrar?',
      answer:
        'Qualquer candidato ao cargo de Prefeito, Vice-Prefeito ou Vereador nas Eleições Municipais de 2024 pode se cadastrar na plataforma.',
    },
    {
      title: 'Isso é seguro? O TSE permite?',
      answer:
        'Arrecadamos e recebemos recursos de mais de 2 mil campanhas, a operação é homologada e autorizada pela Justiça Eleitoral.',
    },
    {
      title: 'Quando posso começar a arrecadar?',
      answer:
        'O período de arrecadação ocorre do dia 15 de maio de 2024 até o último dia útil antes da eleição.',
    },
    {
      title:'Quando posso sacar o valor arrecadado?',
      answer: 
      'Os valores arrecadados estarão disponíveis para saque na conta campanha a partir do dia 16 de agosto.',
    },
    {
      title: 'Como as pessoas podem me doar? Podem pagar com Pix? Cartão?',
      answer:
        'Após o cadastro, será gerada uma página de doações do candidato com um link exclusivo. A plataforma aceita doações via PIX, cartão de crédito e boleto bancário.',
    },
    {
      title:'Em quanto tempo o dinheiro é disponibilizado?',
      answer:
        'Doações em PIX são disponibilizadas no momento do processamento; boleto em até 72 horas após o processamento do sistema bancário; cartão de crédito até 30 dias.',
    },
    {
      title:'Quanto tempo a solicitação de saque é processada?',
      answer:
        'A solicitação do saque é processada em até 2 dias úteis.',
    },
    {
      title: 'Qual o diferencial do QueroApoiar.com.br?',
      answer:
      'Atuamos em mais de 2 mil campanhas, arrecadando mais de R$3.5 milhões. Nas 3 eleições que participamos, nunca recebemos reclamação no ReclameAqui nem na Justiça Eleitoral e Justiça Comum. Realizamos o pagamento de solicitação de saque em uma velocidade superior à concorrência e praticamos as melhores taxas do mercado junto ao melhor suporte de atendimento.',
    },
    {
      title:'Existe alguma taxa de adesão que deve ser paga no cadastro?',
      answer:
        'Para a eleição de 2024 estamos trabalhando com a taxa de inscrição, única, no valor de R$199,00 que será paga via PIX ou cartão de crédito.',
    },
    {
      title:'Quais são as taxas pagas nas transações?',
      answer:
        'A taxa é de 3,65%. Essa taxa é cobrada por transação e é fixa, independente do valor a ser doado.',
    },
    {
      title:'Existe taxa de saque?',
      answer:
        'O primeiro saque é gratuito, após a primeira operação nos cobramos R$5,00 por saque realizado.',
    },
    {
      title:'Qual o valor mínimo?',
      answer:
        'A plataforma é integrada ao sistema do TSE? A cada saque é gerado um arquivo FCC, que é o formato utilizado pela justiça eleitoral. Basta fazer o upload dele no site do TSE.',
    },
    {
      title:'Existe um painel para administrar as doações?',
      answer:
        'Toda doação recebida pode ser visualizada no painel do candidato.',
    },
    {
      title:'Eu consigo ver os dados dos doadores?',
      answer:
        'Pelo painel do candidato é possível visualizar os dados compartilhados do candidato, todo o processo segue a LGPD.',
    },
    {
      title:'Os meus doadores vão ter acesso a um recibo de doação?',
      answer:
        'Sim, seguindo regras do TSE, é emitido um recibo para o CPF do doador a cada doação.',
    },
    {
      title:'O QueroApoiar me oferece suporte?',
      answer:
        'Claro! Temos um time de suporte preparado para todas as dúvidas que surgirem ao longo do processo. Contem conosco!',
    },
  ];

  public doadores = [
    {
      title: 'Como localizo o candidato que desejo doar?',
      answer:
        '<div>Para descobrir a página do seu candidato acesse:&nbsp;<a href="https://www.queroapoiar.com.br/candidatos">queroapoiar.com.br/candidatos</a>, preencha o nome e faça a doação ;)</div>',
    },
    {
      title:'Qual a chave Pix do meu candidato?',
      answer: 
      'Atenção! A chave pix só será gerada após o preenchimento dos seus dados. Não faça pix para nenhum CNPJ!',
    },
    {
      title: 'O QueroApoiar só aceita doações pelo site?',
      answer:
        'Sim, a doação é feita direto pelo site, via PIX, boleto ou cartão de crédito. Primeiro, acesse a página do seu candidato, selecione o valor desejado. Preencha suas informações, selecione como forma de pagamento pix. Anote a chave pix aleatória ou escaneie o QR Code e faça o pagamento através do app da sua instituição financeira.',
    },
    {
      title: 'Minha empresa pode doar?',
      answer:
        'Não! Apenas doações de pessoa física são aceitas.',
    },
    {
      title: 'Preciso me registrar para doar?',
      answer:
        'Sim, é necessário se cadastrar em nosso site para realizar sua doação.',
    },
    {
      title: 'A minha doação ainda não apareceu na lista. O que eu faço?',
      answer:
        'Sua doação constará na lista em 72 horas úteis caso seja paga em boleto, e após alguns minutos caso seja realizado o pagamento em pix ou cartão de crédito. Passou desse prazo e ainda não apareceu? Chame a gente no Whatsapp ou email contato@queroapoiar.com.br',
    },
    {
      title: 'Quero cancelar uma doação. Como eu faço?',
      answer:
        '<div class="answer-lista">Para cancelar a doação, envie a solicitação para contato@queroapoiar.com.br através do email utilizado no momento da doação.<ul>No email deverá constar:<br><li>⁠Seu nome completo</li><li>⁠Seu CPF</li><li>⁠Nome do candidato</li><li>⁠Data e valor da doação</li><li>⁠Caso tenha doado por boleto, informe uma chave pix para reembolso</li><li>⁠Lembrando que para o cancelamento será cobrada a taxa de transação de 3,65% acrescida de uma taxa de operação no valor de R$5,00.</li><li>⁠A doação realizada por pix será estornada para sua conta bancária. Caso tenha feito por cartão de crédito, constará nos próximos dias em sua fatura.</li></ul></div>',
    },
    {
      title: 'Qual o limite para doação?',
      answer:
        'Para doações realizadas por cartão de crédito e boleto o limite máximo diário por CPF é R$1064,10. Para doações feitas em pix não existe limite máximo.',
    },
    {
      title: 'Preciso declarar no Imposto de Renda?',
      answer: 
      'Sim, sua doação deve ser declarada no seu próximo imposto de renda informando o CNPJ de campanha do candidato na ficha de “Doações a Partidos Políticos, Comitês Financeiros e Candidatos a cargos efetivos”. E não pode exceder 10% do rendimento anual.',
    },
    {
      title: 'Moro fora do Brasil, posso doar?',
      answer:
        'Pode doar, mas é preciso que a doação seja feita de uma instituição brasileira e que seu CPF seja informado. É proibida doação de estrangeiros.',
    },
    {
      title: 'Como posso fazer minha doação?',
      answer:
        'Você pode doar pelo nosso site via cartão de crédito, PIX ou se preferir por boleto bancário.',
    },
    {
      title: 'Que formas de pagamento são aceitas?',
      answer:
        'Você pode doar através de cartão de crédito, PIX ou se preferir por boleto bancário.',
    },
    {
      title: 'Meus dados serão públicos? Como apagar meu CPF?',
      answer:
        'Conforme o art. 22, II, da Resolução-TSE nº 23.607/2019 seus dados como: nome, CPF e valor doado, serão exibidos na lista de doações do candidato no site público.',
    },
    {
      title: 'Vou receber um recibo de doação?',
      answer:
        'Para obter o recibo de doação, acesse a página do candidato e clique no botão "Transparência". Após finalizar a doação, você recebe automaticamente também um recibo em seu email.',
    },
    // {
    //   title: 'Meus dados aparecerão no site do candidato como doador?',
    //   answer:
    //     'Sim, conforme o art. 22, II, da Resolução-TSE nº 23.607/2019 seus dados como: nome, CPF e valor doado, serão exibidos na lista de doações do candidato no site público.',
    // },
    // {
    //   title:'Fiz minha doação durante a pré-campanha, mas o candidato desistiu da candidatura ou não solicitou o registro de candidatura, o que acontece com o valor doado?',
    //   answer:
    //     'Na hipótese de o candidato não apresentar o seu pedido de registro de candidatura à Justiça Eleitoral, os recursos arrecadados serão ser devolvidos aos doadores, descontando as taxas.',
    // },
  ];

  public questions = [
    // {
    //   title: 'O QueroApoiar é homologado pelo TSE?',
    //   answer:
    //     'Sim. Somos umas das poucas empresas autorizadas pelo TSE a receber doações eleitorais.',
    // },
    // {
    //   title: 'Qual o período de arrecadação?',
    //   answer:
    //     'A partir do dia 15 de maio de 2022 até o dia das eleições é permitido arrecadar via financiamento coletivo de campanha.',
    // },
    // {
    //   title: 'O QueroApoiar possui sistema antifraude?',
    //   answer:
    //     'Sim. Todas as transações financeiras feitas por cartão de crédito possuem um rigoroso sistema antifraude para não colocar o seu apoio em risco.',
    // },
    // {
    //   title:
    //     'O QueroApoiar aceita doações via transferência bancária, DOC ou TED?',
    //   answer: 'Aceitamos PIX, cartão de crédito e boleto.',
    // },

    // {
    //   title: 'O QueroApoiar só aceita doações pelo site?',
    //   answer:
    //     'Sim, a doação é feita direto pelo site, via PIX, boleto ou cartão de crédito',
    // },
    // {
    //   title: 'Qual o diferencial da plataforma?',
    //   answer:
    //     'O Quero Apoiar tem as menores taxas, atendimento próximo, segurança, tecnologia de ponta, vários métodos de pagamento, página personalizada com link próprio e várias ferramentas para ajudar a divulgação do seu perfil.',
    // },
    // {
    //   title: 'O que preciso para aderir à plataforma?',
    //   answer:
    //     'Você precisa ter em mãos alguns dados do candidato: nome completo, CPF, RG e-mail, telefone e uma foto, nome que será usado na candidatura Tudo certo? Pode clicar aqui para realizar o cadastro.',
    // },
    // {
    //   title: 'Existe alguma taxa de adesão à plataforma?',
    //   answer:
    //     'Para a eleição de 2024 estamos trabalhando com a taxa de inscrição, única, no valor de R$199,00 que será paga via PIX ou cartão de crédito no início do período de arrecadação (15 de maio).',
    // },
    // {
    //   title: 'Quais taxas são cobradas e quais o valores?',
    //   answer:
    //     'Se a sua doação for feita no cartão de crédito a taxa é de 3,65% por transação, independente do valor da doação. Se optar por doar por boleto, a taxa é de 3,65%. Esse custo é fixo e é independente do valor e só é cobrada se o boleto for compensado.',
    // },
    // {
    //   title: 'Qual a taxa para doações feitas por cartão de crédito?',
    //   answer:
    //     'A taxa é de 3,65%. Essa taxa é cobrada por transação e é fixa, independente do valor a ser doado.',
    // },
    // {
    //   title: 'Qual a taxa para doações feitas por boleto bancário?',
    //   answer:
    //     'A taxa é de 3,65%. Essa taxa é fixa, independente do valor de doação. Lembrando que o valor mínimo para doação por boleto é R$60,00.',
    // },
    // {
    //   title:
    //     'É divulgado no site do candidato as taxas administrativas a serem cobradas pelo QueroApoiar?',
    //   answer: 'Sim, tudo é divulgado seguindo as normas do TSE.',
    // },
    // {
    //   title: 'O candidato terá suporte do QueroApoiar?',
    //   answer:
    //     'Claro! Temos um time de suporte preparado para todas as dúvidas que surgirem ao longo do processo.',
    // },
    // {
    //   title: 'Quem pode receber doação pelo QueroApoiar?',
    //   answer:
    //     'Até 15 de agosto de 2024: Candidatos aos cargos de Presidente, Governador, Senador, Deputado Federal, Deputado Estadual e Deputado Distrital de todos as regiões do Brasil. Após 15 de agosto de 2024 até o dia das eleições: Candidatos registrados junto ao TSE aos cargos de Presidente, Governador, Senador, Deputado Federal, Deputado Estadual e Deputado Distrital de todos as regiões do Brasil.',
    // },
    // {
    //   title: 'O QueroApoiar emite recibo de doação?',
    //   answer:
    //     'Sim, seguindo regras do TSE, é emitido um recibo para o CPF do doador em cada doação.',
    // },
    // {
    //   title:
    //     'Sou candidato e não tenho certeza se irei me candidatar. Como funciona a minha arrecadação?',
    //   answer:
    //     'Todo o valor arrecadado de qualquer candidato fica em posse do Quero Apoiar até que o candidato obtenha a conta de campanha e os dados jurídicos (CNPJ) ou se identificamos que o cadastro de candidatura não foi aprovado pelo TSE. Sendo assim todo o valor arrecadado é estornado para o doador, com a retenção da taxa da plataforma, sem danos para o candidato, nem para o doador.',
    // },
    // {
    //   title:
    //     'A arrecadação pode ser feita até o dia da eleição. Considerando que o valor estará disponível para transferência após a eleição, o QueroApoiar poderá depositar recursos na conta da campanha após as eleições?',
    //   answer:
    //     'De acordo com o art. 33 da Resolução-TSE nº 23.607/2019, os candidatos podem arrecadar recursos e contrair obrigações até o dia da eleição. A arrecadação via QueroApoiar poderá ser usada após a eleição somente para a quitação de despesas já contraídas e não pagas, essas deverão estar integralmente quitadas até o prazo de entrega da prestação de contas à Justiça Eleitoral.',
    // },
    // {
    //   title: 'O que preciso para ser apoiador?',
    //   answer: 'Simples, basta fazer a doação no site do Quero Apoiar.',
    // },
    // {
    //   title: 'É seguro doar?',
    //   answer:
    //     'Sim! O QueroApoiar é homologado pelo TSE e todas as transações além de passarem pelo antifraude, são criptografadas para que seus dados bancários e pessoais sejam preservados.',
    // },
    // {
    //   title: 'Como posso fazer minha doação?',
    //   answer:
    //     'Você pode doar pelo nosso site via cartão de crédito, PIX ou se preferir por boleto bancário.',
    // },
    // {
    //   title: 'Existe um valor mínimo e máximo para doação?',
    //   answer:
    //     'Você pode doar entre R$20,00 e R$1.064,10 através de financiamento coletivo, por dia, respeitando o limite de 10% (dez por cento) dos rendimentos brutos auferidos pelo doador no ano-calendário anterior à eleição (Lei nº 9.504/1997, art. 23, § 1º). Para cartão de crédito e pix, o mínimo permitido é R$20,00. Para boleto, o mínimo permitido é R$60,00.',
    // },
    // {
    //   title: 'É emitido um recibo de doação?',
    //   answer:
    //     'Sim, seguindo regras do TSE, é emitido um recibo para o CPF do doador a cada doação.',
    // },
    // {
    //   title: 'O doador deve declarar a doação?',
    //   answer:
    //     'Sim, sua doação deve ser declarada no seu próximo imposto de renda informando o CNPJ de campanha do candidato na ficha de “Doações a Partidos Políticos, Comitês Financeiros e Candidatos a cargos efetivos”.',
    // },
    // {
    //   title: 'Meus dados aparecerão no site do candidato como doador?',
    //   answer:
    //     'Sim, conforme o art. 22, II, da Resolução-TSE nº 23.607/2019 seus dados como: nome, CPF e valor doado, serão exibidos na lista de doações do candidato no site público.',
    // },
    // {
    //   title:
    //     'Fiz minha doação durante a pré-campanha, mas o candidato desistiu da candidatura ou não solicitou o registro de candidatura, o que acontece com o valor doado?',
    //   answer:
    //     'Na hipótese de o candidato não apresentar o seu pedido de registro de candidatura à Justiça Eleitoral, os recursos arrecadados serão ser devolvidos aos doadores, descontando as taxas.',
    // },
    // {
    //   title: 'Há taxa de saque?',
    //   answer:
    //     'O primeiro saque é gratuito, após a primeira operação nos cobramos R$5,00 por saque realizado.',
    // },
    // {
    //   title:
    //     'A  plataforma tem integração com o sistema de prestação de contas do STE?',
    //   answer:
    //     'A cada saque é gerado um arquivo FCC, que é o formato utilizado pela justiça eleitoral. Basta fazer o upload dele no site do TSE. ',
    // },
  ];

  public elements = ['cadastro', 'comofunciona', 'contato'];
  public currentEl = 0;
  private offsetTop = 0;

  @ViewChild('comofunciona', { static: true }) comofunciona: ElementRef;
  @ViewChild('contato', { static: true }) contato: ElementRef;
  @ViewChild('cadastro', { static: true }) cadastro: ElementRef;

  public adminHelpPage: any;
  private prev = 0;

  ngOnInit() {
    this.adminHelpPage = this.router.url.endsWith('ajuda');
    this.setNavbarStatus();
    window.scrollTo(0, 0);
    this.ENV.active = 'help';
    const email = new FormControl(
      '',
      Validators.compose([Validators.required, Validators.email])
    );
    const name = new FormControl('', Validators.compose([Validators.required]));
    const assunto = new FormControl(
      '',
      Validators.compose([Validators.required])
    );
    const message = new FormControl(
      '',
      Validators.compose([Validators.required])
    );
    this.contatoFormStep = 0;
    this.contatoForm = [
      {
        fields: [
          {
            name: 'name',
            type: 'text',
            placeholder: 'Nome Completo',
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            blurCallback() { },
          },
          {
            name: 'email',
            type: 'email',
            placeholder: 'E-mail',
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            blurCallback() { },
          },
          {
            name: 'assunto',
            type: 'select',
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            placeholder: 'Assunto',
            options: {
              fields: {
                value: 'title',
                text: 'title',
              },
              items: this.assuntos,
            },
            clickCallback: (data) => { },
          },
          {
            name: 'message',
            type: 'textarea',
            rows: 6,
            placeholder: 'Digite a sua mensagem',
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            blurCallback() { },
          },
        ],
        builder: this.formBuilder.group({
          name,
          email,
          assunto,
          message,
        }),
        validation_messages: {
          name: [{ type: 'required', message: 'Preencha o campo nome.' }],
          email: [{ type: 'required', message: 'Preencha o campo e-mail.' }],
          assunto: [{ type: 'required', message: 'Preencha o campo assunto.' }],
          message: [{ type: 'required', message: 'Digite uma mensagem.' }],
        },
      },
    ];
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }

  setNavbarStatus() {
    if (window.innerWidth > 700) this.ENV.open = true;
    else this.ENV.open = false;
  }

  scrollEvent(event) {
    this.offsetTop = event.target.scrollTop;
    // console.log("SCROLLED: ", event);
  }

  newScroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 6000,
    });
  }

  scroll(el: any, menu: boolean = false) {
    let element = el;
    const currentEl = String(this.ENV.CURRENT_ELEMENT);
    if (!menu && (!el || el === undefined) && this.ENV.CURRENT_ELEMENT) {
      const idx = this.elements.indexOf(currentEl);
      const nextEl =
        idx === this.elements.length - 1
          ? this.elements[0]
          : this.elements[idx + 1];
      element = nextEl;
    } else if (!this.ENV.CURRENT_ELEMENT) {
      element = this.elements[1];
    }
    setTimeout(() => {
      if (this[element]) {
        this[element].nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'start',
        });
      }
    }, 0);
  }
  @HostListener('window:scroll', ['$event'])
  onScroll(ev) {
    const scrollPosition = window.pageYOffset;
    if (scrollPosition >= this.prev) {
      this.headerHide = true;
      this.prev = scrollPosition;
    } else {
      this.headerHide = false;
      this.prev = scrollPosition;
    }
  }

  changeContent(event) {
    const offsetLeft = 0;
    let offsetTop = 0;

    let el = event.srcElement;

    while (el) {
      el = el.parentElement;
      offsetTop += el.offsetTop;
    }
    console.log(offsetTop);

    if (offsetTop <= 700) {
    }
  }

  goToUrl(): void {
    this.document.location.href = '/questions';
  }
  openDialog() {
    const dialogRef = this.dialog.open(FaleConoscoModalComponent, {
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }
  goHome() {
    this.router.navigate(['home']);
  }

  clean() {
    this.contatoForm[0].builder.reset();
  }

  performClick(el: MatButton) {
    el._elementRef.nativeElement.click();
  }

  async onSubmit(event) {
    this.isDisabled = true;
    if (!this.contatoForm[0].builder.valid) {
      this.isDisabled = false;
      return this.utils.toast({
        message: 'Por favor, preencha corretamente o formulário.',
        action: 'Ok',
        duration: 10000,
      });
    }
    const data = { ...event[0].builder.value };

    try {
      await this.http.post('api/faleConosco/sendContactEmail', false, data);
      this.utils.toast({
        message:
          'Mensagem enviada com sucesso! Entraremos em contato em breve.',
        action: 'Ok',
        duration: 10000,
      });
      //this.contatoForm[0].builder.reset();
      this.isDisabled = false;
    } catch (err) {
      console.log(err);
      this.utils.toast({
        message:
          'Não foi possível enviar sua mensagem, por favor verifique seus dados ou tente novamente mais tarde.',
        action: 'Ok',
        duration: 10000,
      });
      this.isDisabled = false;
    }
  }
}
