import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpService, private authService: AuthService) {}

  getPermissions(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/users/getPermissions', false, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  getRoles(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get('api/roles/getAllSite', false)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  profileAvatar(data) {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/users/profileAvatar', true, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  createOrUpdate(data: any): any {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/users/create', false, data)
        .then((res) => {
          resolve();
        })
        .catch((err) => {
          // console.log("erroorrre", err.error.message);
          throw err;
        });
    });
  }

  editProfile(data: any): any {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/user/editProfile/editProfile', true, data)
        .then((res) => {
          resolve();
        })
        .catch((err) => {
          throw err;
        });
    });
  }

  updateCPF(data: any): any {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/users/updateCPF', true, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          throw err;
        });
    });
  }

  checkSubscriptionIsPaid(): Promise<{ isPaid: boolean }> {
    return new Promise((resolve, reject) => {
      this.http
        .get('api/subscribeV2/checkPaid/exec', true)
        .then((res) => {
          console.log('esponse of verification if is paid -----', res);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  checkPaymentExists(): Promise<{ isPaid: boolean }> {
    return new Promise((resolve, reject) => {
      this.http
        .get('api/subscribeV2/checkPaymentExists/exec', true)
        .then((res) => {
          console.log('esponse of verification if is paid -----', res);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  // checkPaid(): Promise<any> {
  //     return new Promise((resolve, reject) => {
  //         this.http.get('api/users/checkPaid', true).then(res => {
  //             resolve(res);
  //         })
  //         .catch(err => reject(err));
  //     });
  // }
}
