<div class="donations-component">
    <div class="">
        <h1 class="title">Lista de doações</h1>
        <div class="donations-law">
            <p class="little">Conforme a resolução do TSE 23.553, Art. 23 . II – identificação obrigatória, com o nome
                completo e o número de inscrição no cadastro de pessoas físicas (CPF) de cada um dos doadores, o valor
                das quantias doadas individualmente, forma de pagamento e as datas das respectivas doações. Precisamos
                colocar todos esses dados já que sem o CPF poderiam haver homônimos. Empresas de financiamento coletivo
                que não respeitarem esta norma terão problemas com a justiça eleitoral.</p>
        </div>
        <div class="buttonCenter">
            <button (click)="search(2018)" class="purple">2018</button>
            <button (click)="search(2020)" class="purple">2020</button>
            <button (click)="search(2022)" class="purple">2022</button>
        </div>
        <div *ngIf="donationsList.length == 0" class="fraseCenter">
            <p> Nenhuma doação dísponivel deste ano :( </p>
        </div>
        <div *ngIf="donationsList.length >= 1" class=" donations-table-options col-md-12 col-sm-12 col-sm-12">
            <table class="donations-table">
                <tr>
                    <th>{{ tableLabels.name }}</th>
                    <th>{{ tableLabels.cpf }}</th>
                    <th>{{ tableLabels.date }}</th>
                    <th>{{ tableLabels.donationMethod}}</th>
                    <th>{{ tableLabels.value }}</th>
                    <th></th>
                </tr>
                <tr *ngFor="let i of donationsList">
                    <td>{{ getName(i) }}</td>
                    <td>{{ i.donatorInfo.Identity }}</td>
                    <td>{{ i.updatedAt | date :"dd/MM/yyyy - HH:mm"}}</td>
                    <th>{{ i.type}}</th>
                    <td>R${{ i.donationValue}},00</td>
                    <!-- <td class="link" (click)="showDonate(i)">Ver Mais</td> -->
                </tr>
            </table>
        </div>
        <div (click)="expand()"
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 expand">
            <span class="input-group-addon material-icons">keyboard_arrow_down</span>
        </div>
    </div>
</div>