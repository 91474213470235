<div class="row center-lg center-md top-lg top-md">

    <div class="d-component col-xs-12 col-sm-12 col-md-11 col-lg-11">


        <mat-card>
            <div class="dcontainer row">
                <div class="values col-md-12 col-lg-12">
                    <div class="row full">
                          <!-- <img class="icon" src="../../assets/porquinho.png" /> -->
                          <p class="line-height text-b2 col-md-9">
                              Listagem de saques
                          </p>
                        <div class="col-md-3">
                        </div>
                    </div>
                </div>
                <div class=" donations-table-options col-md-12 col-sm-12 col-sm-12">
                    <table mat-table [dataSource]="dataSource" class="donations-table">

                            <ng-container matColumnDef="dateRequest">
                              <th mat-header-cell *matHeaderCellDef> Data de requisição </th>
                              <td  mat-cell *matCellDef="let element">
                                <div class="display">
                                 {{element.dateRequest | date :"dd/MM/yyyy HH:mm"}} <br>
                                </div>
                              </td>
                             </ng-container>
                             <ng-container matColumnDef="status">
                              <th mat-header-cell *matHeaderCellDef> Status </th>
                              <td mat-cell *matCellDef="let element">
                                  <div class="pendente" *ngIf="element.status == 'pendente'">Aguardando Pagamento</div>
                                  <div class="pendente" *ngIf="element.status == 'processamento'">Pendente</div>
                                  <div class="sucesso" *ngIf="element.status == 'autorizado'">Autorizado</div>
                                  <div class="sucesso" *ngIf="element.status == 'aprovado'">Aprovado</div>
                                  <div class="recusado" *ngIf="element.status == 'recusado'">Recusado</div>
                                  <div class="recusado" *ngIf="element.status == 'rejeitado'">Rejeitado</div>
                                  <div class="pendente" *ngIf="element.status == 'disponivel'">Disponível</div>
                                  <div class="pendente" *ngIf="element.status == 'disputa'">Em Disputa</div>
                                  <div class="pendente" *ngIf="element.status == 'devolvido'">Devolvido</div>
                                  <div class="recusado" *ngIf="element.status == 'baixado'">Expirado</div>
                                  <div class="recusado" *ngIf="element.status == 'cancelamento'">Em Cancelamento</div>
                                  <div class="pendente" *ngIf="element.status == 'chargeback'">Chargeback</div>
                                  <div class="pendente" *ngIf="element.status == 'liberado'">Liberado</div>
                              </td>
                          </ng-container>

                          <ng-container matColumnDef="liquid">
                              <th mat-header-cell *matHeaderCellDef> Tipo </th>
                              <td mat-cell *matCellDef="let element"> {{element.valor}} </td>
                          </ng-container>
                          <ng-container matColumnDef="bruto">
                              <th mat-header-cell *matHeaderCellDef> Início </th>
                              <td mat-cell *matCellDef="let element">
                                  {{element.valor}}
                              </td>
                          </ng-container>
                          <ng-container matColumnDef="tax">
                            <th mat-header-cell *matHeaderCellDef> Término </th>
                            <td mat-cell *matCellDef="let element">
                                3.75
                            </td>
                          </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <mat-paginator [pageSize]="10"></mat-paginator>
                </div>
            </div>

        </mat-card>
    </div>

</div>
