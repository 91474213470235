import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'image-crop-modal',
  templateUrl: './image-crop-modal.component.html',
  styleUrls: ['../app.component.scss', './image-crop-modal.component.scss']
})
export class ImageCropModalComponent implements OnInit {
  public title: string;
  public content: string;
  public event: any;
  public croppedImage: any;
  public aspect: any;
  public redWidth: any;
  public redHeight: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialogRef: MatDialogRef<ImageCropModalComponent>) {
    this.event = data.event;
    this.aspect = data.aspect;
    if (this.aspect === 1) {
      this.redHeight = 400;
      this.redWidth = 400;
    } else {
      this.redHeight = 900;
      this.redWidth = 1600;
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  close() {
    this.matDialogRef.close({ image: this.croppedImage});
  }

  exit() {
    this.matDialogRef.close();
  }

  ngOnInit() {
    // this.title = localStorage.getItem("err-title") || "pagina não encontrada!";
    // this.content = localStorage.getItem("err-content") || "verifique a url e tente novamente.";
  }

}
