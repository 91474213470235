import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { CandidateService } from 'src/providers/candidate.service';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { Title, Meta } from '@angular/platform-browser';
import { UrnaService } from '../../providers/urna.service';
import { UtilsProvider } from 'src/commons/utils';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

@Component({
	selector: 'app-candidate',
	templateUrl: './candidate-example.component.html',
	styleUrls: ['./candidate-example.component.scss']
})
export class CandidateExComponent implements OnInit {
	title: any;
	faFacebook = faFacebook;
	faTwitter = faTwitter;
	faYoutube = faYoutube;
	faInstagram = faInstagram;
	faWhatsapp = faWhatsapp;
	anotherValue = false;
	fotoCandidato: any;
	candidatos: any;
	id = this.activatedRoute.snapshot.paramMap.get('entity');
	donations = [];
	userDataBios: any;
	public imageUrl = '../../assets/no-avatar.png';
	public userDataSocialMedia: any;
	public donateValue: any;
	userMedias = {};
	public userNotFound = false;
	public flags = [];
	public candidateName: any;

	constructor(
		private httpClient: HttpClient,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private candidateService: CandidateService,
		private titleService: Title,
		private metaTagService: Meta,
		private urnaServie: UrnaService,
		private utils: UtilsProvider
	) { }

	ngOnInit() {
		/* this.candidateService.getProfiles({all: true}).then(res => { */
		window.scrollTo(0,0)
	}


	getDonationsList() {
		this.router.navigate(['/doacoes'], {
			queryParams: {}
		});
	}

	public copyToClipboard = () => {
		const url = 'https://www.queroapoiar.com.br/exemplo';
		document.addEventListener('copy', (e: ClipboardEvent) => {
			e.clipboardData.setData('text/plain', url);
			e.preventDefault();
			// document.removeEventListener('copy', );
		});
		document.execCommand('copy');
		return this.utils.toast({ message: 'URL copiada com sucesso!', action: 'Ok!' });
	}

	public cadastrar = () => {
		this.router.navigate(['/cadastrar'])
	}
}
