<div style="width: 100%; overflow: hidden; ">
<mat-toolbar class="menu" [class.hide]="headerHide">
    <!-- <div class="header-title">
        <a [routerLink]="['/']"><img src="../../assets/logo/logo_full.png"/></a>
    </div>
    <div class="menu-container">
        <button [routerLink]="'/'" fragment="produtos">Produtos</button>
        <button (click)="onClick('produtos')">Produtos</button>
        <button [routerLink]="['/maquininha']" *ngIf="!notHome">Maquininha</button>
        <button  [routerLink]="'/'" fragment="depoimentos">Depoimentos</button>
        <button  (click)="onClick('depoimentos')">Depoimentos</button>
        <button  [routerLink]="'/maquininha'" fragment="duvidas" *ngIf="notHome">Dúvidas?</button>
        <button mat-button [matMenuTriggerFor]="contact">Contatos</button>
        <button class="menu-button" *ngIf="!this.ENV.USER" [routerLink]="['/login']">Acessar</button>
        <button class="menu-button" *ngIf="this.ENV.USER" (click)="logout()">Sair</button>
        <mat-menu #contact="matMenu" class="teste">
            <button mat-menu-item>
                <span class="material-icons">phone_in_talk</span> (11) 91111-1111
            </button>
            <button mat-menu-item [routerLink]="['/contato']">
                <span class="material-icons">email</span> contato@teste.com
            </button>
        </mat-menu>
    </div> -->
    <nav class="navBar">
        <div class="alignLogo">
            <div class="row middle-xs">
                <div class="col-xs-3 col-sm-4 col-md-4 col-lg-4">
                    <div class="row middle-xs">
                        <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7">
                            <a [routerLink]="'/'"><img class="img-responsive logo" src="../../assets/logo/logo_mini.svg"></a>
                        </div>
                    </div>
                </div>
                <div class="col-xs-9 col-sm-8 col-md-8 col-lg-8">
                    <div class="menu-interno">
                        <ul>
                            <!-- <li><a [routerLink]="'/'">Início</a></li>
                            <li><a [routerLink]="'/candidatos'">Candidatos 2022</a></li> -->
                            <!-- <li><a [routerLink]="''">Home</a></li> -->
                            <!-- <li><a [routerLink]="'/dadosAnteriores'">Dados Anteriores</a></li> -->
                            <li><a [routerLink]="'/candidatos'">Candidatos</a></li>
                            <li><a [routerLink]="'/duvidas'">Ajuda</a></li>
                            <button mat-flat-button *ngIf="!this.ENV.logged" [routerLink]="'/cadastrar'" >Cadastrar</button>
                            <button mat-flat-button *ngIf="!this.ENV.logged" [routerLink]="'/entrar'" >Acessar</button>
                            <button mat-flat-button [matMenuTriggerFor]="profile" *ngIf="this.ENV.logged">Minha Conta</button>
                            <mat-menu #profile="matMenu" class="teste">
                                <button mat-menu-item [routerLink]="'/admin'">
                                    <!-- span class="material-icons">phone_in_talk</span --> Painel Administrativo
                                </button>
                                <!-- button mat-menu-item [routerLink]="'/perfil'">
                                    <span class="material-icons">phone_in_talk</span> Editar Perfil
                                </button>
                                <button *ngIf="isCandidate()" mat-menu-item (click)="doacoes()">
                                    <span class="material-icons">phone_in_talk</span> Minhas Doações
                                </button -->
                               <!--  <button *ngIf="isCandidate()" mat-menu-item (click)="visualizar()">
                                    span class="material-icons">phone_in_talk</span>  Ver Minha Página
                                </button>-->
                                <button mat-menu-item (click)="logout()">
                                    <!-- span class="material-icons">email</span--> Sair
                                </button>
                            </mat-menu>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </nav>

</mat-toolbar>
