import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-maquininha',
  templateUrl: './maquininha.component.html',
  styleUrls: ['./maquininha.component.scss']
})
export class MaquininhaComponent implements OnInit {

  public questions = []

  constructor() { }

  ngOnInit(): void {
    window.scrollTo(0,0)
    this.questions = [
      {
        title: "Eu preciso ter dinheiro para doar?",
        answer: "Sim!"
      },
      {
        title: "Quais os meios de pagamento?",
        answer: "Cartão de crédito, boleto e amor ;)"
      },
      {
        title: "Se eu posso pagar com amor, então eu não preciso de dinheiro?",
        answer: "..."
      }
    ]
  }

}
