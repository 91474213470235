import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class DomainProvider {

	constructor(
		private http: HttpService,
		private httpClient: HttpClient
	) { }

	searchCities(data: any) {
		return new Promise((resolve, reject) => {
			// this.httpService.unaltorizeRequest("post", "api/urna/validateAlias", {name: value}).then((result: any) => {
			this.http.post( 'api/domain/searchCities', false, data).then((res: any) => {
				resolve(res);
			});
		}).catch(err => console.log('Error trying to get cities: ', err));
	}
	getCEP(cepValue) {
		return new Promise((resolve, reject) => {
			this.httpClient.get('https://viacep.com.br/ws/' + cepValue + '/json/')
			.subscribe(data => {
				resolve(data);
			});
		}).catch(err => console.log('Error trying to create schedule: ', err));

	}

}
