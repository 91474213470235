import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root',
})
export class VaquinhaService {
  constructor(private http: HttpService, private authService: AuthService) {}

  getList(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get('api/vaquinha/list/exec', true)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  getType(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get('api/vaquinha/getType/exec', true)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  edit(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/vaquinha/edit/exec', true, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  create(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/vaquinha/create/exec', true, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  getURL(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(`api/vaquinha/checkURL/exec?url=${data}`, true)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }
  getElectionVaquinha({
    year = new Date().getFullYear(),
    params = '',
    skip = 0,
    limit = 8,
    url = '',
  }): Promise<any> {
    return new Promise((resolve, reject) => {
      let link = `api/vaquinha/getElectionVaquinha/exec?year=${year}&_skip=${skip}&_limit=${limit}`;
      if (params) link += `&params=${params}`;
      if (url) link += `&url=${url}`;
      this.http
        .get(link, false)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }
  getElectionVaquinhaList({
    year = new Date().getFullYear(),
    params = '',
    skip = 0,
    limit = 8,
    url = '',
    alreadySearch = [],
    changeModelSearch = false,
    restOfSkip,
  }): Promise<any> {
    return new Promise((resolve, reject) => {
      let link = `api/vaquinha/getElectionVaquinha/list?year=${year}&_skip=${skip}&_limit=${limit}&changeModelSearch=${changeModelSearch}`;
      if (params) link += `&params=${params}`;
      if (url) link += `&url=${url}`;
      if (restOfSkip) link += `&restOfSkip=${restOfSkip}`;
      if (alreadySearch && alreadySearch.length != 0)
        link += `&alreadySearch=${alreadySearch}`;
      this.http
        .get(link, false)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  getOldVaquinha(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(`api/pastData/oldVaquinhas/getOldVaquinhas`, false, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  getVaquinha({ skip = 0, limit = 8, url = '' }): Promise<any> {
    return new Promise((resolve, reject) => {
      let link = `api/vaquinha/getStandardVaquinha/exec?_skip=${skip}&_limit=${limit}`;
      if (url) link += `&url=${url}`;

      this.http
        .get(link, false)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  getTotalVaquinha(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(`api/vaquinha/getTotalVaquinha/exec`, false)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }
}
