<div>
  <div class="card">
    <div class="container">
      <input [hidden]="true" type="file" #fileInput
        accept=".gif, .jpg, .png, .pdf, .html, .jpeg, .doc, .docx, .bmp, .dib, .txt" onclick="this.value = null"
        (change)="fileChangeEvent($event)">
        <input [hidden]="true" type="file" #fileInputCnpj
        accept=".gif, .jpg, .png, .pdf, .html, .jpeg, .doc, .docx, .bmp, .dib, .txt" onclick="this.value = null"
        (change)="fileChangeEventCnpj($event)">
      <div class="header">
        <img class="logo" src="../../assets/logo/logo.svg" />
        <h3> Informe os dados da sua conta bancária onde será depositado o valor: </h3>
      </div>
      <div class="form">
        <div class="error" *ngIf="error">
          <p>O valor solicitado é inválido ou ultrapassa seu limite disponível para saque, por favor insira um valor
            válido.</p>
        </div>
        <default-form (keyup.enter)="performClick(register)" [forms]="formAddress" [step]="0"></default-form>
        <default-form  [forms]="cnpj" [step]="0"></default-form>
        <!-- mat-checkbox (keyup.enter)="performClick(register)" class="example-margin" [(ngModel)]="terms"><p>Eu concordo com os <a> termos de uso da maquininha.</a></p></mat-checkbox -->
        <div class="buttons">
          <label>Envie os documentos abaixo:</label><br>
          <button mat-flat-button #register class="btn2 outline" [ngClass]="{ inactive: cartaoCnpj || account }" (change)="fileChangeEvent($event)" (click)="sendFile(true)" *ngIf="checkTypeField == 'CNPJ'">Anexar Cartão CNPJ </button>
          <button mat-flat-button #register class="btn2 outline" [ngClass]="{ inactive: docIdentidade || account }" (change)="fileChangeEventCnpj($event)" (click)="sendFile(false)">Anexar Documento de
            Identificação</button>
        </div>
        <h4 class="title-form"> Pix (opcional) </h4>
        <span [ngStyle]="{'margin-bottom': '1rem' }">Verique se a chave do pix está digitada corretamente.</span>
        <default-form (keyup.enter)="performClick(register)" [forms]="formPix" [step]="0"></default-form>
        <h3 class="title-form">Endereço </h3>
        <default-form (keyup.enter)="performClick(register)" [forms]="address" [step]="0"></default-form>
      </div>
    </div>
  </div>
  <div class="btn-container">
    <button mat-flat-button class="btn1" (click)="close()">Cancelar</button>
    <button mat-flat-button #register class="btn2" (click)="onSubmit()">Salvar Dados</button>
  </div>
</div>
