import { ModalTermDonationComponent } from './modal-term-donation.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, } from "@angular/core";
import { HttpService } from "src/providers/http.service";
import { CommonModule } from "@angular/common";
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [CommonModule, MatCardModule, MatInputModule, MatDialogModule, MatButtonModule],
  declarations: [ModalTermDonationComponent],
  providers: [HttpService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})

export class ModalTermDonationModule { }
