import { Component, OnInit } from "@angular/core";
import { Validators, FormBuilder, FormControl } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "src/providers/auth.service";
import { RxwebValidators } from "@rxweb/reactive-form-validators";
import { MatDialog } from "@angular/material/dialog"
import { ModalDefaultComponent } from '../modal-default/modal-default.component';
import { MatButton } from '@angular/material/button';
@Component({
	selector: "app-forget-password",
	templateUrl: "./forget-password.component.html",
	styleUrls: ["./forget-password.component.scss", "../app.component.scss"]
})
export class ForgetPasswordComponent implements OnInit {

	public forgotPasswordForm: any;
	public forgotPasswordFormStep: any;
	public isDisabled = false;
	constructor(
		public router: Router,
		public formBuilder: FormBuilder,
		public auth: AuthService,
		public dialog: MatDialog
	) { }

	ngOnInit() {
		window.scrollTo(0,0)
		const email = new FormControl("", Validators.compose([Validators.required, Validators.email]));
		const confirmEmail = new FormControl("", Validators.compose([
            Validators.required,
            Validators.email,
			RxwebValidators.compare({ fieldName: "email" }),
		]));
		this.forgotPasswordFormStep = 0;
		this.forgotPasswordForm = [
			{
				fields: [
					{
						name: "email",
						type: "email",
						placeholder: "E-mail",
						blurCallback() { }
					},
					{
						name: "confirmEmail",
						type: "email",
						placeholder: "Confirme seu E-mail",
						blurCallback() { }
					}
				],
				builder: this.formBuilder.group({
          email,
          confirmEmail
				}),
				validation_messages: {
					email: [
						{ type: "required", message: "Preencha o campo e-mail." },
					],
					confirmEmail: [
						{ type: "required", message: "Preencha o campo confirme seu e-mail." },
						{ type: "compare", message: "Os campos devem ter o mesmo valor." },
					],
				},
			},
		];
	}

	clean() {
		this.forgotPasswordForm[0].builder.reset();
	}

	performClick(el: MatButton) {
        el._elementRef.nativeElement.click()
        console.log(this.isDisabled)
    }

	onSubmit(event) {
		if (!this.forgotPasswordForm[0].builder.valid) {
            // this.utils.showMessagem("Erro", "Preencha corretamente o formulário");
            console.log("a")
			return;
		}
        const data = { ...event[0].builder.value };
        console.log("b")
		console.log(data)
		
		this.isDisabled = true;
		this.auth.forgotPassword(data).then((res) => {
			this.isDisabled = false
            this.clean();
            console.log("aaa" + res)
			if (res !== "") { this.router.navigate(["/entrar"]) } else { console.log("(this should be a toast) invalid user"); }
		}).catch(err => {
			this.isDisabled = false
		});
	}

	checkDisabled() {
		return this.isDisabled
	}

	openDialog() {
		const dialogRef = this.dialog.open(ModalDefaultComponent, { data: { message: "aaaa"}});
		dialogRef.afterClosed().subscribe((res) => {
			this.router.navigate(["/login"])
		})
	}
}
