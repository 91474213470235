import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/providers/auth.service';
import { UtilsProvider } from 'src/commons/utils';
import { ENV } from 'src/environments/environment-variables.token';
import { UserService } from 'src/providers/user.service';
import { HttpService } from 'src/providers/http.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CandidateService } from 'src/providers/candidate.service';
import { ModalDefaultComponent } from '../modal-default/modal-default.component';
import { PaymentService } from '../../providers/payments.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SaqueModalComponent } from '../saque-modal/saque-modal.component';
import { TransferModalComponent } from '../transfer-modal/transfer-modal.component';
import { AdminComponent } from '../admin/admin.component';
import { AccountService } from 'src/providers/account.service.';
import * as moment from 'moment';
import { DonationService } from 'src/providers/donation.service';

@Component({
  selector: 'app-withdraw-vaquinha',
  templateUrl: './withdraw-vaquinha.component.html',
  styleUrls: ['./withdraw-vaquinha.component.scss'],
})
export class WithdrawVaquinhaComponent implements OnInit {
  public profileForm: any;
  public Editor = ClassicEditor;
  public profileData = '';
  displayedColumns: string[] = [
    'icon',
    'paymentCode',
    'donatorInfo',
    'paymentInfo',
    'status',
    'updatedAt',
    'donationValue',
    'recibo',
  ];

  name: string;
  cpf: number;
  date: string;
  donationMethod: string;
  value: string;
  candidato: any;
  saques: any;
  private total: any;
  private tax: any;
  public available = 0;
  public donationsList: any;
  public totalValue = 0;
  public availableValue: any;
  public liquidValue = 0;
  public diffValue = 0;
  public taxValue = 0;
  public grossValue = 0;
  public user: any;
  public vaquinha: any;
  public infoDonation: any;
  public donationsId: any = [];

  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public dataSource;

  // public isCandidate: any;
  constructor(
    public formBuilder: FormBuilder,
    public userService: UserService,
    public dialog: MatDialog,
    private httpService: HttpService,
    public authProvider: AuthService,
    @Inject(ENV) public ENV: any,
    public utils: UtilsProvider,
    private router: Router,
    private accountProvider: AccountService,
    private donationProvider: DonationService,
    private candidateProvider: CandidateService,
    private paymentsProvider: PaymentService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
    console.log(localStorage.getItem('user'));
    if (!localStorage.getItem('user')) {
      this.router.navigate(['/entrar']);
    }
    this.route.queryParams.subscribe((params) => {
      this.vaquinha = params;
    });
    const _user = JSON.parse(localStorage.getItem('user'));
    // console.log('USER EMNU: ', _user);
    console.log('routerr', this.router.url.split('/')[3]);  
    // this.paymentsProvider
    //   .getAllHandling({ user: _user.user._id, withdraw: true })
    //   .then(async (res) => {
    //     this.saques = res;
    //   })
    //   .catch((e) => {
    //     this.utils.toast({
    //       message: 'Não foi possível buscar informações do candidato',
    //     });
    //   });

    // const _user = JSON.parse(localStorage.getItem('user'))
    this.user = _user.user;
    this.ENV.active = 'donations';
    const self = this;
    const data_inicial = new FormControl('', Validators.compose([]));
    const data_final = new FormControl('', Validators.compose([]));

    this.profileForm = [
      {
        fields: [
          {
            name: 'data_inicial',
            label: 'Data Inicial',
            placeholder: 'DD/MM/AAAA',
            type: 'date',
            cols: 'col-lg-5 col-md-5 col-sm-6 col-xs-12',
            blurCallback: () => {},
          },
          {
            name: 'data_final',
            label: 'Data Final',
            placeholder: 'DD/MM/AAAA',
            type: 'date',
            cols: 'col-lg-5 col-md-5 col-sm-6 col-xs-12',
            blurCallback: () => {},
          },
        ],
        builder: this.formBuilder.group({
          data_inicial,
          data_final,
        }),
        validation_message: {
          data_inicial: [],
          data_final: [],
        },
      },
    ];
    this.getUserInfo();
    this.getSaque();
  }

  getUserInfo() {
    const _user = JSON.parse(localStorage.getItem('user'));
    // const donationsId = [];
    console.log("user", _user);

    this.donationProvider
      .listDonationByVaquinha(this.router.url.split('/')[3].split('?')[0], {})
      .then(async (res) => {
        this.donationsList = res;
        this.dataSource = new MatTableDataSource(this.donationsList);
        this.dataSource.paginator = this.paginator;
        
        this.donationsList.map((item) => {
          const value = {
            _id: item._id,
          }

          this.donationsId.push(value);
        })

        if(this.donationsId.length > 0) {
          const [errValue, value] = await this.utils.tryCatch(
            this.candidateProvider.getAvailableDonationsV2({
              vaquinhaRef: this.router.url.split('/')[3].split('?')[0]
            })
          );
            if (errValue) {
              console.log('Não foi possível resgatar os valores das doações');
            }
            
            this.infoDonation = value;
          }

          this.total = this.infoDonation.response.totalValue;
            this.available = this.infoDonation.response.availableValue;
            const diff = this.infoDonation.response.antecipationTotal;
            /* this.tax = ((3 / 100) * value.response.grossDiff); */
            this.tax = this.infoDonation.response.grossDiff;
            const gross = this.infoDonation.response.grossValue;
    
            this.grossValue = gross > 0 ? gross : 0;
            this.taxValue = this.tax > 0 ? this.tax : 0;
    
            this.totalValue = this.total > 0 ? this.total : 0;
            this.diffValue = diff > 0 ? diff : 0;
            this.availableValue = this.available > 0 ? this.available : 0;
            this.liquidValue = this.infoDonation.response.liquidValue > 0 ? this.infoDonation.response.liquidValue : 0;

      });

    // this.paymentsProvider
    //   .getAllDonations({ user: _user.user._id })
    //   .then(async (res) => {
    //     console.log(res);
    //     this.donationsList = res;
    //     this.dataSource = new MatTableDataSource(this.donationsList);
    //     this.dataSource.paginator = this.paginator;

    //     const [errValue, value] = await this.utils.tryCatch(
    //       this.candidateProvider.getAvailableDonations({ user: _user.user._id })
    //     );
    //     if (errValue) {
    //       console.log('Não foi possível resgatar os valores das doações');
    //     }

    //     console.log(value);
    //     this.total = value.data.totalValue;
    //     this.available = value.data.availableValue;
    //     const diff = value.data.antecipationTotal;
    //     /* this.tax = ((3 / 100) * value.data.grossDiff); */
    //     this.tax = value.data.grossDiff;
    //     const gross = value.data.grossValue;

    //     this.grossValue = gross > 0 ? gross : 0;
    //     this.taxValue = this.tax > 0 ? this.tax : 0;

    //     this.totalValue = this.total > 0 ? this.total : 0;
    //     this.diffValue = diff > 0 ? diff : 0;
    //     this.availableValue = this.available > 0 ? this.available : 0;
    //     this.liquidValue =
    //       value.data.liquidValue > 0 ? value.data.liquidValue : 0;
    //   })
    //   .catch((e) => {
    //     this.utils.toast({
    //       message: 'Não foi possível buscar informações do candidato',
    //     });
    //   });
  }

  getSaque() {
    this.paymentsProvider.getAllHandlingV2(
        {
          vaquinhaRef: this.router.url.split('/')[3].split('?')[0], 
          withdraw: true 
        }
      )
      .then(async (res) => {
        this.saques = res;
      })
      .catch((e) => {
        this.utils.toast({
          message: 'Não foi possível buscar informações do candidato',
        });
      });
  }

  isCandidate() {
    const _user = JSON.parse(localStorage.getItem('user'));
    if (
      _user.user.role &&
      _user.user.role.descricao &&
      String(_user.user.role.descricao) === 'candidato'
    ) {
      return true;
    }
    return false;
  }

  async openDialog(antecipate?: any) {
    const _user = JSON.parse(localStorage.getItem('user'));
    const accounts = await this.accountProvider.getAccountV2({
      vaquinhaRef: this.router.url.split('/')[3].split('?')[0],
    });

    if (antecipate) {
      const confirmDialog = this.dialog.open(ModalDefaultComponent, {
        data: {
          title: 'Deseja mesmo antecipar o valor total?',
          message:
            '<p>Caso você opte por antecipar o valor total das doações, <br> uma taxa será aplicada sobre o valor bruto ainda não liberado. <br>Tem certeza que deseja continuar?</p>',
        },
      });
        confirmDialog.afterClosed().subscribe((result) => {
          if (result === 'false') {
            return;
          } else if (!accounts || accounts == null) {
            const dialogRef = this.dialog.open(SaqueModalComponent, {
              data: {
                available: this.available > 0 ? this.available : 0,
                vaquinhaRef: this.router.url.split('/')[3].split('?')[0],
              },
            });
            dialogRef.afterClosed().subscribe((result) => {
              console.log('RESULT: ', result);
              const dialogRef = this.dialog.open(TransferModalComponent, {
                data: {
                  vaquinhaRef: this.router.url.split('/')[3].split('?')[0],
                  userRef: _user.user._id,
                  antecipado: Number(this.total), 
                  isAntecipado: true,
                  email: _user.user.email,
                  account: result.success.transfer,
                },
              });
              dialogRef.afterClosed().subscribe((result) => {});
            });
          } else {
            const dialogRef = this.dialog.open(TransferModalComponent, {
              data: {
                vaquinhaRef: this.router.url.split('/')[3].split('?')[0],
                userRef: _user.user._id,
                antecipado: Number(this.total), 
                isAntecipado: true,
                email: _user.user.email,
                account: accounts 
              },
            });
            dialogRef.afterClosed().subscribe((result) => {});
          }
        });
      } 
      else {
        if (!accounts || accounts == null) {
          const dialogRef = this.dialog.open(SaqueModalComponent, {
            data: { 
              available: this.available > 0 ? this.available : 0,
              vaquinhaRef: this.router.url.split('/')[3].split('?')[0],
            },
          });
          dialogRef.afterClosed().subscribe((result) => {
            console.log('RESULT: ', result);
            const dialogRef = this.dialog.open(TransferModalComponent, {
              data: {
                antecipado: this.total - this.tax,
                account: result.success.transfer,
              },
            });
            dialogRef.afterClosed().subscribe((result) => {});
          });
        } 
        else {
          const dialogRef = this.dialog.open(TransferModalComponent, {
            data: {
                vaquinhaRef: this.router.url.split('/')[3].split('?')[0],
                userRef: _user.user._id,
                isAntecipado: false,
                email: _user.user.email,
                available: this.available > 0 ? this.available : 0,
                account: accounts,
            },
          });
          dialogRef.afterClosed().subscribe((result) => {});
        }
    // } else {
    //   const dialogRef = this.dialog.open(ModalDefaultComponent, {
    //     data: {
    //       title: 'Função desabilitada',
    //       message:
    //         '<p>Saques só poderão ser realizados a partir do dia 16/08/2022 conforme resolução do TSE</p>',
    //     },
    //   });
    //   dialogRef.afterClosed().subscribe((result) => {});
    }
  
}

  public openModal() {
    const dialogRef = this.dialog.open(SaqueModalComponent, {
      data: {
        available: this.available > 0 ? this.available : 0,
        vaquinhaRef: this.router.url.split('/')[3].split('?')[0],
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  public showDonate(donate: any) {
    console.log(donate);

    let formaPagamento;
    if (donate.type === 'credito') {
      formaPagamento = 'Crédito';
    } else if (donate.type === 'debito') {
      formaPagamento = 'Débito';
    } else if (donate.type === 'boleto') {
      formaPagamento = 'Boleto';
    }

    this.accountProvider
      .getAccount({ user: donate.userRef._id })
      .then((account) => {
        const candidate = donate.userRef;
        const params = {
          candidateEmail: candidate.email,
          userEmail: donate.donatorInfo.Email,
          candidateCpf: candidate.cpf,
          candidateCnpj: account ? account.cnpj : undefined,
          userCpf: donate.donatorInfo.Identity,
          partido: candidate.partido,
          cargo: candidate.cargo,
          candidateName: candidate.name,
          userName: donate.donatorInfo.nomecompleto
            ? donate.donatorInfo.nomecompleto
            : donate.donatorInfo.Name,
          value: 'R$' + String(donate.donationValue).replace('.', ','),
          date: donate.updatedAt,
          pagamento: formaPagamento,
          //error: donate.paymentInfo.ResponseDetail.Description
        };

        const date =
          new Date(params.date).toLocaleDateString() +
          ' - ' +
          new Date(params.date).toLocaleTimeString();
        let template;
        if (params.candidateName) {
          template = `<p><b>Candidato(a):</b> ${params.candidateName}</p>`;
        }
        if (params.candidateCpf) {
          template += `<p><b>CPF do Candidato:</b> ${params.candidateCpf}</p>`;
        }
        if (params.candidateCnpj) {
          template += `<p><b>CNPJ do Candidato:</b> ${params.candidateCnpj}</p>`;
        }
        if (params.partido) {
          template += `<p><b>Partido:</b> ${params.partido}</p>`;
        }
        if (params.cargo) {
          template += `<p><b>Cargo:</b> ${params.cargo}</p>`;
        }
        if (params.userName) {
          template += `<p><b>Nome do Doador:</b> ${params.userName}</p>`;
        }
        if (params.userCpf) {
          template += `<p><b>CPF do Doador:</b> ${params.userCpf}</p>`;
        }
        if (params.date) {
          template += `<p><b>Data:</b> ${date}</p>`;
        }
        if (params.value) {
          template += `<p><b>Valor:</b> ${params.value}</p>`;
        }
        if (params.pagamento) {
          template += `<p><b>Forma de Pagamento:</b> ${params.pagamento}</p>`;
        }

        const dialogRef = this.dialog.open(ModalDefaultComponent, {
          autoFocus: false,
          data: {
            title: 'Dados do Recibo',
            message: template,
          },
        });

        dialogRef.afterClosed().subscribe((result) => {});
      });
  }

  exportFCC() {
    const _user = JSON.parse(localStorage.getItem('user'));
    const _id = _user.user._id;
    this.paymentsProvider.exportPayments({ user: _id });
  }

  export() {
    var list = [];
    this.donationsList.forEach((element) => {
      const liquidValue =
        element.donationValue -
        (element.liquidValue * element.donationValue) / 100;
      console.log('routerr', liquidValue);
      list.push({
        'Realizado em': moment(element.createdAt).format('DD/MM/YYYY - HH:mm'),
        'Nome Doador': element.donatorInfo.nomecompleto
          ? element.donatorInfo.nomecompleto
          : element.donatorInfo.Name,
        'Telefone Doador': element.donatorInfo.Phone,
        'Email Doador': element.donatorInfo.Email,
        'CPF Doador': element.donatorInfo.Identity,
        Valor: element.donationValue,
        'Valor Líquido': liquidValue.toFixed(2),
        Forma: element.type,
        Status: element.status,
        'Atualizado em': moment(element.updatedAt).format('DD/MM/YYYY - HH:mm'),
      });
    });
    // window.open(this.ENV.API_URL+"/imports/exportXLS/?grupo=" + this.group + "&begin=" + moment(this.begin).startOf("day") + "&end=" + moment(this.end).endOf("day"));
    this.utils.exportAsExcelFile(
      list,
      'doacoes-quero-apoiar-' + String(moment().format('YYYY-MM-DD'))
    );
  }

  getName(donate: any) {
    const name = String(donate.userName).toLowerCase().split(' ');
    return String(donate.userName).toLowerCase();
  }

  getValue(donate: any) {
    return String(donate.value).replace('.', ',');
  }

  async onSubmit() {
    // const _user = JSON.parse(localStorage.getItem('user'));
    // const _id = _user.user._id;

    const dInicial = String(
      this.profileForm[0].builder.controls.data_inicial.value
    );
    // const inicial = dInicial[1] + '/' + dInicial[0] + '/' + dInicial[2];

    const dFinal = String(
      this.profileForm[0].builder.controls.data_final.value
    );
    // const final = dFinal[1] + '/' + dFinal[0] + '/' + dFinal[2];
    // const dataInicial = new Date(inicial);
    // const dataFinal = new Date(final);

    // console.log(dataInicial);
    // console.log(dataFinal);
    // this.paymentsProvider
    //   .getAllDonations({
    //     skip: 0,
    //     limit: 10000000,
    //     user: _id,
    //     dataInicial,
    //     dataFinal,
    //   })
    //   .then((res: any) => {
    //     this.donationsList = res;
    //     this.dataSource = new MatTableDataSource(this.donationsList);
    //     this.dataSource.paginator = this.paginator;
    //     console.log(this.donationsList);
    //   })
    //   .catch((e) => {
    //     this.utils.toast({ message: 'Erro ao filtrar resultados.' });
    //   });
    const query = {
      initAt: '',
      endAt: '',
    };

    if (dFinal) {
      query.endAt = dFinal;
    }
    if (dInicial) {
      query.initAt = dInicial;
    }
    console.log('router query', query);
    this.donationProvider
      .listDonationByVaquinha(this.router.url.split('/')[3], { ...query })
      .then((res) => {
        this.donationsList = res;
        this.dataSource = new MatTableDataSource(this.donationsList);
        this.dataSource.paginator = this.paginator;
        console.log('routerr res', res);
      });
  }
}
