
<div class="card-container">
    <div class="card">
        <mat-card class="container-cd">
            <div class="form">
                <img class="logo" src="../../assets/logo/logo.svg"/>
                <h2>Seja bem-vindo :)</h2>
                <default-form (keyup.enter)="performClick(login)" [forms]="loginForm" [step]="loginFormStep"></default-form>
                
                <div class="row">
                    <div class="col-lg-4"></div>
                </div>
                <div class="btn-container">
                    <button [disabled]="isDisabled" mat-flat-button #login class="btn confirm-button" (click)="onSubmit(loginForm)">entrar</button>
                    <h3><a [routerLink]="['/cadastrar']">Ainda não tem conta? Cadastre-se</a></h3>
                    <h3><a [routerLink]="['/esqueci-a-senha']">Esqueci Minha Senha</a></h3>
                    <h3><a [routerLink]="['/esqueci-o-email']">Esqueci Meu E-mail</a></h3>
                </div>
            </div>
        </mat-card>
    </div>
</div>