import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-guideline',
  templateUrl: './guideline.component.html',
  styleUrls: ['./guideline.component.scss']
})
export class GuidelineComponent implements OnInit {
  public testeForm: any;
  public testeFormStep: any; 
  constructor(
    public formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    const email = new FormControl("", Validators.compose([Validators.required, Validators.email]));
    const password = new FormControl("", Validators.compose([Validators.required]));
    this.testeFormStep = 0;

    this.testeForm = [
      {
          fields: [
              {
                  name: "email",
                  type: "email",
                  cols: "col-md-3",
                  placeholder: "E-mail",
                  blurCallback() { }
              },
              {
                  name: "password",
                  type: "password",
                  cols: "col-md-3",
                  placeholder: "Senha",
                  blurCallback() { }
              },
          ],
          builder: this.formBuilder.group({
              email: [
                  "",
                  Validators.compose([
                      Validators.required,
                      Validators.email
                  ]),
              ],
              password: [
                  "",
                  Validators.compose([
                      Validators.required,
                  ]),
              ]
          }),
          validation_messages: {
              email: [
                  { type: "required", message: "Preencha o seu e-mail." },
              ],
              password: [
                  { type: "required", message: "Preencha sua senha." },
              ],
          },
      },
  ];
  }

}
