import { Component, OnInit, Inject, Input } from '@angular/core';
import { ENV } from 'src/environments/environment-variables.token';
import { MainVaquinhaComponent } from '../../modal-vaquinha/main-vaquinha/main-vaquinha.component';

@Component({
  selector: 'app-view-vaquinha',
  templateUrl: './view-vaquinha.component.html',
  styleUrls: ['./view-vaquinha.component.scss'],
})
export class ViewVaquinhaComponent implements OnInit {
  constructor(@Inject(ENV) public ENV: any) {
    // this.init();
  }

  public select: any = 'vaquinha';
  public selectData: any = [
    { html: 'Dados da vaquinha', value: 'vaquinha' },
    { html: 'Dados Bancários', value: 'account' },
  ];

  @Input() data: any = {};

  ngOnInit() {
    if (this.data.typeRef.name != 'Normal') {
      this.selectData.push({ html: 'Perfil do candidato', value: 'candidate' });

      if (
        this.data.account.hasOwnProperty('cpf') &&
        this.data.account.tipoPessoa == 'Física'
      ) {
        console.log('execute this line for me 1', this.data.account);
      }
    }
  }

  selectView(type) {
    this.select = type;
  }
}
