<div class="home-content">
    <mat-drawer-container class="example-container" autosize>
        <mat-drawer #drawer class="sidenav" mode="side" opened="{{ENV.open}}">
            <mat-nav-list>
                <mat-list-item [routerLink]="['./inicio']" [ngClass]="{'is-active': ENV.active == 'home'}"
                    (click)="setActive('home')">
                    <i class="fas fa-home"></i>
                    Início</mat-list-item>
                <mat-divider></mat-divider>
                <!-- <mat-list-item [routerLink]="['./painel-doacoes']" [ngClass]="{'is-active': ENV.active == 'donations'}"
                    (click)="setActive('donations')"> -->
                    <!-- <img class="icon" *ngIf="ENV.active != 'donations'"
                    src="../../assets/porquinho4.png">
                    <img class="icon2" *ngIf="ENV.active == 'donations'"
                    src="../../assets/porquinho5.png"> -->
                    <!-- <i class="fas fa-file-invoice-dollar"></i>
                    Extrato financeiro</mat-list-item> -->
                <mat-list-item [routerLink]="['./painel-saque']" [ngClass]="{'is-active': ENV.active == 'withdraw'}"
                    (click)="setActive('withdraw')">
                    <!-- <img class="icon" *ngIf="ENV.active != 'donations'"
                    src="../../assets/porquinho4.png">
                    <img class="icon2" *ngIf="ENV.active == 'donations'"
                    src="../../assets/porquinho5.png"> -->
                    <i class="fas fa-file-invoice-dollar"></i>
                    Listagem de saques</mat-list-item>
                <mat-list-item [routerLink]="['./minhas-vaquinhas']" [ngClass]="{'is-active': ENV.active == 'vaquinhas'}"
                    (click)="setActive('vaquinhas')">
                    <i class="fa fa-solid fa-piggy-bank"></i>
                    Minhas Vaquinhas
                </mat-list-item>

                <mat-list-item [routerLink]="['./comprovantes']" [ngClass]="{'is-active': ENV.active == 'comprovante'}"
                    (click)="setActive('comprovante')">
                    <!-- <img class="icon" *ngIf="ENV.active != 'donations'"
                        src="../../assets/porquinho4.png">
                        <img class="icon2" *ngIf="ENV.active == 'donations'"
                        src="../../assets/porquinho5.png"> -->
                    <i class="fas fa-receipt"></i>
                    Comprovantes TSE </mat-list-item>
                <!-- <mat-list-item  [ngClass]="{'is-active': ENV.active === 'list'}" (click)="setActive('list')">
                    <i class="fas fa-address-card"></i>
                    Lista de Doadores</mat-list-item> -->
                <mat-divider></mat-divider>
                <mat-list-item [routerLink]="['./perfil']" [ngClass]="{'is-active': ENV.active === 'profile'}"
                    (click)="setActive('profile')">
                    <i class="fas fa-edit"></i>
                    Seu Perfil
                </mat-list-item>
                <mat-list-item [routerLink]="['./ajuda']" [ngClass]="{'is-active': ENV.active === 'help'}"
                    (click)="setActive('help')">
                    <i class="fas fa-info"></i>
                    Ajuda</mat-list-item>
                <mat-list-item (click)="logout()" href="#" [ngClass]="{'is-active': ENV.active === 'exit'}"
                    (click)="setActive('exit')">
                    <i class="fas fa-sign-out-alt"></i>
                    Sair</mat-list-item>
            </mat-nav-list>
        </mat-drawer>
        <mat-drawer-content>
            <div class="content" fxFlex="50" wmAnimate="landing" speed="slow" aos>
                <router-outlet></router-outlet>
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</div>
