import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
@Injectable({
  providedIn: 'root',
})
export class CandidateService {
  constructor(private http: HttpService) {}

  getProfiles(data: object): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/candidate-profile/getProfiles', true, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  getCandidatos(data: object): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get('api/users/getCandidatos', false)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  getCandidatosAdmin(data: object): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/users/getCandidatosAdmin', false, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  getCandidatosNew(data: object): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/users/getCandidatosNew', false, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  getCandidatosAdmin2020(data: object): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/users/getCandidatosAdmin2020', false, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  getTopDonations(data: object): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/users/getTopDonations', false, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  createOrUpdate(data: object): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/candidate-profile/createOrUpdate', true, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  uploadImage(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/candidate-profile/uploadImage', true, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  getAll(data: object): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post('api/candidate-profile/getAll', true, data).then((res) => {
        resolve(res);
      });
    });
  }

  getDonations(data: object): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/candidate-profile/getDonations', false, data)
        .then((res) => {
          resolve(res);
        });
    });
  }

  getOldDonations(data: object): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/pastData/getOldDonations/getOldDonations', false, data)
        .then((res) => {
          resolve(res);
        });
    });
  }

  getDonationsV2(data: object): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/donation/getDonationsVaq/getDonations', false, data)
        .then((res) => {
          resolve(res);
        });
    });
  }

  getDonationsByUser(data: object): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/candidate-profile/getDonationsByUser', false, data)
        .then((res) => {
          resolve(res);
        });
    });
  }

  getDonationsValue(data: object): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/candidate-profile/getDonationsValue', false, data)
        .then((res) => {
          resolve(res);
        });
    });
  }

  getAvailableDonations(data: object): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/candidate-profile/getAvailableDonations', true, data)
        .then((res) => {
          resolve(res);
        });
    });
  }

  getAvailableDonationsV2(data: object): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/candidate-profile/getAvailableDonationsV2', true, data)
        .then((res) => {
          resolve(res);
        });
    });
  }

  findOneV2(id: String): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(`api/candidateProfile/findOne/exec?vaquinhaRef=${id}`, true)
        .then((res) => {
          resolve(res);
        });
    });
  }
}
