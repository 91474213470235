import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
@Injectable({
  providedIn: 'root',
})
export class CouponService {
  constructor(private http: HttpService) {}

  exits(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/couponCode/exist/exec', true, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }
}
