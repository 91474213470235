<div class="container">
    <div class="row">
        <mat-card class="col-sm-12 col-md-12">
            <div class="align-center">	
                <div class="card-body">
                    <div>
                        <img class="img" [src]="imageUrl">
                    </div>
                    <br>   
                    <input [hidden]="true" type="file" #fileInput accept="image/*" (change)="onFileSelected($event.target.files)">
                    <button mat-flat-button class="btn2" (click)="fileInput.click()" >Alterar Foto</button>
                    <p>Tamanho max da imagem: 3mb</p>
                </div>
            </div>
            <div>	
                <div class="align-center text-b2">Redes Sociais</div>
                <div class="card-body">
                    <default-form [forms]="socialMediaForm" [step]="0"></default-form>
                    <br>
                </div>
                <div class="col-sm-12 col-md-12">
                    <span>Sua Biografia</span>
                    <ckeditor [editor]="Editor" [(ngModel)]="model.bios"></ckeditor>
                    <br>
                </div>
                <div class="col-sm-12 col-md-12">
                    <span>Mensagem de Agradecimento (Após doação)</span>
                    <ckeditor [editor]="Editor" [(ngModel)]="model.message"></ckeditor>
                    <br>
                </div>
            </div>
            <div class="btn-container">
                <button [disabled]="validData()" type="submit" class="default-btn next-btn" (click)="saveProfile()">Salvar</button>
            </div>
            <div class="btn-container">
                <button  type="submit" class="default-btn next-btn" (click)="visualizar()">Salvar</button>
            </div>
        </mat-card>
    </div>
</div>