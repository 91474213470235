<div class="row center-lg center-md">
    <div class=" padding col-xs-12 col-sm-12 col-md-11 col-lg-11">
        <mat-card>
            <div class="row">
                <div class=" padding col-xs-12 col-sm-12 col-md-11 col-lg-11">
                    <div class="subheader">
                        <!-- <img class="icon" src="../../assets/porquinho.png" /> -->
                        <h2 class="line-height text-b2">
                            Editar Dados
                        </h2>
                    </div>
                    <default-form [forms]="profileForm" [step]="0"></default-form>
                    <br>
                </div>
                <div *ngIf="isCandidate()" class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
                </div>
                <div class="row full">
                    <div class="col-xs-12 col-sm-12 col-md-offset-3 col-md-6 col-lg-offset-3 col-lg-6">
                        <button mat-flat-button class="default-btn next-btn green full"
                            (click)="onSubmit(profileForm)">Salvar</button>
                        <!-- <button *ngIf="isCandidate()" mat-flat-button class="default-btn next-btn purple full"
                            (click)="visualizar()">Ir para Minha Página</button> -->
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
</div>