import {
  Component,
  OnInit,
  Inject,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { UtilsProvider } from 'src/commons/utils';
import { ENV } from 'src/environments/environment-variables.token';
import { VaquinhaService } from 'src/providers/vaquinha.service';

@Component({
  selector: 'app-type-vaquinha',
  templateUrl: './type-vaquinha.component.html',
  styleUrls: ['./type-vaquinha.component.scss'],
})
export class TypeVaquinhaComponent implements OnInit {
  constructor(
    public utils: UtilsProvider,
    public formBuilder: FormBuilder,
    public vaquinhaProvider: VaquinhaService,
    @Inject(ENV) public ENV: any
  ) {}

  ngOnInit(): void {
    this.typeVaquinhaForm();
  }

  @Input() data: any;
  @Output() Event = new EventEmitter<{}>();
  public formTypeVaquinha: any;
  public selectedType: Object;
  public standardType: Object;

  public type: any = [];

  next() {
    if (!this.selectedType) {
      this.utils.toast({
        message: 'Selecione o tipo de vaquinha',
        action: '❌',
      });
    }
    this.Event.emit(this.selectedType);
  }

  async typeVaquinhaForm() {
    const type = new FormControl('', Validators.compose([Validators.required]));

    const [err, getType] = await this.utils.tryCatch(
      this.vaquinhaProvider.getType()
    );

    if (err) return console.log('getType() error,', err);

    getType.map((item) => {
      const value = {
        value: item._id,
        text: item.name,
        hidden: item.name == 'Eleitoral' ? false : true,
        _id: item._id,
        checked: this.data.text == item.name ? true : false,
      };
      this.type.push(value);
      if (item.name == 'Normal') {
        this.standardType = value;
        this.selectedType = value;
      }
      if (this.data.text == item.name) {
        this.selectedType = value;
      }
    });

    this.formTypeVaquinha = [
      {
        fields: [
          {
            name: 'type',
            type: 'checkbox',
            horizontal: true,
            // label:
            //   'Está vaquinha tem propósito Eleitoral? Se sim, marque eleitoral',
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            options: {
              fields: {
                value: 'value',
                text: 'text',
              },
              items: this.type,
            },
            clickCallback: (data, event) => {
              // self.terms = !self.terms;

              this.type.forEach((val) => {
                if (val.text == data.text) val.checked = !val.checked;
                else {
                  val.checked = false;
                }
              });

              if (event.checked && data.checked) {
                this.selectedType = data;
              } else {
                this.selectedType = this.standardType;
              }
            },
          },
        ],
        builder: this.formBuilder.group({
          type,
        }),
        validation_messages: {
          type: [
            { type: 'required', message: 'Selecione o tipo de vaquinha.' },
          ],
        },
      },
    ];
  }
}
