import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
@Injectable({
  providedIn: 'root',
})
export class TransferService {
  constructor(private http: HttpService) {}

  createTransfer(data: object): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/transfers/createTransfer', true, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  createTransferV2(data: object): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/transfersV2/create/createTranfersV2', true, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  updateLog(data: object): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/transfers/updateLog', true, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  getReceipts(data: object): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/transfers/getReceipts', true, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  getReceiptsV2(data: object): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/transfersV2/getReceipts/exec', true, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }
}
