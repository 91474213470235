<div class="row-header">
    <div class="banner1 flex-end">
        <div class="alignment">
            <img class="logoqa" src="../../assets/image/logo.png" />
            <h1 class="header-text-1">As Eleições 2024<br>começaram!</h1>
            <h2 class="header-text-2">Receba doações!</h2>
            <mat-button class="confirm-button-1" [routerLink]="'/cadastrar'">CRIAR CONTA</mat-button>
            <img class="img2" src="../../assets/image/img1.png" />
        </div>
    </div>
    <div class="banner2 flex-start">
        <img class="img1" src="../../assets/image/img1.png" />
    </div>
</div>
<div class="row-2">
    <div class="box-1 row-maquininha">
        <img class="img-maquininha" src="../../assets/image/img2.png" />
    </div>
    <div class="box-2 row-3">
        <div class="maq-text">Arrecade e torne sua<br>eleição uma realidade</div>
        <div class="p1">QueroApoiar, seu apoio começa aqui!</div>
    </div>
</div>
<div class="tax-pos">
    <div class="tax-box">
        <div class="tax-box-1">
            <div class="mini-tax-text">
                <div class="mini-tax-text-align">
                    <div class="mini-tax-text-1">As</div>
                    <div class="mini-tax-text-2">melhores taxas</div>
                </div>
                <div class="mini-tax-text-3">do Brasil</div>
            </div>
            <div class="tax-text">
                As <span class="span-tax-text">melhores taxas</span> do Brasil
            </div>
            <div class="tax-text-2">
                <p class="p3">Apenas 3,65% por transação!<br>Sem pegadinhas e sem complicação.</p>
            </div>
            <div class="text-align">
                <h4 class="h4">As</h4>
                <h5 class="h5">melhores</h5>
            </div>
            <div class="text-align-2">
                <h6 class="h6">taxas</h6>
                <div class="h7">do Brasil</div>
            </div>
            <p class="p2">Apenas 3,65% por transação!<br>Sem pegadinhas e sem<br>complicação.</p>
        </div>
        <div class="tax-box-2">
            <img class="tax-img" src="../../assets/image/img3.png" />
        </div>
    </div>
</div>
<div class="double-box-pos">
    <div class="double-box-1">
        <div class="saque-box">
            <div class="coin-pos">
                <img class="img5-1" src="../../assets/image/img5.png" />
                <img class="img5-2" src="../../assets/image/img5.png" />
                <img class="img5-3" src="../../assets/image/img5.png" />
            </div>
            <div class="saque-text">
                <div class="saque-text-1">Saque</div>
                <div class="saque-text-2">em até</div>
            </div>
            <img class="img4" src="../../assets/image/img4.png" />
        </div>
    </div>
    <div class="double-box-2">
        <div class="pix-box">
            <div class="pix-text">Receba<br>e saque<br>com PIX</div>
            <img class="img6" src="../../assets/image/img6.png" />
        </div>
    </div>
</div>
<div class="candidato-box-pos">
    <div class="candidato-text">Campeões de arrecadação</div>
    <div class="row">
        <div *ngFor="let i of candidatoDestaque" class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
            <mat-card class="image-card">
                <div class="avatar">
                    <div class="avatar-img center-cropped" (click)="navigate(i.id)"
                        style="background-image: url({{ i.background }});">
                    </div>
                </div>
                <div class="text page-person">
                    <h1 class="click capitalize" (click)="navigate(i)">{{ i.urna}}</h1>
                    <p class="detalhes-candidato"><b>{{ i.partido }}</b></p><br>
                    <p class="detalhes-candidato"><b>{{ i.cargo }}</b></p>
                </div>
                <mat-card-actions>
                    <button class="redirect-button" mat-flat-button (click)="getDonationsList(i.id)"><img class="icon"
                            src="../../assets/porquinho2.png">Ver Detalhes</button>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>
</div>
<div class="footer">
    <div class="footer-column">
        <img class="img7" src="../../assets/image/img7.png" />
        <div class="footer-text">Crie sua conta e<br>comece a arrecadar!</div>
        <mat-button class="confirm-button-2" [routerLink]="'/cadastrar'">CRIAR CONTA</mat-button>
    </div>
</div>