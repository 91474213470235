<div>
  <div class="card">
    <div class="container">
      <div class="header">
        <img class="logo" src="../../assets/logo/logo.svg" />
        <h3> Confirme os dados da transação: </h3>
      </div>
      <div class="form">
        <div class="error" *ngIf="error">
          <p>O valor solicitado é inválido ou ultrapassa seu limite disponível para saque, por favor insira um valor
            válido.</p>
        </div>
        <default-form (keyup.enter)="performClick(register)" [forms]="formAddress" [step]="0"></default-form>
        <p class="little">Cada vaquinha terá seu primeiro saque isento de taxas adicionais sobre o valor líquido total. Após o primeiro saque, será aplicada uma <strong>taxa de R$5,00</strong> sobre cada transferência.</p>
      </div>
    </div>
  </div>
  <div class="btn-container">
    <button mat-flat-button class="btn1" (click)="close()">Cancelar</button>
    <button mat-flat-button #register class="btn2" (click)="onSubmit()">Enviar Solicitação</button>
  </div>
</div>
