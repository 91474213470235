import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(private http: HttpService) {}

  createAccount(data: object): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/account/createAccount', true, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  createAccountV2(data: object): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/account/create/createAccountV2', true, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  getAccount(data: object): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/account/getAccount', true, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  getAccountV2(data: object): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/account/getAccount/getAccountV2', true, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  updateAccount(data: object): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/account/updateAccount', true, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  updateAccountV2(data: object): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/account/edit/updateAccountV2', true, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }
  uploadCartaoCNPJ(data: object): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/account/uploadCartaoCNPJ', true, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }
  uploadDocumentoIdentificacao(data: object): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/account/uploadDocumentoIdentificacao', true, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  findOneV2(id: String): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(`api/account/findOne/exec?vaquinhaRef=${id}`, true)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  editV2(data: Object): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(`api/account/edit/updateAccountV2`, true, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }
}
