import { Component, OnInit, Inject, OnDestroy, HostListener } from "@angular/core";
// import { MatMenuComponent } from "@angular/material/menu"
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { DOCUMENT, ViewportScroller } from '@angular/common';
import { ENV } from "../../environments/environment-variables.token";
import { ScrollService } from 'src/providers/scroll.service';
import { UrnaService } from 'src/providers/urna.service';

@Component({
    selector: "default-header",
    templateUrl: "default-header.html",
    styleUrls: ["./default-header.scss"],
    providers: [],
})

export class DefaultHeaderComponent implements OnDestroy {

    private prev = 0;
    public headerHide: any;
    public fragment: any;
    public href: string = "";
    mySubscription: any;
    notHome: any;
    constructor(
        @Inject(DOCUMENT) private document: Document,
        public route: ActivatedRoute,
        private viewportScroller: ViewportScroller,
        public router: Router,
        private scrollService: ScrollService,
        private urna: UrnaService,
        @Inject(ENV) public ENV: any
    ) {
        console.log("load header")
        if (localStorage.getItem("user")) {
            this.ENV.logged = true
        }

        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };
        this.mySubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.href = location.pathname;
                console.log("url", this.href);
                if (this.href === '/maquininha') {
                    console.log("other pages")
                    this.notHome = true
                } else {
                    this.notHome = false
                    console.log("home")
                }
            }
        });

    }

    ngOnDestroy() {
        console.log("teste header")
        this.route.fragment.subscribe(fragment => { this.fragment = fragment })
        if (this.mySubscription) {
            this.mySubscription.unsubscribe();
        }
    }

    isCandidate() {
        if (this.ENV.logged) {
            const _user = JSON.parse(localStorage.getItem('user'));
            if (_user && _user.user && _user.user.role && _user.user.role.descricao && String(_user.user.role.descricao) === 'candidato') { return true; }
            return false;
        }
    }

    visualizar() {
        if (localStorage.getItem('urna')) {
            let _urna = localStorage.getItem('urna')
            return this.router.navigate(['/' + _urna]);
        }

        let _user = JSON.parse(localStorage.getItem('user'))
        let userRef = _user.user._id
        this.urna.getOne({ userRef }).then(res2 => {
            if (res2 !== undefined && res2.urna[0]) {
                localStorage.setItem('urna', res2.urna[0].alias);
                this.router.navigate(['/' + res2.urna[0].alias]);
            } else {
                this.router.navigate(['/perfil']);
            }
        }).catch(err => {
            this.router.navigate(['/perfil']);
        })
    }

    doacoes() {
        this.router.navigate(['/painel-doacoes']);
    }

    @HostListener("window:scroll", ["$event"])
    onScroll(ev) {
        const scrollPosition = window.pageYOffset;
        if (scrollPosition >= this.prev) {
            this.headerHide = true;
            this.prev = scrollPosition;
        } else {
            this.headerHide = false;
            this.prev = scrollPosition;
        }
    }

    ngAfterViewChecked(): void {
        try {
            document.querySelector('#' + this.fragment).scrollIntoView();
        } catch (e) { }
    }

    onClick(elementId: string): void {
        if (this.router.url == '/') this.scrollService.changeNav(elementId);
        else this.router.navigateByUrl('/').then(() => {
            setTimeout(() => {
                this.scrollService.changeNav(elementId);
            }, 500);
        });
    }

    logout() {
        this.ENV.logged = false
        localStorage.clear();
        this.router.navigate(["/"]);
    }
}