<div class="row center-lg center-md top-lg top-md">


  <div class="donations-component col-xs-12 col-sm-12 col-md-11 col-lg-11">
      <h1 style="margin-top: 1rem;"> Vaquinha: {{ vaquinha.title  }}</h1>
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <mat-card class="image-card">
                    <div>
                        <div class="saque">
                            <p>Dinheiro disponível para saque imediato</p>
                            <div class="value">

                                <h1>{{ availableValue | currency : "R$"}}</h1>
                                <button *ngIf="availableValue > 0" (click)="openDialog()" mat-flat-button
                                    class="btn1">RETIRAR VALOR</button>
                            </div>
                        </div>
                        <mat-divider class="margin"></mat-divider>
                        <div class="total">
                            <br><br>
                            <p>Antecipar <b>valor total</b></p>
                            <div class="value">
                                <h1>{{ totalValue  | currency : "R$"}}</h1>
                                <button *ngIf="diffValue > 0" (click)="openDialog(true)" mat-flat-button
                                    class="btn2">ANTECIPAR VALOR</button>
                            </div>
                            <p>+ Disponível: {{ availableValue  | currency : "R$"}}</p>
                            <p>+ A receber: {{ diffValue | currency : "R$" }}</p>
                            <p>- Taxa Antecipação: <b> {{taxValue | currency : "R$"}}</b></p>
                            <br>
                            <hr><br>
                            <p>Valor bruto arrecadado: <b>{{grossValue | currency : "R$"}}</b></p>
                            <p>Valor liquido arrecadado: <b>{{liquidValue | currency : "R$"}}</b></p>
                        </div>
                        <!-- <a href="admin/comprovantes"> -->
                            <!-- <button disabled mat-flat-button class="default-btn outline col-xs-12 col-sm-12 col-md-12 col-lg-12" >
                                Exportar Comprovante TSE</button> -->
                        <!-- </a> -->
                        <button (click)="openModal()" mat-flat-button
                            class="default-btn green col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            Editar Dados Bancários</button>
                        <!-- a href="admin/comprovantes" class="historico"><p>Ver Histórico de Comprovantes</p></a -->
                    </div>
                </mat-card>
            </div>
            <div #duvidas class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <mat-card class="container-cd">
                    <h4>
                        Últimos saques <span class="destaque">realizados</span><br>
                    </h4>
                    <mat-divider></mat-divider>
                    <mat-list>
                       <!--  <mat-list-item *ngIf="!saques || !saques[0]">
                            <p>Saques só poderão ser realizados a partir do dia 16/08/2022 conforme resolução do TSE</p>
                        </mat-list-item> -->
                        <mat-list-item *ngFor="let donate of saques">
                            <div class="item">
                                <strong *ngIf="donate.status=='realizado'"> Saque efetivado </strong>
                                <strong *ngIf="donate.status=='pendente'"> Saque em análise </strong>
                                <strong *ngIf="donate.status=='recusado'"> Saque recusado </strong>
                                <span class="transfer">- &nbsp;{{ donate.value | currency : "R$" }}</span><br>
                            </div>
                            <!-- mat-divider></mat-divider -->
                        </mat-list-item>
                    </mat-list>
                </mat-card>
            </div>
        </div>
        <!-- <mat-card>
            <div class=" donations-container row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                    <div class="subheader">

                        <h2 class="line-height text-b2">
                            Minhas Doações
                        </h2>
                    </div>

                    <br>
                </div>
                <div class="values col-lg-12">
                    <div class="left col-lg-6">
                        <p class="total"><b>Total já Arrecadado:</b></p>
                        <p>Total de taxas</p>
                        <p>Disponível para Saque:</p>
                        <p>Sujeito a Antecipação:</p>
                    </div>
                    <div class="right col-lg-6">
                        <p class="total"><b>{{ liquidValue | currency : "R$" }} (Valor Bruto: {{ grossValue | currency : "R$"}})</b></p>
                        <p>{{ grossValue - liquidValue | currency : "R$"}}</p>
                        <p>{{ availableValue | currency : "R$"}}</p>
                        <p>{{ diffValue | currency : "R$"}} + Taxa Antecipação (-3%)</p>
                    </div>
                </div>
                <div class="btn-container col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <button *ngIf="diffValue > 0" (click)="openDialog(true)" mat-flat-button
                        class="default-btn full next-btn col-xs-12 col-sm-12 col-md-4 col-lg-3">
                        Antecipar Valor Total</button>
                    <button (click)="openDialog()" *ngIf="availableValue > 0" mat-flat-button
                        class="default-btn green  full col-xs-12 col-sm-12 col-md-4 col-lg-3">
                        Retirar Valor Disponível</button>
                </div>
                <br>
            </div>

        </mat-card> -->
        <mat-card>
            <div class=" donations-container row">
                <div class="values col-md-12 col-lg-12">
                    <div class="row full">
                        <default-form [forms]="profileForm" [step]="0" class="col-xs-12 col-sm-6 col-md-7 col-lg-8">
                        </default-form>
                        <div class="col-xs-12 col-sm-6 col-md-5 col-lg-4">
                            <button (click)="onSubmit()" mat-flat-button
                                class="default-btn green full col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                Buscar</button>
                            <button (click)="export()" mat-flat-button
                                class="default-btn full export col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                Exportar</button>
                        </div>
                    </div>
                </div>
                <div class=" donations-table-options col-md-12 col-sm-12 col-sm-12">
                    <table mat-table [dataSource]="dataSource" class="donations-table">
                        <ng-container matColumnDef="icon">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element">
                                <img class="icon" *ngIf="element.type == 'credito'" src="../../assets/payment/credit.svg">
                                <img class="icon" *ngIf="element.type == 'debito'" src="../../assets/payment/debit.svg">
                                <img class="icon" *ngIf="element.type == 'boleto'" src="../../assets/payment/barcode.svg">
                                <img class="icon" *ngIf="element.type == 'pix'" src="../../assets/payment/pix.svg">
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="paymentCode">
                            <th mat-header-cell *matHeaderCellDef> Código </th>
                            <td mat-cell *matCellDef="let element"> {{element.paymentCode}} </td>
                        </ng-container>
                        <ng-container matColumnDef="donatorInfo">
                            <th mat-header-cell *matHeaderCellDef> Cliente </th>
                            <td mat-cell *matCellDef="let element">
                                <strong
                                    class="name">{{ element.donatorInfo.nomecompleto || element.donatorInfo.Name }}</strong><br>
                                <span>{{ element.donatorInfo.Identity }}</span><br>
                                <span>{{ element.donatorInfo.Phone }}</span><br>
                                <span>{{ element.donatorInfo.Email }}</span>
                            </td>
                        </ng-container>
                        <!-- <ng-container matColumnDef="type">
                            <th mat-header-cell *matHeaderCellDef> Pagamento </th>
                            <td mat-cell *matCellDef="let element">
                                <div *ngIf="element.type == 'credito'"><strong>Cartão de Crédito</strong></div>
                                <div *ngIf="element.type == 'debito'"><strong>Cartão de Débito</strong></div>
                                <div *ngIf="element.type == 'boleto'"><strong>Boleto Bancário</strong><br>
                                    <span>{{element.paymentInfo.ResponseDetail.BankSlipNumber}}</span></div>
                            </td>
                        </ng-container> -->
                        <ng-container matColumnDef="paymentInfo">
                            <th mat-header-cell *matHeaderCellDef> Link do boleto</th>
                            <td mat-cell *matCellDef="let element">
                                <a class="link" target="_blank"
                                    *ngIf="element.paymentInfo && element.paymentInfo.ResponseDetail && element.paymentInfo.ResponseDetail.BankSlipUrl"
                                    href="{{element.paymentInfo.ResponseDetail.BankSlipUrl}}">Clique Aqui</a>
                                <a class="link" target="_blank"
                                    *ngIf="element.paymentInfo && element.paymentInfo.payment_method && element.paymentInfo.payment_method.boleto"
                                    href="{{element.paymentInfo.links[0].href}}">Clique Aqui</a>
                                <strong
                                    *ngIf="element.paymentInfo && element.paymentInfo.ResponseDetail && !element.paymentInfo.ResponseDetail.BankSlipUrl || element.paymentInfo && element.paymentInfo.payment_method && !element.paymentInfo.payment_method.boleto">N/A</strong>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="donationValue">
                            <th mat-header-cell *matHeaderCellDef> Valor líquido <br> <span>Valor doado</span> </th>
                            <td mat-cell *matCellDef="let element">
                                <strong>{{ element.liquidValueDonation | currency : "R$"}}</strong><br>
                                <small><span>{{element.donationValue | currency : "R$"}}</span></small>
                            </td>
                        </ng-container>
                        <!-- ng-container matColumnDef="paymentInfo.ResponseDetail">
                            <th mat-header-cell *matHeaderCellDef> Retorno </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.paymentInfo.ResponseDetail.Description || 'N/A' }} </td>
                        </ng-container-->
                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef> Status </th>
                            <td mat-cell *matCellDef="let element">
                                <div class="pendente" *ngIf="element.status == 'pendente'">Aguardando Pagamento</div>
                                <div class="pendente" *ngIf="element.status == 'processamento'">Pendente</div>
                                <div class="sucesso" *ngIf="element.status == 'autorizado'">Autorizado</div>
                                <div class="sucesso" *ngIf="element.status == 'aprovado'">Aprovado</div>
                                <div class="recusado" *ngIf="element.status == 'recusado'">Recusado</div>
                                <div class="recusado" *ngIf="element.status == 'rejeitado'">Rejeitado</div>
                                <div class="pendente" *ngIf="element.status == 'disponivel'">Disponível</div>
                                <div class="pendente" *ngIf="element.status == 'disputa'">Em Disputa</div>
                                <div class="pendente" *ngIf="element.status == 'devolvido'">Devolvido</div>
                                <div class="recusado" *ngIf="element.status == 'baixado'">Expirado</div>
                                <div class="recusado" *ngIf="element.status == 'cancelamento'">Em Cancelamento</div>
                                <div class="pendente" *ngIf="element.status == 'chargeback'">Chargeback</div>
                                <div class="pendente" *ngIf="element.status == 'liberado'">Liberado</div>
                            </td>
                        </ng-container>
                        <!-- <ng-container matColumnDef="createdAt">
                            <th mat-header-cell *matHeaderCellDef> Data de Criação </th>
                            <td mat-cell *matCellDef="let element">
                                <strong>{{element.createdAt | date :"dd/MM/yyyy"}}</strong><br>
                                <span>{{element.createdAt | date :"HH:mm"}}</span>
                            </td>
                        </ng-container> -->
                        <ng-container matColumnDef="updatedAt">
                            <th mat-header-cell *matHeaderCellDef> Última Atualização </th>
                            <td mat-cell *matCellDef="let element">
                                <strong>{{element.updatedAt | date :"dd/MM/yyyy"}}</strong><br>
                                <span>{{element.updatedAt | date :"HH:mm"}}</span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="recibo">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element" class="link" (click)="showDonate(element)">Recibo
                            </td>
                        </ng-container>

                        <!--tr>
                            <th>Código</th>
                            <th>Cliente</th>
                            <th>Status</th>
                            <th>Tipo de Pagamento</th>
                            <th>Link</th>
                            <th>Retorno</th>
                            <th>Valor</th>
                            <th>Data de Criação</th>
                            <th>Última Atualização</th>
                            <th></th>
                        </tr>
                        <tr *ngFor="let i of donationsList">
                            <td>{{ i.paymentCode }}</td>
                            <td>
                                <strong>{{ i.donatorInfo.Name }}</strong>
                                {{ i.donatorInfo.Identity }}
                            </td>
                            <td>{{ i.status }}</td>
                            <td>{{ i.type }}</td>
                            <td>
                                <a class="link" target="_blank" *ngIf="i.paymentInfo.ResponseDetail.BankSlipUrl" href="{{i.paymentInfo.ResponseDetail.BankSlipUrl}}">Clique Aqui</a>
                                <strong *ngIf="!i.paymentInfo.ResponseDetail.BankSlipUrl">N/A</strong>
                            </td>
                            <td>{{ i.paymentInfo.ResponseDetail.Description || 'N/A' }}</td>
                            <td>R${{ i.donationValue }}</td>
                            <td>{{ i.createdAt | date :"dd/MM/yyyy - HH:mm"}}</td>
                            <td>{{ i.updatedAt | date :"dd/MM/yyyy - HH:mm"}}</td>
                            <td class="link" (click)="showDonate(i)">Recibo</td>
                        </tr-->
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <mat-paginator [pageSize]="10"></mat-paginator>
                </div>
            </div>
        </mat-card>
    </div>
</div>
