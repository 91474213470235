import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { MatButton } from '@angular/material/button';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { DomainProvider } from 'src/providers/domains';
import { MaquininhaService } from 'src/providers/maquininha.service';
import { ENV } from 'src/environments/environment-variables.token';
import { UtilsProvider } from 'src/commons/utils';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { TransferService } from 'src/providers/transfer.service';
import { AccountService } from 'src/providers/account.service.';
import { MediaService } from 'src/providers/media.service';
import { type } from 'os';

@Component({
  selector: 'analysis-modal',
  templateUrl: './analysis-modal.component.html',
  styleUrls: ['../app.component.scss', './analysis-modal.component.scss'],
})
export class AnalysisModalComponent implements OnInit {
  public title: string;
  public content: string;
  public event: any;
  public formAddress: any;
  public cnpj: any;
  public formPix: any;
  public address: any;
  public state: any;
  public terms = false;
  public error = false;
  private transfer: any;
  public docIdentidade: any;
  public cartaoCnpj: any;
  public account: any;
  public checkTypeField: any = '';
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('fileInputCnpj') fileInputCnpj: ElementRef;
  constructor(
    public formBuilder: FormBuilder,
    private domain: DomainProvider,
    private maquininha: MaquininhaService,
    private transferProvider: TransferService,
    private mediaProvider: MediaService,
    private accountProvider: AccountService,
    public utils: UtilsProvider,
    public matDialogRef: MatDialogRef<AnalysisModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(ENV) public ENV: any
  ) {}

  ngOnInit() {
    const self = this;
  }

  close() {
    this.matDialogRef.close();
  }

  performClick(el: MatButton) {
    el._elementRef.nativeElement.click();
  }
}
