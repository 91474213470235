import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
@Injectable({
	providedIn: 'root'
})
export class MaquininhaService {
    constructor(
        private http: HttpService
    ) {}

    createRequest(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.post('api/maquininha/createRequest', true, data).then(res => {
                resolve(res);
            })
            .catch(err => reject(err));
        });
    }
}
