import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UtilsProvider } from 'src/commons/utils';
import { ENV } from 'src/environments/environment-variables.token';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { WithdrawService } from 'src/providers/withdraw.service';

@Component({
  selector: 'app-withdraw-panel',
  templateUrl: './withdraw-panel.component.html',
  styleUrls: ['./withdraw-panel.component.scss'],
})
export class WithdrawPanelComponent implements OnInit {
  displayedColumns: string[] = [
    'dateRequest',
    'status',
    'liquid',
    'bruto',
    'tax',
  ];

  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public dataSource;

  constructor(
    public formBuilder: FormBuilder,
    public dialog: MatDialog,
    private WithdrawProvider: WithdrawService,
    @Inject(ENV) public ENV: any,
    public utils: UtilsProvider,
    private router: Router
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
    if (!localStorage.getItem('user')) {
      this.router.navigate(['/entrar']);
    }
    this.ENV.active = 'withdraw';
    // this.getVaquinha();

    this.WithdrawProvider.listWithdraw().then((res) => {
      this.dataSource = new MatTableDataSource(res);
      this.dataSource.paginator = this.paginator;
    });
  }
}
