import { ForgetEmailComponent } from './forget-email/forget-email.component';
import { SearchCandidates2020Component } from './search-candidates-2020/search-candidates-2020.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AdminComponent } from './admin/admin.component';
import { MaquininhaComponent } from './maquininha/maquininha.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ContatoComponent } from './contato/contato.component';
import { CandidateComponent } from '../app/candidate/candidate.component';
import { CandidateExComponent } from '../app/candidate-example/candidate-example.component';
import { GuidelineComponent } from './guideline/guideline.component';
import { DonationsPageComponent } from './donations-page/donations-page.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ProfileComponent } from './profile/profile.component';
import { CandidateProfileComponent } from './candidate-profile/candidate-profile.component';
import { QuestionsComponent } from './questions/questions.component';
import { DonationsPanelComponent } from './donations-panel/donations-panel.component';
import { MyVaquinhaComponent } from './my-vaquinha/myVaquinha.component';
import { PaymentsComponent } from './payments/payments.component';
import { SearchCandidatesComponent } from './search-candidates/search-candidates.component';
import { HomeAdminComponent } from './home-admin/home-admin.component';
import { DonatorsListComponent } from './donators-list/donators-list.component';
import { ExportsPanelComponent } from './exports-panel/exports-panel.component';
import { MainHomeComponent } from './mainHome/mainHome.component';
import { WithdrawVaquinhaComponent } from './withdraw-vaquinha/withdraw-vaquinha.component';
import { WithdrawPanelComponent } from './withdraw-panel/withdraw-panel.component';
import { OldVaquinhaComponent } from './oldVaquinhas/oldVaquinha.component';
import { OldDonationsComponent } from './oldDonations/oldDonations.component';

const routes: Routes = [
  // { path: '', component: MainHomeComponent },
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'maquininha', component: MaquininhaComponent },
  { path: 'entrar', component: LoginComponent },
  { path: 'cadastrar', component: RegisterComponent },
  { path: 'exemplo', component: CandidateExComponent },
  { path: 'doacoes', component: DonationsPageComponent },
  { path: 'OldDoacoes', component: OldDonationsComponent },
  { path: 'pagamentos', component: PaymentsComponent },
  { path: 'esqueci-a-senha', component: ForgetPasswordComponent },
  { path: 'esqueci-o-email', component: ForgetEmailComponent },
  { path: 'mainHome', component: MainHomeComponent },
  { path: 'guideline', component: GuidelineComponent },
  //{ path: 'perfil', component: ProfileComponent },
  { path: 'candidate-profile', component: CandidateProfileComponent },
  { path: 'duvidas', component: QuestionsComponent },
  //{ path: 'painel-doacoes', component: DonationsPanelComponent },
  { path: 'candidatos', component: SearchCandidatesComponent },
  { path: 'dadosAnteriores', component: OldVaquinhaComponent },
  { path: 'candidatos-2020', component: SearchCandidates2020Component },
  { path: 'contato', component: ContatoComponent },
  {
    path: 'admin',
    component: AdminComponent,
    children: [
      { path: '', redirectTo: 'inicio', pathMatch: 'full' },
      {
        path: 'inicio',
        component: MyVaquinhaComponent /* HomeAdminComponent */,
      },
      { path: 'perfil', component: ProfileComponent },
      { path: 'painel-doacoes', component: DonationsPanelComponent },
      { path: 'painel-saque', component: WithdrawPanelComponent },
      { path: 'minhas-vaquinhas', component: MyVaquinhaComponent },
      {
        path: 'minhas-vaquinhas/:entity',
        component: WithdrawVaquinhaComponent,
      },
      { path: 'ajuda', component: QuestionsComponent },
      { path: 'doadores', component: DonatorsListComponent },
      { path: 'comprovantes', component: ExportsPanelComponent },
    ],
  },
  { path: ':entity', component: CandidateComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
