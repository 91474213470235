import { Injectable, Inject } from '@angular/core';
import { HttpService } from './http.service';
import { Router } from '@angular/router';
import { ENV } from '../environments/environment-variables.token';
import { SessionStorageService } from 'angular-web-storage';
import { UtilsProvider } from 'src/commons/utils';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	constructor(
		private http: HttpService,
		private router: Router,
		public session: SessionStorageService,
		private utils: UtilsProvider,
		@Inject(ENV) public ENV: any
	) { }

	login(data: any): any {
		return new Promise((resolve, reject) => {
			this.http.post('api/users/login', false, data).then(res => {
				this.setToken(res.token).then(() => {
					resolve(res.token);
				});
			}).catch(err => {
				reject(err)
				console.log('erroorrre', err);
				return this.utils.toast({
					/* message: err.error.message, */
					message: 'Email ou senha inválidos',
					duration: 10000,
					action: 'Ok'
				});
			});
		});
	}

	register(data: any): any {
		return new Promise((resolve, reject) => {
			this.http.post('api/user/createUser/createCandidate', false, data).then(res => {
				console.log('REGISTER RESPONSE', res);
				this.setToken(res.token).then(() => {
					resolve();
				});
			}).catch(err => {
				// console.log("erroorrre", err.error.message);
				reject(err)
				return this.utils.toast({
					/* message: err.error.message, */
					message: err,
					duration: 10000,
					action: 'Ok'
				});
			});
		});
	}

	logout() {
		return new Promise((resolve, reject) => {
			this.ENV.USER = undefined;
			localStorage.clear();
			resolve();
		}).catch(err => console.log('Error trying to log you out: ', err));
	}

	setToken(token: string): any {
		return new Promise((resolve, reject) => {
			this.http.setToken(token).then(() => {
				this.me().then((r) => {

					resolve();
				});
			});
		}).catch(err => console.log('Error trying to set token: ', err));
	}

	me() {
		return new Promise((resolve, reject) => {
			this.http.get('api/auth/protected', true).then((auth: any) => {
				console.log('PROTECTED RESPONSE: ', auth);
				this.ENV.USER = auth;
				localStorage.setItem('user', JSON.stringify(auth));
				resolve();
			})
			.catch(err => {
				localStorage.clear();
				// localStorage.removeItem('user');
				this.router.navigate(['/entrar']);
				reject(err);
			});
		});
	}

	forgotPassword(data: any): any {
		return new Promise((resolve, reject) => {
			this.http.post('api/user/forgottenPassword/forgotPassword', false, data).then((res) => {
				console.log('E-mail enviado!');
				this.utils.toast({
					message: res.message,
					duration: 10000,
					action: 'Ok'
				});
				
				resolve();
			}).catch(err => reject(err));
		});
	}

	forgotEmail(data: any): any {
		return new Promise((resolve, reject) => {
			this.http.post('api/user/forgottenEmail/forgottenEmail', false, data).then((res) => {
				this.utils.toast({
					message: res.message,
					duration: 10000,
					action: 'Ok'
				});
				
				resolve();
			}).catch(err => reject(err));
		});
	}
}


