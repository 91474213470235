import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpClientModule } from '@angular/common/http';
import { MatInputModule,  } from '@angular/material/input';
import { QuestionsComponent } from './questions.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
// import { ScrolledToDirective } from 'src/directives/scrolled-to.directive';
import {MatStepperModule} from '@angular/material/stepper';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from 'src/components/form-components.module';
// import { NgxMaskModule } from 'ngx-mask'

@NgModule({
  imports: [
	MatCardModule,
	MatButtonModule,
	MatDialogModule,
	// NgxMaskModule.forRoot(),
	ComponentsModule,
	CommonModule,
	MatFormFieldModule,
	MatInputModule,
	BrowserModule,
	FormsModule,
	RouterModule,
	HttpClientModule,
	MatStepperModule,
	ReactiveFormsModule,
	MatExpansionModule
  ],
  declarations: [
	QuestionsComponent,
  ],
  entryComponents: [
  ],
  providers: [
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})

export class QuestionsComponentModule { }
