<h1 class="title">Título</h1>
<p class="normal-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque in enim non metus consectetur condimentum scelerisque eget neque. Ut ornare congue tellus. Maecenas vulputate, quam vel auctor feugiat, diam urna congue eros, in luctus arcu purus ut erat. Donec ipsum risus, mollis luctus tempor eu, mollis et velit.</p>
<br/>
<h1 class="title">Forms</h1>
<default-form [forms]="testeForm" [step]="testeFormStep"></default-form>
<br/>
<h1 class="title">Botões</h1>
<button class="menu-button">Acessar</button>
<br/>
<br/>
<button class="confirm-button">Cadastrar</button>
<br/>
<br/>
<div class="row">
    <div class="col-md-4" style="background-color: aqua;">
        Flexbox
    </div>
    <div class="col-md-4" style="background-color: blueviolet;">
        Flexbox 2
    </div>
    <div class="col-md-4" style="background-color: cornflowerblue;">
        Flexbox 3
    </div>
</div>