import { logging } from 'protractor';
import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { DomainProvider } from 'src/providers/domains';
import { ENV } from 'src/environments/environment-variables.token';
import { UtilsProvider } from 'src/commons/utils';
import { Router } from '@angular/router';
import { VaquinhaService } from 'src/providers/vaquinha.service';

@Component({
  selector: 'mainHome',
  templateUrl: './mainHome.component.html',
  styleUrls: ['../app.component.scss', './mainHome.component.scss'],
})
export class MainHomeComponent implements OnInit {
  public vaquinha: any;
  public totalVaquinha;
  public title: any;
  public meta: any;
  public image: any;
  public peapleDonation: any;
  private skip = 0;
  private limit = 8;
  public finalSearch = false;

  constructor(
    public formBuilder: FormBuilder,
    private domain: DomainProvider,
    private router: Router,
    private mainHome: VaquinhaService,
    public utils: UtilsProvider,
    @Inject(ENV) public ENV: any
  ) {}

  ngOnInit() {
    this.getVaquinha(false);
    this.getTotalVaquinha();
  }

  getVaquinha(showMore) {
    this.mainHome
      .getVaquinha({
        skip: this.skip,
        limit: this.limit,
      })
      .then((res) => {
        if (res.length != this.limit) this.finalSearch = true;
        if (showMore) {
          this.vaquinha.push(...res);
        } else {
          this.vaquinha = res;
        }
      })
      .catch((err) => {
        console.log('ERR: ', err);
        throw err;
      });
  }

  getTotalVaquinha() {
    this.mainHome
      .getTotalVaquinha()
      .then((res) => {
        console.log('res', res);
        this.totalVaquinha = res;
      })
      .catch((err) => {
        console.log('ERR: ', err);
        throw err;
      });
  }

  expand() {
    this.skip++;
    this.getVaquinha(true);
  }

  createVaq() {
    const session = JSON.parse(localStorage.getItem('user'));
    if (session != null) {
      this.router.navigate(['/admin/minhas-vaquinhas']);
    } else {
      this.router.navigate(['/entrar']);
    }
  }

  navigate(candidato: any) {
    this.router.navigate([candidato.url]);
  }
}
