<!-- <div *ngIf="data.confirmData != true; else elseBlock">
<div class="view-photo">
  <img class="col-md-12" alt="vaquinha" src={{data.image.link}} />
</div>
<p> <strong>Descrição:</strong> {{data.description}}</p>
<p><strong>Início:</strong> {{data.initAt | date :"dd/MM/yyyy HH:mm" }}</p>
<p><strong>Término:</strong> {{data.endAt | date :"dd/MM/yyyy HH:mm"}}</p>
<p><strong>Tipo:</strong> {{ data.type.name }}</p>
<p><strong>URL:</strong> {{ data.url }}</p>
<p><strong>Messagem de agradecimeto:</strong> {{ data.thankMessage }}</p>
<p class="align-center"><strong>Tipo de Pagamentos</strong></p>

<div class="view-payments">
  <div *ngFor="let method of data.paymentTypes" >
    <img class="icon" *ngIf="method == 'debit'"  src="../../assets/payment/debit.svg">
    <img class="icon" *ngIf="method == 'credit'"  src="../../assets/payment/credit.svg">
    <img class="icon" *ngIf="method == 'pix'"  src="../../assets/payment/pix.svg">
    <img class="icon" *ngIf="method == 'ticket'"  src="../../assets/payment/barcode.svg">
  </div>
</div>
</div>

<ng-template #elseBlock> -->
  <div class="display">

    <div  *ngFor="let view of selectData">

        <button (click)="selectView(view.value)"><h2 class="{{select == view.value ? 'active' : ''}}">{{view.html}}</h2></button>
    </div>
  </div>
  <div class="display" *ngIf="select == 'vaquinha'">

    <div class="align-center">
      <img class="col-md-12 height" alt="vaquinha" src={{data.image.link}} />
    </div>
    <div>
      <p> <strong>Descrição:</strong> {{data.description}}</p>
      <p *ngIf="data.typeRef.name == 'Normal'"><strong>Início:</strong> {{data.initAt | date :"dd/MM/yyyy HH:mm" }}</p>
      <p *ngIf="data.typeRef.name == 'Normal'"><strong>Término:</strong> {{data.endAt | date :"dd/MM/yyyy HH:mm"}}</p>
      <p><strong>Tipo:</strong> {{ data.typeRef.name }}</p>
      <p><strong>URL:</strong> {{ data.url }}</p>
      <p><strong>Messagem de agradecimeto:</strong> {{ data.thankMessage }}</p>
    </div>
  </div>
  <div class="display" *ngIf="select == 'account'">

    <div>
      <p> <strong>Banco:</strong> {{data.account.banco}}</p>
      <p> <strong>Agência:</strong> {{data.account.agencia}}</p>
      <p><strong>Digíto da Agência:</strong> {{data.account.digAgencia }}</p>
      <p><strong>Conta:</strong> {{data.account.conta }}</p>
      <p><strong>Digíto da conta:</strong> {{ data.account.digConta }}</p>
      <p><strong>Tipo de conta:</strong> {{ data.account.tipoConta }}</p>
      <p *ngIf="data.account.tipoPessoa == 'Física'"><strong>CNPJ:</strong> {{ data.account.cnpj }}</p>
      <p *ngIf="data.account.tipoPessoa != 'Física'"><strong>CPF:</strong> {{ data.account.cpf }}</p>
    </div>
  </div>
  <div class="display" *ngIf="select == 'candidate'">

    <div class="align-center">
      <img class="col-md-12 height" alt="vaquinha" src={{data.candidate.image.host+data.candidate.image.full}} />
    </div>
    <div>
      <p><strong>Partido político:</strong> {{data.candidate.politicalParty.name}}</p>
      <p><strong>Cargo:</strong> {{data.candidate.position.name}}</p>
      <p><strong>Bandeiras:</strong> {{data.candidate.tags }}</p>
      <p><strong>Bio</strong></p> <div [innerHTML]="data.candidate.bio">&nbsp;</div>
    </div>
  </div>

<!-- </ng-template> -->
