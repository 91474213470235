<div class="home-content">
    <div class="content" fxFlex="50" wmAnimate="landing" speed="slow" aos>
        <div class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1">
            <div class="row component-container">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 header">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-header">
                            <h1 class="title">Candidatos Atuais!</h1><img class="icon"
                                src="../../assets/image/porquinho.png" />
                        </div>
                        <div
                            class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12">
                            <form class="value-form">
                                <mat-form-field appearance="outline" class="form-field">
                                    <mat-label>Pesquise seu candidato aqui</mat-label>
                                    <input type="text" matInput name="keyword" [(ngModel)]="keyword"
                                        (keyup.enter)="search()" placeholder="Nome, Partido, Cidade ou Bandeiras">
                                    <button mat-icon-button matSuffix matTooltip="Pesquisar" (click)="search()">
                                        <span class="input-icon material-icons">search</span>
                                    </button>
                                </mat-form-field>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="box">
                        <div class="row">
                            <div class="null" *ngIf="!candidatos[0]">
                                <p> Nenhum resultado foi encontrado para sua busca :( </p>
                            </div>
                            <div *ngFor="let i of candidatos" class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                <mat-card *ngIf="i.userRef" class="image-card">
                                    <!--div mat-card-image (click)="more(i)" class="card-image center-cropped click"
                                        style="background-image: url({{ getPicture(i) }});"></div -->
                                    <div class="avatar">
                                        <div class="avatar-img center-cropped" (click)="more(i)"
                                            style="background-image: url({{ getPicture(i) }});">
                                            <!-- <img [src]="getPicture(i)" class="img-avatar"> -->
                                        </div>
                                    </div>
                                    <div class="text page-person">
                                        <h1 class="click capitalize" (click)="more(i)">{{ getNome(i) }}</h1>
                                        <p><b>{{ getPartido(i) }}</b></p><br>
                                        <img class="icon-small gray" src="../../assets/map-marker.svg">
                                        <p>{{ getEstado(i) }}, {{ i.userRef.municipio }}</p><br><br>
                                        <h3 *ngIf="i.total != 0" class="money">
                                            <b>{{ i.total| currency : 'R$'  }} já arrecadado!</b></h3>
                                        <h3 *ngIf="i.total == 0" class="money"><b>Seja o <strong>primeiro</strong> a
                                                doar!</b></h3>
                                    </div>
                                    <mat-card-content>
                                    </mat-card-content>
                                    <mat-card-actions>
                                        <button class="redirect-button" mat-flat-button (click)="more(i)">Quero
                                            Apoiar!</button>
                                    </mat-card-actions>
                                </mat-card>
                            </div>
                        </div>
                    </div>
                </div>
                <div (click)="expand()" *ngIf="candidatos[0] && candidatos.length >= 8"
                    class="col-xs-12 col-sm-12 col-md-12 col-lg-12 expand">
                    <span class="input-group-addon material-icons">keyboard_arrow_down</span>
                    <a>Ver Mais</a>
                </div>
            </div>
            <br>
        </div>
    </div>
</div>