import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ENV } from 'src/environments/environment-variables.token';
import { HttpService } from 'src/providers/http.service';
import { ScrollService } from 'src/providers/scroll.service';
import { Subscription } from 'rxjs';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import { CandidateService } from '../../providers/candidate.service';
import { MatStepper } from '@angular/material/stepper';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/providers/auth.service';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { FaleConoscoModalComponent } from '../fale-conosco-modal/fale-conosco-modal.component';
import { UtilsProvider } from 'src/commons/utils';
import { MatButton } from '@angular/material/button';

@Component({
	selector: 'app-search-candidates-2020',
	templateUrl: './search-candidates-2020.component.html',
	styleUrls: ['./search-candidates-2020.component.scss'],
})
export class SearchCandidates2020Component implements OnInit {
	title = 'Quero Apoiar - Home';
	faPlayCircle = faPlayCircle;
	public email: any;
	public name: any;
	public telefone: any;
	public senha: any;
	public senhaConf: any;
	public error: any;
	public success: any;
	public loading: any;
	public headerHide: any;
	public already: any;
	public terms = false;
	public daysLeft: any;
	public keyword: any;
	public showVideo = true;
	public completo = false;
	public isDisabled = false;
	emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
	userForm = this.formBuilder.group({
		email: ['', [Validators.required]]
	});
	eventSubscription: any;
	public elements = ['cadastro', 'comofunciona', 'contato'];
	public currentEl = 0;
	private offsetTop = 0;
	private skip = 0
	public formGeneralData: any;
	public formPersonal: any;
	public formCidade: any;
	public stepper: MatStepper;
	public stepperCount = 0;
	public arrCities = [];
	public arrStates: any;
	public city: '';
	public state: '';
	public busca: any;
	public url: any;
	public errorAlias = false;
	public candidatos: any;
	public message: any;
	public values: any;
	public imgUrl: any;

	constructor(
		private httpClient: HttpClient,
		private router: Router,
		private scrollService: ScrollService,
		private candidateService: CandidateService,
		private formBuilder: FormBuilder,
		private httpService: HttpService,
		private titleService: Title,
		private auth: AuthService,
		public utils: UtilsProvider,
		private metaTagService: Meta,
		private _snackBar: MatSnackBar,
		public dialog: MatDialog,
		@Inject(DOCUMENT) private document: Document,
		@Inject(ENV) public ENV: any
	) { }

	ngOnInit() {
		window.scrollTo(0, 0)
		const self = this;
		//this.titleService.setTitle(this.title)
		this.candidateService.getCandidatosAdmin2020({ skip: 0 }).then(res => {
			//this.metaTagService.updateTag(
			//	{ name: 'description', content: 'Bem vindo ao Quero Apoiar' }
			//);
			//console.log('CANDIDATOS ', res);
			this.candidatos = res;
		}).catch(err => {
			console.log('ERR: ', err);
			throw err;
		});
	}

	expand() {
		this.skip++
		this.candidateService.getCandidatosAdmin2020({ skip: this.skip, search: this.keyword }).then(res => {
			this.candidatos.push(...res);
			//console.log(this.candidatos)
		}).catch(err => {
			console.log('ERR: ', err);
			throw err;
		});
	}

	search() {
		this.skip = 0;
		var value = ""
		this.candidateService.getCandidatosAdmin2020({ skip: this.skip, search: this.keyword }).then(res => {
			this.candidatos = res;
			//console.log(this.candidatos)
		}).catch(err => {
			console.log('ERR: ', err);
			throw err;
		});
	}

	openSnackBar(message: string, action: string) {
		this._snackBar.open(message, action, {
			duration: 6000,
		});
	}

	performClick(el: MatButton) {
		el._elementRef.nativeElement.click()
	}

	getPicture(candidato: any) {
		console.log("candidato",candidato);
		if (candidato.userRef && candidato.userRef.media && candidato.userRef.media[0]) {
			const media = candidato.userRef.media[0];
			return media.host + '' + media.full;
		}
		else {
			return '../../assets/image/no-avatar-width.jpg';
		}
	}

	onKeyCandidato(value: string) {
		this.candidateService.getProfiles({ search: value }).then((res: any) => {
			console.log(res);
			const array = Array.prototype.slice.call(res);
			if (this.values !== '') {
				array.map(e => {
					if (e.name === this.values) {
						this.candidatos = [e];
						// console.log(this.candidatos);
					}
				});
			} else {
				this.candidatos = res;
			}
		});
	}

	scrollTo(el: any) {
		if (this[el]) {
			this[el].nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	}

	more(candidato) {
		if (candidato && candidato.alias) {
			this.router.navigate([candidato.alias]);
		}
	}

	getNome(candidato) {
		var nome;
		if (candidato && candidato.userRef && candidato.userRef.urna) { nome = String(candidato.userRef.urna).toLowerCase() }
		else if (candidato && candidato.userRef && candidato.userRef.name) { nome = String(candidato.userRef.name).toLowerCase() }
		if (nome.length > 22) return nome.slice(0, 23) + "..."
		else return nome
	}

	getPartido(candidato) {
		var partido;
		if (candidato.userRef && candidato.userRef.partido) {
			partido = String(candidato.userRef.partido)
			if (partido.length > 20) return partido.slice(0, 20) + "..."
			else return partido
		}
	}

	getEstado(candidato) {
		var estado;
		if (candidato.userRef && candidato.userRef.estado) {
			estado = String(candidato.userRef.estado)
			if (estado == "Rio de Janeiro") return "RJ"
			else return estado
		}
	}
}
