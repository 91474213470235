import { Component, OnInit, ElementRef, ViewChild, OnDestroy, Inject, HostListener, Host } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ENV } from '../../environments/environment-variables.token';
import { DOCUMENT } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FaleConoscoModalComponent } from '../fale-conosco-modal/fale-conosco-modal.component';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { HttpService } from 'src/providers/http.service';
import { UtilsProvider } from 'src/commons/utils';
import { MatButton } from '@angular/material/button';
import { PaymentService } from 'src/providers/payments.service';
import { MaquininhaModalComponent } from '../maquininha-modal/maquininha-modal.component';
import { CandidateService } from 'src/providers/candidate.service';
import { ModalDefaultComponent } from '../modal-default/modal-default.component';
import { SaqueModalComponent } from '../saque-modal/saque-modal.component';
import { AccountService } from 'src/providers/account.service.';
import { TransferModalComponent } from '../transfer-modal/transfer-modal.component';
import * as moment from 'moment';
@Component({
	selector: 'home-admin',
	templateUrl: './home-admin.component.html',
	styleUrls: ['./home-admin.component.scss']
})
export class HomeAdminComponent implements OnInit {
	constructor(
		private _snackBar: MatSnackBar,
		public dialog: MatDialog,
		private router: Router,
		public formBuilder: FormBuilder,
		public http: HttpService,
		public utils: UtilsProvider,
		private paymentsProvider: PaymentService,
		private accountProvider: AccountService,
		private candidateProvider: CandidateService,
		@Inject(ENV) public ENV: any,
		@Inject(DOCUMENT) private document: Document,
	) {
	}

	name: string;
	cpf: number;
	date: string;
	donationMethod: string;
	value: string;
	candidato: any;
	public donationsList: any;
	public totalValue = 0;
	public liquidValue = 0;
	public availableValue = 0;
	public grossValue = 0;
	public diffValue = 0;
	public taxValue = 0;
	public currentEl = 0;
	private offsetTop = 0;
	private total: any;
	private tax: any;
	private available: any;


	@ViewChild('comofunciona', { static: true }) comofunciona: ElementRef;
	@ViewChild('contato', { static: true }) contato: ElementRef;
	@ViewChild('cadastro', { static: true }) cadastro: ElementRef;


	private prev = 0;

	ngOnInit() {
		window.scrollTo(0, 0);
		this.ENV.active = 'home';
		const _user = JSON.parse(localStorage.getItem('user'));
		// console.log('USER EMNU: ', _user);
		this.paymentsProvider.getAllHandling({ user: _user.user._id }).then(async (res) => {

			this.donationsList = res;
			const [errValue, value] = await this.utils.tryCatch(this.candidateProvider.getAvailableDonations({ user: _user.user._id }));
			if (errValue) { console.log('Não foi possível resgatar os valores das doações'); }

			console.log(value);
			this.total = value.data.totalValue;
			this.available = value.data.availableValue;
			const diff = value.data.antecipationTotal ;
			this.tax = value.data.grossDiff;

			this.taxValue = this.tax > 0 ? this.tax : 0;
			this.diffValue = diff > 0 ? diff : 0;
			this.totalValue = this.total > 0 ? this.total : 0;
			this.availableValue = this.available > 0 ? this.available : 0;
			this.grossValue = value.data.grossValue;
			this.liquidValue = value.data.liquidValue > 0 ? value.data.liquidValue : 0;
		}).catch(e => {
			this.utils.toast({ message: 'Nenhum valor de doação para ser exibido.' });
		});
	}

	scrollEvent(event) {
		this.offsetTop = event.target.scrollTop;
		// console.log("SCROLLED: ", event);
	}

	newScroll(el: HTMLElement) {
		el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
	}
	openSnackBar(message: string, action: string) {
		this._snackBar.open(message, action, {
			duration: 6000,
		});
	}

	getName(donate: any) {
		const name = String(donate.userName).toLowerCase().split(' ');
		return String(donate.userName).toLowerCase();
	}

	getValue(donate: any) {
		return String(donate.value).replace('.', ',');
	}

	changeContent(event) {
		const offsetLeft = 0;
		let offsetTop = 0;

		let el = event.srcElement;

		while (el) {
			el = el.parentElement;
			offsetTop += el.offsetTop;
		}
		console.log(offsetTop);

		if (offsetTop <= 700) {

		}
	}

	goToUrl(): void {
		this.document.location.href = '/questions';
	}

	openDialog() {
		const dialogRef = this.dialog.open(MaquininhaModalComponent, {});
		dialogRef.afterClosed().subscribe(result => { });
	}

	goHome() {
		this.router.navigate(['home']);
	}

	performClick(el: MatButton) {
		el._elementRef.nativeElement.click();
	}

	async openDialogTransfer(antecipate?: any) {

		const _user = JSON.parse(localStorage.getItem('user'));
		const accounts = await this.accountProvider.getAccount({ user: _user.user._id });
		console.log('ACCOUNTS: ', accounts);
		if (moment().isAfter(moment('2022-01-01T00:00:00'))){
			if (antecipate) {
				const confirmDialog = this.dialog.open(ModalDefaultComponent, {
					data: {
						title: 'Deseja mesmo antecipar o valor total?',
						message: '<p>Caso você opte por antecipar o valor total das doações, <br> uma taxa de 3% será aplicada sobre o valor bruto ainda não liberado. <br>Tem certeza que deseja continuar?</p>'
					}
				});
				confirmDialog.afterClosed().subscribe(result => {
					console.log('RESULT: ', result);
					if (result === 'false') { return; }
					else if (!accounts || accounts == null) {
						const dialogRef = this.dialog.open(SaqueModalComponent);
						dialogRef.afterClosed().subscribe(result => {
							console.log('RESULT: ', result);
							const dialogRef = this.dialog.open(TransferModalComponent, { data: { antecipado: Number(this.total), account: result.success.transfer } });
							dialogRef.afterClosed().subscribe(result => { });
						});
					} else {
						const dialogRef = this.dialog.open(TransferModalComponent, { data: { antecipado: Number(this.total), account: accounts } });
						dialogRef.afterClosed().subscribe(result => { });
					}
				});
			} else {
				if (!accounts || accounts == null) {
					const dialogRef = this.dialog.open(SaqueModalComponent, { data: { available: this.available > 0 ? this.available : 0 } });
					dialogRef.afterClosed().subscribe(result => {
						console.log('RESULT: ', result);
						const dialogRef = this.dialog.open(TransferModalComponent, { data: { antecipado: this.total - this.tax, account: result.success.transfer } });
						dialogRef.afterClosed().subscribe(result => { });
					});
				} else {
					const dialogRef = this.dialog.open(TransferModalComponent, { data: { available: this.available > 0 ? this.available : 0, account: accounts } });
					dialogRef.afterClosed().subscribe(result => { });
				}
			}
		}else{

			const dialogRef = this.dialog.open(ModalDefaultComponent, {data: {
						title: 'Função desabilitada',
						message: '<p>Saques só poderão ser realizados a partir do dia 16/08/2022 conforme resolução do TSE</p>'
					}
				});
			dialogRef.afterClosed().subscribe(result => { });
		}
	}
}
