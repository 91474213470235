<div>
  <p>
    Informe os dados da conta bancária onde será utilizada para o recebimento.
  </p>
</div>
<default-form  [forms]="formAccount" [step]="0"></default-form>
<div *ngIf="checkTypeField != ''">

  <div ngClass="label">Envie os documentos abaixo: {{checkTypeField}}</div>
  <div
  [ngClass]="
    checkTypeField == 'CPF'
    ? 'container_docImage container_docImage_center'
    : 'container_docImage container_docImage_evenly'
    "
    >

    <div *ngFor="let item of docImages; index as i">
      <div [ngClass]="item.inputName == 'myFileInputCNPJ' && checkTypeField == 'CPF' ? 'hidden' : 'visible'">

        <div ngClass="container_docImage_middle">

          <img *ngIf="item.file" [src]="item.file" alt="Preview" width="300" height="200">
          <div
            *ngIf="!item.file"
            ngClass="container_docImage_preview"
            (click)="selectFile(item.inputName)"
          >+</div>
          <input type="file" id={{item.inputName}} style="display: none;" (change)="docFile($event.target.files, i)">
          <button mat-flat-button #register class="btn1 outline"  (click)="selectFile(item.inputName)">
            {{item.text}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<default-form  [forms]="formPix" [step]="0"></default-form>
<div *ngIf="checkTypeField != 'CPF'">
  <default-form  [forms]="razaoSocial" [step]="0"></default-form>
</div>

<div class="btn-container">
  <button mat-flat-button class="btn1"  (click)="back()">Voltar</button>

  <button mat-flat-button class="btn2"  (click)="next()" >{{last == false ? 'Continuar': 'Salvar'}}</button>
</div>
