<div class="card-container">
    <div class="duvidas" id="duvidas">
        <div class="row">
            <div class="col-xs-12 col-sm-8 col-md-4 col-lg-4">
                <div>
                    <img src="../../assets/maquininha-oficial.png" width="40%">
                </div>
            </div>
            <div class=
                "col-xs-12
                col-sm-8
                col-md-4
                col-lg-8">
                <mat-accordion>
                    <mat-expansion-panel *ngFor="let question of questions">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="questions">
                                {{question.title}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p>{{question.answer}}</p>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </div>
    <div class="right-bottom col-xs-12 col-sm-12 col-md-12 col-xs-12">
        <div>
            <h1 class="subtitle">Ficou com dúvida?</h1>
            <p class="normal-text">Sua dúvida não está aqui? Fale com a gente!</p>
            <div class="contato normal-text">
                <div><span class="material-icons">phone_in_talk</span> (11) 91111-1111</div>
                <div><span class="material-icons">email</span> contato@teste.com</div>
            </div>
        </div>
    </div>
</div>