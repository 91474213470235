import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/providers/auth.service';
import { UtilsProvider } from 'src/commons/utils';
import { ENV } from 'src/environments/environment-variables.token';
import { UserService } from 'src/providers/user.service';
import { HttpService } from 'src/providers/http.service';
import { Router } from '@angular/router';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CandidateService } from 'src/providers/candidate.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ImageCropModalComponent } from '../image-crop-modal/image-crop-modal.component';
import { ArrayDataSource } from '@angular/cdk/collections';
import { UrnaService } from 'src/providers/urna.service';

@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
	public profileForm: any;
	public imageUrl = '../../assets/no-avatar.png';
	public coverUrl = '../../assets/cover.jpg'
	private fileToUpload: File = null;
	private coverToUpload: File = null;
	private selectedImage: any;
	private selectedCoverImage: any;
	public Editor = ClassicEditor;
	public profileData = '';
	public model = { bios: '', message: '', video: '' };
	public socialMediaForm: any;
	private user: any;
	public customFlag: true;
	public inputFlags: any;
	public flags: any;
	private newProfile = false;
	public canvas: ElementRef;
	private oldUrl: any;
	public url: any;
	public oldUser = false;
	public errorAlias: any;
	public errorPicture: any;
	public errorSubmit: any;
	public imageChangedEvent: any = '';
	public croppedImage: any = '../../assets/no-avatar.png';
	public croppedCoverImage: any = '../../assets/cover.jpg';
	public values = [30, 50, 100, 150]
	private errorCpf = false;
	@ViewChild('fileInput') fileInput: ElementRef;

	// public isCandidate: any;
	constructor(
		public formBuilder: FormBuilder,
		public userService: UserService,
		public urnaService: UrnaService,
		public dialog: MatDialog,
		private httpService: HttpService,
		public authProvider: AuthService,
		@Inject(ENV) public ENV: any,
		public utils: UtilsProvider,
		private router: Router,
		private candidateProvider: CandidateService,
	) { }

	ngOnInit(): void {
		window.scrollTo(0, 0)
		console.log(localStorage.getItem('user'));
		if (!localStorage.getItem('user')) {
			this.router.navigate(['/entrar']);
		}
		this.ENV.active = 'profile'
		const self = this;
		const _id = new FormControl('', Validators.compose([]));
		const username = new FormControl('', Validators.compose([Validators.required]));
		const email = new FormControl('', Validators.compose([Validators.required]));
		const password = new FormControl('', Validators.compose([]));
		const confirmPassword = new FormControl('', Validators.compose([]));
		// const confirmPassword = new FormControl('', Validators.compose([
		// 	Validators.required,
		// 	RxwebValidators.compare({ fieldName: 'password' }),
		// ]));
		const cpf = new FormControl('', Validators.compose([Validators.required]));
		const _user = JSON.parse(localStorage.getItem('user'));
		// console.log("aaaaaa", _user)
		this.user = _user.user;

		// this.getProfile();
		this.oldUser = _user.user.cpf ? false : true,
			this.profileForm = [
				{
					fields: [
						{
							name: '_id',
							type: 'hidden',
							cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
							blurCallback: () => { }
						},
						{
							name: 'username',
							label: 'Nome',
							placeholder: 'Nome',
							// readonly: true,
							type: 'text',
							cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
							blurCallback: () => { }
						},
						{
							name: 'cpf',
							type: 'mask',
							mask: { mask: "000.000.000-00", type: "num", len: 14 },
							minlength: 14,
							maxlength: 14,
							label: 'CPF',
							placeholder: 'CPF (Apenas números)',
							cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
							// readonly: _user.user.cpf ? true : false,
							blurCallback() {
								if (cpf.valid) {
									// cpf.value.replace(/[\.\-]*/gi, "")
									const val = cpf.value.replace(/[\.\-]*/gi, '');
									self.profileForm[0].builder.controls.cpf.value = val;
									if (!self.utils.cpfVerify(val)) {
										self.utils.toast({
											message: 'CPF inválido, verifique os dados inseridos.',
											action: 'Ok',
											duration: 10000
										});
										self.errorCpf = true;
									} else {
										self.errorCpf = false;
									}
								}
							}
						},
						{
							name: 'email',
							label: 'E-mail',
							placeholder: 'E-mail',
							type: 'text',
							disabled: true,
							readonly: true,
							cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
							blurCallback: () => { }
						},
						{
							name: 'password',
							label: 'Senha',
							type: 'password',
							autocomplete: "new-password",
							placeholder: 'Alterar Senha',
							cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
							blurCallback: () => { }
						},
						{
							name: 'confirmPassword',
							type: 'password',
							placeholder: 'Confirme sua senha',
							cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
							blurCallback() { }
						},
					],
					builder: this.formBuilder.group({
						_id,
						username,
						cpf,
						email,
						password,
						confirmPassword,
					}),
					validation_message: {
						_id: [],
						username: [],
						email: [],
						password: [],
						cpf: [],
						confirmPassword: [],
					}
				}
			];
		this.getUserInfo();
	}

	ngOnDestroy() {
		this.ENV.admin = false;
	}

	getUserInfo() {
		const _user = JSON.parse(localStorage.getItem('user'));
		console.log('USER: ', _user);
		console.log('CANDIDATO: ', _user.user.role && _user.user.role.descricao && String(_user.user.role.descricao) === 'candidato');
		// const defaultImg = '../../assets/no-avatar.png';
		// const img = _user && _user.media ? _user.user.media[0] : defaultImg;
		console.log('USERRR', _user.user);

		this.profileForm[0].builder.patchValue({ ..._user.user });
		// if (!_user || _user.user || !_user.user.media || _user.user.media.length === 0) { this.imageUrl = img.host && img.full ? img.host + img.full : defaultImg; }
		// if (String(_user.user.role.descricao) === 'candidato') {
		// 	this.urnaService.getOne( { userRef: _user.user._id }).then( async (result) => {
		// 		console.log(result);
		// 		const alias = result.urna[0].alias;
		// 		this.oldUrl = alias;
		// 		this.url = alias;
		// 		console.log('OLDURL', this.oldUrl);
		// 		this.profileForm[0].builder.patchValue({
		// 			url: alias
		// 		});
		// 	}).catch(err => {
		// 		console.log(err);
		// 	});
		// } else if (String(_user.user.role.descricao) === 'doador' || String(_user.user.role.descricao) !== 'candidato') {
		// 	this.profileForm[0].builder.controls.url.type = 'hidden';
		// }

	}

	isCandidate() {
		const _user = JSON.parse(localStorage.getItem('user'));
		if (_user.user.role && _user.user.role.descricao && String(_user.user.role.descricao) === 'candidato') { return true; }
		return false;
	}

	onFileSelected(file: FileList) {
		this.fileToUpload = file.item(0);
		const reader = new FileReader();
		reader.onload = (event: any) => {
			this.imageUrl = event.target.result;
		};
		reader.readAsDataURL(this.fileToUpload);
	}

	onFileSelectedCover(file: FileList) {
		this.coverToUpload = file.item(0);
		const reader = new FileReader();
		reader.onload = (event: any) => {
			this.coverUrl = event.target.result;
		};
		reader.readAsDataURL(this.coverToUpload);
	}

	async getProfile() {
		const userObj = JSON.parse(localStorage.getItem('user'));
		const [errGetProfile, profile] = await this.utils.tryCatch(
			this.candidateProvider.getProfiles({ user: userObj.user._id, all: false })
		);
		if (errGetProfile) {
			return this.utils.toast({
				message: 'Não foi possível buscar por dados do candidato, tente novamente mais tarde.',
				action: 'Ok',
				duration: 8000
			});
		}
		if (profile.length === 0) {
			this.newProfile = true;
			return false;
		}
		console.log('PROFILE: ', profile[0]);
		if (profile[0].bios) { this.model.bios = profile[0].bios; }
		if (profile[0].message) { this.model.message = profile[0].message; }
		if (profile[0].video) { this.model.video = profile[0].video }
		if (profile[0].values && profile[0].values[0]) { this.values = profile[0].values}
		if (profile[0].cover) {
			const cover = profile[0].cover;
			this.croppedCoverImage = cover.host + cover.full;
		} else {
			this.croppedCoverImage = '../../assets/cover.jpg'
		}
		if (profile[0] && profile[0].user && profile[0].user.media && profile[0].user.media.length > 0) {
			const media = profile[0].user.media[0];
			this.croppedImage = media.host + media.full;
		} else if (userObj.user.media) {
			this.croppedImage = userObj.user.media.host + userObj.user.media.full;
		} else {
			this.croppedCoverImage = '../../assets/no-avatar.png'
		}
		let flagss = [];
		if (profile[0].flags && profile[0].flags.length > 0) {
			this.customFlag = true;
			flagss = profile[0].flags.map(el => {
				return {
					_id: el,
					title: el
				};
			});
		}
		console.log('FINAL FLAGS: ', flagss);
		this.socialMediaForm[0].builder.patchValue({ ...profile[0].socialMedias, video: this.model.video });
		// this.socialMediaForm[0].builder.patchValue({
		// 	flags: profile[0].flags
		// })

		if (profile[0].flags) { this.inputFlags = profile[0].flags; }
	}

	treatCustomFlags() {
		const flags = String(this.inputFlags);
		const flagArray = flags.split(',');

		const newFlags = [];
		for (const f of flagArray) {
			if (f.length > 0) {
				newFlags.push({
					_id: f,
					title: f
				});
			}
		}
		console.log(newFlags);
		return newFlags;
	}


	onSubmitProfile() {
		return new Promise<void>(async (resolve, reject) => {
			const socialMedias = { ...this.socialMediaForm[0].builder.value };
			const video = socialMedias.video
			delete socialMedias.video
			const flags = socialMedias.flags ? socialMedias.flags : [];
			if (this.inputFlags && this.inputFlags.length > 0) {
				const newFlags = this.treatCustomFlags();
				for (const flag of newFlags) {
					flags.push(flag);
				}
				console.log('flags', flags);
			}
			delete socialMedias.flags;
			console.log('HAS SELECTED MEDIA: ', this.selectedImage);
			const data = {
				bios: this.model.bios,
				message: this.model.message ? this.model.message : null,
				socialMedias,
				values: this.values.sort((a, b) => a - b),
				newProfile: this.newProfile,
				flags,
				video,
				media: this.selectedImage ? this.selectedImage : undefined,
				cover: this.selectedCoverImage ? this.selectedCoverImage : undefined
			};
			console.log('FINAL DATA: ', data);
			const [errSubmit, res] = await this.utils.tryCatch(
				this.candidateProvider.createOrUpdate(data)
			);
			if (errSubmit) {
				return this.utils.toast({
					message: 'Não foi possível atualizar dados do usuário, tente novamente mais tarde!',
					action: 'Ok',
					duration: 8000
				});
			}
			resolve();
		});
	}

	/*saveProfile() {
		new Promise(async (resolve, reject) => {
			if (this.fileToUpload) { await this.uploadImage(); }
			else { await this.onSubmitProfile(); }
		})
	}

	validData() {
		const socialMedias = { ...this.socialMediaForm[0].builder.value };
		const validForm = Object.keys(socialMedias).some(el => socialMedias[el].length > 0);
		if (this.fileToUpload || validForm || this.model.bios.length > 0 || !this.errorAlias) {
			return false;
		}
		return true;
	}*/

	fileChangeEvent(event: any): void {
		const dialogRef = this.dialog.open(ImageCropModalComponent, {
			autoFocus: false,
			data: { event: event, aspect: 1 / 1 }
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.croppedImage = result.image;
				this.fileToUpload = result.image, "profilepic"
				console.log("FILEEEEE: ", this.fileToUpload)
			}
		});
	}

	fileChangeCoverEvent(event: any): void {
		const dialogRef = this.dialog.open(ImageCropModalComponent, {
			autoFocus: false,
			data: { event: event, aspect: 16 / 9 }
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.croppedCoverImage = result.image;
				this.coverToUpload = result.image
			}
		});
	}

	imageCropped(event: ImageCroppedEvent) {
		this.croppedImage = event.base64;
		console.log("BASE64: ", this.croppedImage)
	}

	imageLoaded() {
		// show cropper
	}

	cropperReady() {
		// cropper ready
	}

	loadImageFailed() {
		// show message
	}

	getBase64(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error);
			console.log('teste', reader);
		});
	}

	// uploadImage(data: any) {
	// 	return new Promise(async (resolve, reject) => {
	// 		const [err, res] = await this.utils.tryCatch(this.userService.profileAvatar({ photo: data }));
	// 		if (err) {
	// 			this.utils.toast({
	// 				message: "Não foi possível realizar o upload de imagem, tente novamente mais tarde!",
	// 				action: "Ok",
	// 				duration: 8000
	// 			});
	// 			return reject(err);
	// 		}
	// 		return resolve(res);
	// 	}).catch(err => {
	// 		return this.utils.toast({
	// 			message: "Não foi possível realizar o upload de imagem, tente novamente mais tarde!",
	// 			action: "Ok",
	// 			duration: 8000
	// 		});
	// 	});
	// }


	editPassword() {
		// const dialogRef = this.dialog.open(ModalChangePasswordComponent);
		// dialogRef.afterClosed().subscribe(result => {
		// 	console.log(result);
		// });
	}
	visualizar() {
		window.open('/' + this.profileForm[0].builder.controls.url.value, '_blank');
	}
	async onSubmit(form) {

		if (this.errorCpf === true) {
			return this.utils.toast({
				message: 'CPF inválido, por favor digite um CPF válido.',
				action: 'Ok',
				duration: 10000
			});
		}

		// if (!this.profileForm[0].builder.valid) {
		// 	return this.utils.toast({
		// 		message: 'Por favor, preencha o formulário corretamente',
		// 		action: 'Ok',
		// 		duration: 8000
		// 	});
		// }

		const _user = JSON.parse(localStorage.getItem('user'));
		// const role = _user.user.role._id ? _user.user.role._id : _user.user.role;
		// const admin = _user.user.cpf ? false : true;
		const cpf = this.profileForm[0].builder.controls.cpf.value.replaceAll("-", "").replaceAll(".", "")
		const data = { ...this.profileForm[0].builder.value, cpf };
		// console.log("DADOS QUE ESTAO SNEDO ENVIADOS", data )
		this.userService.editProfile(data).then((result: any) => {
			this.authProvider.me();
			// localStorage.setItem('urna', this.profileForm[0].builder.controls.url.value);
			return this.utils.toast({
				message: 'Perfil atualizado com sucesso!',
				action: 'Ok',
				duration: 8000
			});
		}).catch((err: any) => {
			console.log('erro', err);
			return this.utils.toast({
				message: 'Não foi possível atualizar dados do usuário, tente novamente mais tarde!',
				action: 'Ok',
				duration: 8000
			});
		});
	}
}
