<div class="all-content">
    <div class="row center-lg center-md content" fxFlex="50" wmAnimate="landing" speed="slow" aos>
        <div class="col-xs-12 col-sm-12 col-md-11 col-lg-11">
            <div class="row component-container">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <div class="box">
                        <mat-card class="image-card">
                            <div>
                                <div class="saque">
                                    <p>Dinheiro disponível para saque imediato</p>
                                    <div class="value">
                                        <h1>{{ availableValue | currency : "R$"}}</h1>
                                        <button *ngIf="availableValue > 0" (click)="openDialogTransfer()" mat-flat-button class="btn1 default-btn ">RETIRAR VALOR</button>
                                    </div>
                                </div>
                                <mat-divider class="margin"></mat-divider>
                                <div class="total">
                                    <p>Antecipar <b>valor total</b></p>
                                    <div class="value">
                                        <h1>{{ totalValue  | currency : "R$"}}</h1>
                                        <button *ngIf="diffValue > 0" (click)="openDialogTransfer(true)" mat-flat-button class="btn2 default-btn ">ANTECIPAR VALOR</button>
                                    </div>
                                    <p>+ Disponível: {{ availableValue  | currency : "R$"}}</p>
                                    <p>+ A receber: {{ diffValue | currency : "R$" }}</p>
                                    <p>- Taxa Antecipação (3%): <b>{{taxValue | currency : "R$"}}</b></p>
                                    <br><hr><br>
                                    <p>Valor bruto arrecadado: <b>{{grossValue | currency : "R$"}}</b></p>
                                    <p>Valor liquido arrecadado: <b>{{liquidValue | currency : "R$"}}</b></p>
                                </div>
                            </div>
                        </mat-card>
                        <!-- <mat-card class="image-card">
                                                                <img mat-card-image class="image" style="margin-bottom: 0;"
                                        src="../../assets/image/usemaquina.jpg" />
                            <h1>
                                    Receba com maquininha!
                            </h1>
                            <p>Assine nossa solução para doações presenciais, <b>com os melhores valores e taxas do mercado</b>, e conte com a comodidade das maquininhas.
                            </p>
                            <button class="redirect-button" mat-flat-button [routerLink]="'/contato'">saiba mais</button>
                        </mat-card> -->
                        <!-- <div class="row">
                            <div #ajuda class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <mat-card class="container-cd">
                                    <div class="form">
                                        <div #cadastro class="header">
                                            <h3>
                                                Solicite a maquininha
                                            </h3>
                                        </div>
                                        <img src="../../assets/qa-maquininha.png">
                                        <div class="btn-container">
                                            <button #enviar (click)="openDialog()" mat-flat-button class="btn">Peça
                                                Agora!</button>
                                            <button #enviar mat-flat-button class="btn second-btn">Mais
                                                informações</button>
                                        </div>
                                    </div>
                                </mat-card>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div #duvidas class="col-xs-12 col-sm-12 col-md-6 col-lg-6">

                    <div #taxas class="row">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <mat-card class="container-cd">
                                <h4>
                                    Últimas <span>movimentações</span><br>
                                </h4>
                                <mat-divider></mat-divider>
                                <mat-list>
                                    <mat-list-item *ngIf="!donationsList || !donationsList[0]">
                                        <p>Nenhuma movimentação até agora</p>
                                    </mat-list-item>
                                    <mat-list-item *ngFor="let donate of donationsList">
                                        <div class="item" *ngIf="!donate.transfer">
                                            {{ getName(donate) }}
                                            <span class="money">R${{ getValue(donate) }}</span><br>
                                        </div>
                                        <div class="item" *ngIf="donate.transfer">
                                            <strong *ngIf="donate.status=='realizado'"> Saque efetivado </strong>
                                            <strong *ngIf="donate.status=='pendente'"> Saque em análise </strong>
                                            <strong *ngIf="donate.status=='recusado'"> Saque recusado </strong>
                                            <span class="transfer">-R${{ getValue(donate) }}</span><br>
                                        </div>
                                        <!-- mat-divider></mat-divider -->
                                    </mat-list-item>
                                </mat-list>
                            </mat-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>