import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { UtilsProvider } from 'src/commons/utils';
import { CandidateService } from 'src/providers/candidate.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Router } from '@angular/router';

@Component({
	selector: 'app-candidate-profile',
	templateUrl: './candidate-profile.component.html',
	styleUrls: ['./candidate-profile.component.scss']
})
export class CandidateProfileComponent implements OnInit {
    public Editor = ClassicEditor;
    public profileData = '';
    public model = {bios: '', message: ''};
    public socialMediaForm: any;
    private user: any;
    private newProfile = false;
    private fileToUpload: File = null;
    private selectedImage: any;
    public canvas: ElementRef;
	public imageUrl = '../../assets/no-avatar.png';
	private url: any;
	@ViewChild('fileInput') fileInput: ElementRef;

	constructor(
		private utils: UtilsProvider,
		private formBuilder: FormBuilder,
		private candidateProvider: CandidateService,
		private router: Router,
	) { }

	ngOnInit(): void {
		window.scrollTo(0,0);
		if (!localStorage.getItem('user')) {
			this.router.navigate(['/entrar']);
		}
		const _user = JSON.parse(localStorage.getItem('user'));
		this.user = _user.user;
		const flags =  [
			{
				_id: 'segurança',
				title: 'segurança',
			},
			{
				_id: 'educação',
				title: 'educação',
			},
			{
				_id: 'saúde',
				title: 'saúde',
			},
			{
				_id: 'direitos dos animais',
				title: 'direitos dos animais',
			},
			{
				_id: 'direitos da mulher',
				title: 'direitos da mulher',
			}
		];
  this.socialMediaForm = [
            {
                fields: [
                    {
                        name: 'facebook',
                        label: 'Facebook',
                        type: 'text',
                        placeholder: 'URL',
                        blurCallback() { }
                    },
                    {
                        name: 'instagram',
                        label: 'Instagram',
                        type: 'text',
                        placeholder: 'URL',
                        blurCallback() { }
                    },
                    {
                        name: 'twitter',
                        label: 'Twitter',
                        type: 'text',
                        placeholder: 'URL',
                        blurCallback() { }
                    },
                    {
                        name: 'youtube',
                        label: 'Youtube',
                        type: 'text',
                        placeholder: 'URL',
                        blurCallback() { }
					},
					{
						name: 'flags',
						type: 'multiselect',
						cols: 'col-lg-12 col-md-12 col-sm-12',
						label: 'Bandeiras Defendidas',
						enableCheckAll: true,
						selectAllText: 'Marcar todas',
      					unSelectAllText: 'Desmarcar todas',
						placeholder: 'Selecione suas bandeiras',
						data: flags,
						change: (event, item) => {
							console.log('selected user types', item);
						},
						control: this,
					},
                ],
                builder: this.formBuilder.group({
                    facebook: ['', null],
                    instagram: ['', null],
                    twitter: ['', null],
					youtube: ['', null],
					flags: ['', null]
                }),
                validation_messages: {
                    facebook: [],
                    instagram: [],
                    twitter: [],
					youtube: [],
					flags: []
                }
            }
        ];

  this.getProfile();
	}

	async getProfile() {
        const userObj = JSON.parse(localStorage.getItem('user'));
        const [errGetProfile, profile] = await this.utils.tryCatch(
            this.candidateProvider.getProfiles({ user: userObj.user._id,  all: false})
        );
		      if (errGetProfile) {
			return this.utils.toast({
				message: 'Não foi possível buscar por dados do candidato, tente novamente mais tarde.',
				action: 'Ok',
				duration: 8000
			});
		}
        if (profile.length === 0) {
            this.newProfile = true;
            return false;
		}

		      console.log('PROFILE: ', profile[0]);
		      if (profile[0].bios) { this.model.bios = profile[0].bios; }
		      if (profile[0].message) { this.model.message = profile[0].message; }
        if (profile[0] && profile[0].user && profile[0].user.media && profile[0].user.media.length > 0) {
            const media = profile[0].user.media[0];
            this.imageUrl = media.host + media.full;
        } else if (userObj.user.media) {
            this.imageUrl = userObj.user.media.host + userObj.user.media.full;
		}
		      let flags = [];
		      if (profile[0].flags && profile[0].flags.length > 0) {
			flags =	profile[0].flags.map(el => {
				return {
					_id: el,
					title: el
				};
			});
		}
		      console.log('FINAL FLAGS: ', flags);
		      this.socialMediaForm[0].builder.patchValue({...profile[0].socialMedias, flags});
		      this.socialMediaForm[0].builder.controls.value = flags;
	}

	async onSubmit() {
		const socialMedias = {...this.socialMediaForm[0].builder.value};
		const flags = socialMedias.flags ? socialMedias.flags : [];
		delete socialMedias.flags;
		console.log('HAS SELECTED MEDIA: ', this.selectedImage);
  const data = {
			bios: this.model.bios,
			message: this.model.message ? this.model.message : null,
            socialMedias,
			newProfile: this.newProfile,
			flags,
            media: this.selectedImage ? this.selectedImage : undefined,
		};
		console.log('FINAL DATA: ', data);
  const [errSubmit, res] = await this.utils.tryCatch(
            this.candidateProvider.createOrUpdate(data)
        );
  if (errSubmit) {
			return this.utils.toast({
				message: 'Não foi possível atualizar os dados do perfil, por favor, tente novamente mais tarde.',
				action: 'Ok',
				duration: 8000
			});
		}
  return this.utils.toast({
			message: 'Perfil atualizado com sucesso!',
			action: 'Ok',
			duration: 8000
		});
	}

    onFileSelected(file: FileList) {
        this.fileToUpload = file.item(0);
        const reader = new FileReader();
        reader.onload = (event: any) => {
            this.imageUrl = event.target.result;
        };
        reader.readAsDataURL(this.fileToUpload);
    }

	async uploadImage() {
		console.log('uploadImage.....');
  const [err, res] = await this.utils.tryCatch(this.candidateProvider.uploadImage({ file: this.imageUrl }));
		if (err) {
			return this.utils.toast({
				message: 'Ocorreu um erro ao realizar o upload de imagem, tente novamente mais tarde.',
				action: 'Ok',
				duration: 8000
			});
		}
		console.log('RESPONSE: ', res);
  const savedImg = res && res.image ? res.image._id : undefined;
		this.selectedImage = savedImg;
		console.log('SELECTED IMAGE: ', this.selectedImage);
  this.onSubmit();
    }

    saveProfile() {
        if (this.fileToUpload) { this.uploadImage(); }
        else { this.onSubmit(); }
    }

    validData() {
        const socialMedias = {...this.socialMediaForm[0].builder.value};
        const validForm = Object.keys(socialMedias).some(el => socialMedias[el].length > 0);
        if (this.fileToUpload || validForm || this.model.bios.length > 0) {
            return false;
        }
        return true;
	}
	visualizar(){

	}
}
