import { PaymentService } from 'src/providers/payments.service';
import { PagseguroService } from './../../providers/pagseguro.service';
import { MatStepper } from '@angular/material/stepper';
import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { MatButton } from '@angular/material/button';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { DomainProvider } from 'src/providers/domains';
import { MaquininhaService } from 'src/providers/maquininha.service';
import { ENV } from 'src/environments/environment-variables.token';
import { UtilsProvider } from 'src/commons/utils';
import { Router } from '@angular/router';
import { UserService } from 'src/providers/user.service';
declare const PagSeguro;



@Component({
  selector: "modal-cpf-form",
  templateUrl: "./modal-cpf-form.component.html",
  styleUrls: ["../app.component.scss", "./modal-cpf-form.component.scss"]
})
export class ModalCpfFormComponent implements OnInit {

  public title: string;
  public content: string;
  public event: any;	
public formPersonal: any;
  public state: any;
  public terms = false;
  public validCPF = false;
  public isValidPersonalInfos = false;

	public sessionId: any;
	public hash: any;

	public imageUrl = '../../assets/no-avatar.png';
	public userDataSocialMedia: any;
	public donateValue: any;
	userMedias = {};
	public donationValue: string;
	public userNotFound = false;
	public flags = [];
	public candidateName: any;
	public candidato: any;
	public valor = '0,00';
	public barcode: any;
	public BankSlipUrl: any;
	public creditComplete = false;
	public debitComplete = false;
	public isDisabled = false;
	public completed = false;
	public messageDebit: any;
	public urlDebit: any;
	public paymentError: any;
	public lat = -15.7801;
	public long = -47.9292;
	public coverImage: any;
	public darkStyle: any;
	public candidateUrna: any;
	public urlBankSlipGenerated = false;




  constructor(
    public formBuilder: FormBuilder,
    private domain: DomainProvider,
	private router: Router,
    private userService: UserService,
    public utils: UtilsProvider,
	public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ModalCpfFormComponent>,
		public pagseguroService: PagseguroService,
		private paymentService: PaymentService,

    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(ENV) public ENV: any) { }

  ngOnInit() {
    const self = this;
    this.createFormPersonal();
    const _user = JSON.parse(localStorage.getItem('user'));
  }

	createFormPersonal() {
		const self = this;
		const CPF = new FormControl('', Validators.compose([Validators.required, Validators.minLength(14), Validators.maxLength(14), Validators.pattern('[0-9]{3}\\.[0-9]{3}\\.[0-9]{3}\\-[0-9]{2}')]));
		this.formPersonal = [
			{
				fields: [
					{
						name: 'CPF',
						label: 'CPF',
						mask: { mask: '000.000.000-00', type: 'num' },
						maxlength: 14,
						type: 'mask',
						placeholder: 'CPF:XXX.XXX.XXX-XX',
						cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
						blurCallback() {
							if (CPF.valid) {
								const val = CPF.value.replace(/[\.\-]*/gi, '');
								if (!self.utils.cpfVerify(val)) {
									self.utils.toast({ message: 'CPF inválido, verifique os dados inseridos' });
									self.validCPF = false;
								} else {
									self.validCPF = true;
								}
							}
						}
					},
				],
				builder: this.formBuilder.group({
					CPF
				}),
				validation_messages: {
					CPF: [
						{ type: 'required', message: 'Preencha o campo CPF.' },
						{ type: 'minlength', message: 'O CPF deve conter 11 digitos.' },
						{ type: 'maxlength', message: 'O CPF deve conter 11 digitos.' },
						{ type: 'pattern', message: 'O CPF deve conter somente números.' }
					],
				},
			},
		];
		const loggedUser = localStorage.getItem('user');
		if (loggedUser) {
			console.log('loggedUser', loggedUser);
			this.formPersonal[0].builder.patchValue(JSON.parse(loggedUser).user);
		}
	}

  close() {
    this.matDialogRef.close()
  }

  onSubmit() {
    if (!this.isValidPersonal()) {
      return this.utils.toast({
        message: 'Por favor, preencha o formulário corretamente.',
        action: 'OK',
        duration: 10000
      });
    } else {
      const _user = JSON.parse(localStorage.getItem('user'));
      this.userService.updateCPF({
		_id: _user.user._id,
        cpf: this.formPersonal[0].builder.controls.CPF.value.replace(/\D/g, ""),
      }).then(success => {
        this.utils.toast({
          message: 'CPF Salvo com sucesso!',
          action: 'SUCESSO',
          duration: 10000
        });
        return this.matDialogRef.close()
      }).catch(err => {
          this.utils.toast({
            message: "Ocorreu um erro, tente novamente mais tarde.",
            action: 'OK',
            duration: 10000
          });
      })

    }
  }


  isValidPersonal() {
		if (this.validCPF) {
				return true;
		}
		return false;
	}
}
