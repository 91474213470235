import { Component, OnInit } from "@angular/core";
import { Validators, FormBuilder, FormControl } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "src/providers/auth.service";
import { RxwebValidators } from "@rxweb/reactive-form-validators";
import { MatDialog } from "@angular/material/dialog"
import { ModalDefaultComponent } from '../modal-default/modal-default.component';
import { MatButton } from '@angular/material/button';
import { UtilsProvider } from 'src/commons/utils';
@Component({
	selector: "app-forget-email",
	templateUrl: "./forget-email.component.html",
	styleUrls: ["./forget-email.component.scss", "../app.component.scss"]
})
export class ForgetEmailComponent implements OnInit {

	public forgotEmailForm: any;
	public forgotEmailFormStep: any;
	public isDisabled = false;
	constructor(
		public utils: UtilsProvider,
		public router: Router,
		public formBuilder: FormBuilder,
		public auth: AuthService,
		public dialog: MatDialog
	) { }

	ngOnInit() {
		window.scrollTo(0,0)
		const cpf = new FormControl('', Validators.compose([Validators.required]));
		this.forgotEmailFormStep = 0;
		this.forgotEmailForm = [
			{
				fields: [
					{
						name: 'cpf',
						type: 'mask',
						placeholder: 'CPF (Apenas Números)',
						mask: { mask: "000.000.000-00", type: "num", len: 14 },
						minlength: 14,
						maxlength: 14,
						cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
						blurCallback() {
							cpf.value.replaceAll(".", "").replaceAll("-", "")
						}
					},
				],
				builder: this.formBuilder.group({
					cpf,
				}),
				validation_messages: {
					cpf: [
						{ type: "required", message: "Preencha o CPF." },
					],
				},
			},
		];
	}

	clean() {
		this.forgotEmailForm[0].builder.reset();
	}

	performClick(el: MatButton) {
        el._elementRef.nativeElement.click()
        console.log(this.isDisabled)
    }

	onSubmit(event) {
		if (!this.forgotEmailForm[0].builder.valid) {
            // this.utils.showMessagem("Erro", "Preencha corretamente o formulário");
			console.log("aaaaa", this.forgotEmailForm[0].builder.valid)
			console.log("aaaaa", !this.forgotEmailForm[0].builder.valid)
            console.log("a")
			return;
		}
        const data = { ...event[0].builder.value };
        console.log("b")
		console.log("data", data)
		
		this.isDisabled = true;
		this.auth.forgotEmail({"cpf": data.cpf.replaceAll(".", "").replaceAll("-", "")}).then((res) => {
			this.isDisabled = false
            this.clean();
            console.log("aaa" + res)
			if (res !== "") { this.router.navigate(["/entrar"]) } else { console.log("(this should be a toast) invalid user"); }
		}).catch(err => {
			this.isDisabled = false
		});
	}

	checkDisabled() {
		return this.isDisabled
	}

	openDialog() {
		const dialogRef = this.dialog.open(ModalDefaultComponent, { data: { message: "aaaa"}});
		dialogRef.afterClosed().subscribe((res) => {
			this.router.navigate(["/login"])
		})
	}
}
