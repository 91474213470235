<div>
  <div class="card">
    <div class="container">
      <div class="header">
        <img class="logo" src="../../assets/logo/logo.svg" />
        <h2> {{ data.title }} </h2>
      </div>
      <div class="form">
        <div class="normal-text" [innerHTML]="data.message"></div>
      </div>
    </div>
  </div>
  <div class="btn-container">
    <button mat-flat-button class="btn1"  [mat-dialog-close]="'false'">Cancelar</button>
    <button mat-flat-button #register class="btn2"  [mat-dialog-close]="'true'">{{ data.message.startsWith("Vaquinhas eleitorais") ? 'Pagar': 'Continuar' }}</button>
  </div>
</div>
