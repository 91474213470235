import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { RxwebValidators } from "@rxweb/reactive-form-validators";
import { HttpService } from 'src/providers/http.service';
import { UtilsProvider } from 'src/commons/utils';
import { MatButton } from '@angular/material/button';

@Component({
	selector: 'app-contato',
	templateUrl: 'contato.component.html',
	styleUrls: ['contato.component.scss']
})
export class ContatoComponent implements OnInit {

	public contatoForm: any;
	public isDisabled = false;
	public contatoFormStep: any;
	public assuntos = [
		{
			"title": "Dificuldades Técnicas",
		},
		{
			"title": "Dúvidas Sobre a Maquininha",
		},
		{
			"title": "Financeiro",
		},
		{
			"title": "Parcerias",
		},
		{
			"title": "Indicações",
		}]

	constructor(
		public router: Router,
		public formBuilder: FormBuilder,
		public http: HttpService,
		public utils: UtilsProvider
		// public auth: AuthService,
		// public utils: UtilsProvider
	) { }

	ngOnInit() {
		window.scrollTo(0,0)
		const email = new FormControl("", Validators.compose([Validators.required, Validators.email]));
		const name = new FormControl("", Validators.compose([Validators.required]));
		const assunto = new FormControl("", Validators.compose([Validators.required]));
		const message = new FormControl("", Validators.compose([Validators.required]));
		this.contatoFormStep = 0;
		this.contatoForm = [
			{
				fields: [
					{
						name: "name",
						type: "text",
						placeholder: "Nome Completo",
						cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
						blurCallback() { }
					},
					{
						name: "email",
						type: "email",
						placeholder: "E-mail",
						cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
						blurCallback() { }
					},
					{
						name: 'assunto',
						type: 'select',
						cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
						placeholder: 'Assunto',
						options: {
							fields: {
								value: 'title',
								text: 'title'
							},
							items: this.assuntos
						},
						clickCallback: (data) => {
						}
					},
					{
						name: "message",
						type: "textarea",

						placeholder: "Digite a sua mensagem",
						cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
						blurCallback() { }
					}
				],
				builder: this.formBuilder.group({
					name,
					email,
					assunto,
					message
				}),
				validation_messages: {
					name: [
						{ type: "required", message: "Preencha o campo nome." },
					],
					email: [
						{ type: "required", message: "Preencha o campo e-mail." },
					],
					assunto: [
						{ type: "required", message: "Preencha o campo assunto." },
					],
					message: [
						{ type: "required", message: "Digite uma mensagem." },
					],
				},
			},
		];
	}

	clean() {
		this.contatoForm[0].builder.reset();
	}

	performClick(el: MatButton) {
		el._elementRef.nativeElement.click()
	}

	async onSubmit(event) {
		this.isDisabled = true
		if (!this.contatoForm[0].builder.valid) {
			return this.utils.toast({
				message: 'Por favor, preencha corretamente o formulário.',
				action: 'Ok',
				duration: 10000
			});

		}
		const data = { ...event[0].builder.value };

		try {
			await this.http.post("api/faleConosco/sendContactEmail", false, data)
			this.utils.toast({
				message: 'Mensagem enviada com sucesso! Entraremos em contato em breve.',
				action: 'Ok',
				duration: 10000
			});
			this.isDisabled = false
			//this.contatoForm[0].builder.reset();
		} catch (err) {
			console.log(err)
			this.utils.toast({
				message: 'Não foi possível enviar sua mensagem, por favor verifique seus dados ou tente novamente mais tarde.',
				action: 'Ok',
				duration: 10000
			});
			this.isDisabled = false
		}
	}
}
