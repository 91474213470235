<div style="height: 100%; display: grid; grid-template-rows: 75px auto 60px;">
  <default-header *ngIf="!ENV.admin"></default-header>
  <default-header-admin *ngIf="ENV.admin"></default-header-admin>
  <div>

    <router-outlet></router-outlet>
    <ngx-loading [show]="ENV.loading" [config]="" [template]="customLoadingTemplate"></ngx-loading>

  </div>
  <default-footer></default-footer>
</div>
 <ng-template #customLoadingTemplate>
    <div class="custom-class">
      <div id="parcel"><img class="porquinho" src="../assets/porquinho5.png"></div>
    </div>
  </ng-template>
