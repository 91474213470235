<div class="donations-component">
    <div class="">
        <div class="donations-law">
            <p class="little">Conforme a resolução do TSE 23.553, Art. 23 . II – identificação obrigatória, com o nome
                completo e o número de inscrição no cadastro de pessoas físicas (CPF) de cada um dos doadores, o valor
                das quantias doadas individualmente, forma de pagamento e as datas das respectivas doações. Precisamos
                colocar todos esses dados já que sem o CPF poderiam haver homônimos. Empresas de financiamento coletivo
                que não respeitarem esta norma terão problemas com a justiça eleitoral.</p>
        </div>
        <div class="donations-table-options col-md-12 col-sm-12 col-sm-12">
            <table class="donations-table">
                <tr>
                    <th>Nome</th>
                    <th>CPF</th>
                    <th>Email</th>
                    <th>Telefone</th>
                    <th>Valor Total</th>
                    <th></th>
                </tr>
                <tr *ngFor="let i of donationsList">
                    <td class="name">{{ getName(i) }}</td>
                    <td>{{ i.donatorInfo.Identity }}</td>
                    <td>{{ i.donatorInfo.Email }}</td>
                    <td>{{ i.donatorInfo.Phone }}</td>
                    <th>R${{ i.donationValue }}</th>
                </tr>
            </table>
        </div>
    </div>
</div>