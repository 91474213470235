import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root',
})
export class MediaService {
  constructor(private http: HttpService, private authService: AuthService) {}

  uploadImage(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/mediaV2/create/uploadImage', true, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }
}
