import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ENV } from 'src/environments/environment-variables.token';
import { Router } from '@angular/router';
import { AuthService } from 'src/providers/auth.service';
import { HttpService } from 'src/providers/http.service';
import { UtilsProvider } from 'src/commons/utils';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loginForm: any;
  public loginFormStep: any;
  public isDisabled = false;

  constructor(
    public router: Router,
    public formBuilder: FormBuilder,
    public auth: AuthService,
    public http: HttpService,
    public utils: UtilsProvider,
    @Inject(ENV) public ENV: any
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    console.log(this.ENV);
    // if(localStorage.getItem("user")) {
    //     this.router.navigate(['/']);
    // }
    const email = new FormControl(
      '',
      Validators.compose([Validators.required, Validators.email])
    );
    const password = new FormControl(
      '',
      Validators.compose([Validators.required])
    );
    this.loginFormStep = 0;

    this.loginForm = [
      {
        fields: [
          {
            name: 'email',
            type: 'email',
            placeholder: 'E-mail',
            blurCallback() {},
          },
          {
            name: 'password',
            type: 'password',
            placeholder: 'Senha',
            blurCallback() {},
          },
        ],
        builder: this.formBuilder.group({
          email,
          password,
        }),
        validation_messages: {
          email: [{ type: 'required', message: 'Preencha o seu e-mail.' }],
          password: [{ type: 'required', message: 'Preencha sua senha.' }],
        },
      },
    ];
  }
  clean() {
    this.loginForm[0].builder.reset();
  }

  onSubmit(event) {
    const data = { ...event[0].builder.value };
    this.isDisabled = true;
    this.auth
      .login(data)
      .then((res) => {
        this.isDisabled = false;
        this.clean();
        this.ENV.logged = true;
        if (res !== '') {
          this.router.navigate(['/admin']);
        }
      })
      .catch((err) => {
        console.log(err);
        this.isDisabled = false;
      });
  }
  performClick(el: MatButton) {
    el._elementRef.nativeElement.click();
    console.log(this.isDisabled);
  }
}
