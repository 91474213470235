import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
@Injectable({
	providedIn: 'root'
})
export class UrnaService {
    constructor(
        private http: HttpService
    ) {}

    getProfile(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.post('api/urna/getProfile', false, data).then(res => {
                resolve(res);
            })
              .catch(err => reject(err));
          });
    }

    getOne(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.post('api/urna/getOne', true, data).then(res => {
                resolve(res);
            })
            .catch(err => reject(err));
        });
    }
}
