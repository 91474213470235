import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import {
  Component,
  Output,
  Inject,
  Input,
  EventEmitter,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { UtilsProvider } from 'src/commons/utils';
import { ENV } from 'src/environments/environment-variables.token';
import { CurrencyPipe, DatePipe } from '@angular/common';
import * as moment from 'moment';
import { VaquinhaService } from 'src/providers/vaquinha.service';
import { PoliticalService } from 'src/providers/political.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { PaymentService } from 'src/providers/payments.service';

@Component({
  selector: 'app-form-vaquinha',
  templateUrl: './form-vaquinha.component.html',
  styleUrls: ['./form-vaquinha.component.scss'],
})
export class FormVaquinhaComponent implements OnInit {
  constructor(
    public utils: UtilsProvider,
    public formBuilder: FormBuilder,
    @Inject(ENV) public ENV: any,
    private datePipe: DatePipe,
    protected currencyPipe: CurrencyPipe,
    public vaquinhaProvider: VaquinhaService,
    public politicalProvider: PoliticalService,
    public paymentProvider: PaymentService
  ) {}

  ngOnInit(): void {
    this.vaquinhaForm();
    this.getPayment();
    // console.log("this.body.realVavaquinha", this.vaquinha)
    // console.log("this.body.realVavaquinha", this.data)
    // console.log("this.body.realVavaquinha", this.data.transparency)
  }

  @Input() data: any = {};
  @Output() Event = new EventEmitter<{}>();
  public shouldSkipPaymentMethod = false;
  // se esse IDS estão incluidos na vaquinha, o campo paymentTypes nao deve ser mostrado ao usuario
  public exclusivePaymentTypes = [
    '6435b18b0399122735e16ba5',
    '6435b19a0399122735e16baa',
    '6435b1b40399122735e16baf',
  ];
  public Editor = ClassicEditor;
  public model = '';
  public selectedPaymentTypes = [];
  public selectedTransparency: any = [];
  public formVaquinha: any;
  public socialMediaForm: any;
  public typeRef: any;
  public vaquinha: any;
  public paymentType: any;
  public getTypePayment: any = [];
  public meta: any = new FormControl('', Validators.compose([]));
  public valueDonation1: any = new FormControl(
    '',
    Validators.compose([Validators.required, this.minValueDonationVerify])
  );
  public valueDonation2: any = new FormControl(
    '',
    Validators.compose([Validators.required, this.minValueDonationVerify])
  );
  public valueDonation3: any = new FormControl(
    '',
    Validators.compose([Validators.required, this.minValueDonationVerify])
  );
  public valueDonation4: any = new FormControl(
    '',
    Validators.compose([Validators.required, this.minValueDonationVerify])
  );
  public formattedAmount: any;
  public selectedElectionPeriod: any = {};
  public paymentTypes = [];
  //   public transp = [
  //     {
  //       value: true,
  //       text: ' Botão transparência ativo (Ele serve para que outras pessoas possam ver quantas e quais pessoas já o doaram)',
  //       checked: true
  //     },
  // ];
  public configCkEditor = {
    toolbar: [
      'heading',
      '|',
      'fontfamily',
      'fontsize',
      'alignment',
      'fontColor',
      'fontBackgroundColor',
      'bold',
      'italic',
      'custombutton',
      'strikethrough',
      'underline',
      'subscript',
      'superscript',
      'link',
      'bulletedList',
      'numberedList',
      '|',
      'outdent',
      'indent',
      'code',
      'codeBlock',
      '|',
      // 'mediaEmbed',
      // 'imageUpload',
      'blockQuote',
      'insertTable',
      // 'youtube',
      'undo',
      'redo',
    ],
  };
  minValueDonationVerify(control: FormControl) {
    const value =
      control.value != null
        ? control.value.replaceAll('R$', '').replaceAll('.', '')
        : 0;
    const formatValue = parseInt(value);
    if (
      (typeof formatValue === 'number' && formatValue < 20) ||
      formatValue > 1064
    ) {
      return { valorMenorQue20: true };
    }
    return null;
  }

  async getPayment() {
    const payment = this.data.paymentTypes;
    if (this.data.edit) {
      payment.forEach((item) => {
        if (this.exclusivePaymentTypes.includes(item))
          this.shouldSkipPaymentMethod = true;
      });
      if (this.shouldSkipPaymentMethod) return;
    }

    const [err, getPayment] = await this.utils.tryCatch(
      this.paymentProvider.getPayment()
    );

    if (err) return console.log('getPayment() error,', err);

    getPayment.map((item) => {
      if (this.data.type == undefined) {
        this.data.type = this.data.typeRef.name;
      }
      if (this.data.type == 'Eleitoral' && item.type == 'eleitoral') {
        const value = {
          value: item._id,
          text: item.name,
          tax: item.tax,
          checked:
            payment != undefined && payment.includes(item._id) ? true : true,
        };
        this.selectedPaymentTypes.push(item._id);
        this.paymentTypes.push(value);
      } else if (this.data.type == 'Normal' && item.type == 'geral') {
        const value = {
          value: item._id,
          text: item.name,
          tax: item.tax,
          checked:
            payment != undefined && payment.includes(item._id) ? true : false,
        };
        this.paymentTypes.push(value);
      }
    });
  }

  social() {
    if (this.data.type == undefined) {
      this.data.type = this.data.typeRef.name;
    }

    var transp = [
      {
        value: true,
        text: ' Botão transparência ativo (Ele serve para que outras pessoas possam ver quantas e quais pessoas já o doaram)',
        checked: this.data.transparency === true ? true : false,
      },
    ];

    const transButton =
      this.data.type == 'Normal'
        ? [
            {
              name: 'transparency',
              type: 'checkbox',
              horizontal: true,
              label: '',
              cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
              options: {
                fields: {
                  value: 'value',
                  text: 'text',
                },
                items: transp,
              },
              clickCallback: (data, event) => {
                // console.log("dataaaaaaa", data)
                // console.log("eventttttt", event)
                if (event.checked === true) {
                  this.selectedTransparency.push(data.value);
                }
                if (event.checked === false) {
                  this.selectedTransparency.push(false);
                }
              },
            },
          ]
        : [];

    const fields = () =>
      new FormControl('', Validators.compose([Validators.required]));

    const facebook = fields();
    const instagram = fields();
    const twitter = fields();
    const youtube = fields();
    const youtubevideo = fields();
    const transparency = fields();
    this.socialMediaForm = [
      {
        fields: [
          {
            name: 'facebook',
            label: 'Facebook',
            type: 'urlInput',
            placeholder: 'URL',
            customString: 'facebook.com/',
            cols: 'col-lg-6 col-md-6 col-sm-12 col-xs-12',
            blurCallback() {},
          },
          {
            name: 'instagram',
            label: 'Instagram',
            type: 'urlInput',
            placeholder: 'URL',
            customString: 'instagram.com/',
            cols: 'col-lg-6 col-md-6 col-sm-12 col-xs-12',
            blurCallback() {},
          },
          {
            name: 'twitter',
            label: 'Twitter',
            type: 'urlInput',
            placeholder: 'URL',
            customString: 'twitter.com/',
            cols: 'col-lg-6 col-md-6 col-sm-12 col-xs-12',
            blurCallback() {},
          },
          {
            name: 'youtube',
            label: 'Canal Youtube (após o @)',
            type: 'urlInput',
            placeholder: 'URL',
            customString: 'youtube.com/@',
            cols: 'col-lg-6 col-md-6 col-sm-12 col-xs-12',
            blurCallback() {},
          },
          {
            name: 'youtubevideo',
            label: 'Vídeo do Youtube (link completo)',
            type: 'urlInput',
            placeholder: 'URL',
            customString: '',
            cols: 'col-lg-6 col-md-6 col-sm-12 col-xs-12',
            blurCallback() {},
          },
          ...transButton,
        ],
        builder: this.formBuilder.group({
          facebook: ['', null],
          instagram: ['', null],
          twitter: ['', null],
          youtube: ['', null],
          youtubevideo: ['', null],
          transparency,
        }),
        validation_messages: {
          facebook: [],
          instagram: [],
          twitter: [],
          youtube: [],
          youtubevideo: [],
          transparency: [],
        },
      },
    ];
  }

  getElectionPeriod() {
    return new Promise(async (resolve, reject) => {
      const [err, period] = await this.utils.tryCatch(
        this.politicalProvider.getElectionPeriod()
      );
      // console.log('eleitoral rep', period);
      if (err) reject(-1);
      resolve(period);
    });
  }

  async vaquinhaForm() {
    const self = this;
    if (this.data.type == undefined) {
      this.data.type = this.data.typeRef.name;
    }

    // if (this.data.transparency){
    //   var checked = true
    //   this.transp.push({checked})
    // }

    this.typeRef = this.data.edit ? this.data.type : this.data.type.text;

    let electionPeriod: any = [];
    if (this.data.type == 'Eleitoral') {
      electionPeriod = await this.getElectionPeriod();
      this.selectedElectionPeriod = electionPeriod;
      if (electionPeriod == null) {
        return this.Event.emit({
          message: 'Não existe período eleitoral, tente novamente mais tarde.',
        });
      }
    }
    const fields = () =>
      new FormControl('', Validators.compose([Validators.required]));

    const initAt = fields();
    const endAt = fields();
    const title = fields();
    const url = fields();
    const thankMessage = fields();
    const paymentTypes = fields();
    const period = fields();
    this.meta.valueChanges.subscribe((meta) => {
      if (meta) {
        this.meta.setValue(
          this.currencyPipe.transform(
            meta.replace(/\D/g, '').replace(/^0+/, ''),
            'BRL',
            'symbol',
            '1.0-0'
          ),
          { emitEvent: false }
        );
      }
    });
    this.valueDonation1.valueChanges.subscribe((valueDonation1) => {
      if (valueDonation1) {
        this.valueDonation1.setValue(
          this.currencyPipe.transform(
            valueDonation1.replace(/\D/g, '').replace(/^0+/, ''),
            'BRL',
            'symbol',
            '1.0-0'
          ),
          { emitEvent: false }
        );
      }
    });
    this.valueDonation2.valueChanges.subscribe((valueDonation2) => {
      if (valueDonation2) {
        this.valueDonation2.setValue(
          this.currencyPipe.transform(
            valueDonation2.replace(/\D/g, '').replace(/^0+/, ''),
            'BRL',
            'symbol',
            '1.0-0'
          ),
          { emitEvent: false }
        );
      }
    });
    this.valueDonation3.valueChanges.subscribe((valueDonation3) => {
      if (valueDonation3) {
        this.valueDonation3.setValue(
          this.currencyPipe.transform(
            valueDonation3.replace(/\D/g, '').replace(/^0+/, ''),
            'BRL',
            'symbol',
            '1.0-0'
          ),
          { emitEvent: false }
        );
      }
    });
    this.valueDonation4.valueChanges.subscribe((valueDonation4) => {
      if (valueDonation4) {
        this.valueDonation4.setValue(
          this.currencyPipe.transform(
            valueDonation4.replace(/\D/g, '').replace(/^0+/, ''),
            'BRL',
            'symbol',
            '1.0-0'
          ),
          { emitEvent: false }
        );
      }
    });

    const payment = this.data.edit
      ? this.data.paymentTypes
      : this.data.vaquinha.paymentTypes;

    // console.log('paument', payment);
    // this.paymentTypes.forEach((item) => {
    //   console.log('paument api', item);

    //   if (payment.includes(item.value)) {
    //     item.checked = true;
    //     this.selectedPaymentTypes.push(item.value);
    //   }
    // });
    const isEleitoral =
      this.data.type == 'Normal'
        ? [
            {
              name: 'initAt',
              type: 'datepicker',
              label: 'Data Inicial',
              cols: 'col-lg-3 col-md-3 col-sm-12 col-xs-12',
              blurCallback() {},
            },
            {
              name: 'endAt',
              type: 'datepicker',
              label: 'Data Final',
              cols: 'col-lg-3 col-md-3 col-sm-12 col-xs-12',
              blurCallback() {},
            },
          ]
        : [
            {
              name: 'period',
              type: 'select',
              selected: electionPeriod._id,
              disabled: true,
              label: 'Período eleitoral',
              cols: 'col-lg-3 col-md-3 col-sm-12 col-xs-12',
              options: {
                fields: {
                  value: '_id',
                  text: 'year',
                },
                items: [electionPeriod],
              },
              blurCallback() {},
              clickCallback: (_) => {},
            },
          ];
    if (this.data.edit) {
      payment.forEach((item) => {
        if (this.exclusivePaymentTypes.includes(item))
          this.shouldSkipPaymentMethod = true;
      });
    }
    const paymentForm = [];
    if (!this.shouldSkipPaymentMethod) {
      paymentForm.push({
        name: 'paymentTypes',
        type: 'checkbox',
        horizontal: true,
        label: 'Tipos de pagamentos permitidos',
        cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
        options: {
          fields: {
            value: 'value',
            text: 'text',
          },
          items: this.paymentTypes,
        },
        clickCallback: (data, event) => {
          console.log('finalvalueed dataValue', data.value);
          if (event.checked) {
            this.selectedPaymentTypes.push(data.value);
          } else {
            const index = this.selectedPaymentTypes.indexOf(data.value);
            this.selectedPaymentTypes.splice(index, 1);
          }
          console.log('finalvalueed maked', this.selectedPaymentTypes);
        },
      });
    }

    this.formVaquinha = [
      {
        fields: [
          {
            name: 'title',
            type: 'text',
            label: 'Nome de Urna',
            placeholder: 'Como o candidato é chamado',
            cols:
              this.data.type == 'Normal'
                ? 'col-lg-6 col-md-6 col-sm-12 col-xs-12'
                : 'col-lg-4 col-md-4 col-sm-12 col-xs-12',
            blurCallback() {},
          },
          {
            name: 'thankMessage',
            type: 'text',
            label: 'Mensagem de agradecimento ( após a doação )',
            placeholder: 'mensagem',
            cols:
              this.data.type == 'Normal'
                ? 'col-lg-6 col-md-6 col-sm-12 col-xs-12'
                : 'col-lg-5 col-md-5 col-sm-12 col-xs-12',
            blurCallback() {},
          },
          ...isEleitoral,
          {
            name: 'meta',
            type: 'text',
            label: 'Digite a meta a ser atingida',
            placeholder: 'R$ 00,00',
            cols: 'col-lg-6 col-md-6 col-sm-12 col-xs-12',
            blurCallback() {},
            onChange(e) {},
          },
          {
            name: 'url',
            type: 'urlInput',
            label: 'URL Vaquinha',
            placeholder: 'vaquinha',
            customString: 'www.queroapoiar.com.br/',
            cols: 'col-lg-6 col-md-6 col-sm-12 col-xs-12',
            blurCallback() {},
          },
          ...paymentForm,
          {
            name: 'valueDonation1',
            type: 'text',
            // invisible: false,
            label: 'Valor sugerido',
            placeholder: 'R$ 20,00',
            cols: 'col-lg-3 col-md-3 col-sm-12 col-xs-12',
            blurCallback() {},
            onChange(e) {},
          },
          {
            name: 'valueDonation2',
            type: 'text',
            // invisible: true,
            label: '.',
            placeholder: 'R$ 40,00',
            cols: 'col-lg-3 col-md-3 col-sm-12 col-xs-12',
            blurCallback() {},
            onChange(e) {},
          },
          {
            name: 'valueDonation3',
            type: 'text',
            // invisible: true,
            label: '.',
            customString: ' ',
            placeholder: 'R$ 50,00',
            cols: 'col-lg-3 col-md-3 col-sm-12 col-xs-12',
            blurCallback() {},
            onChange(e) {},
          },
          {
            name: 'valueDonation4',
            type: 'text',
            // invisible: true,
            label: '.',
            customString: ' ',
            placeholder: 'R$ 100,00',
            cols: 'col-lg-3 col-md-3 col-sm-12 col-xs-12',
            blurCallback() {},
            onChange(e) {},
          },
        ],
        builder: this.formBuilder.group({
          title,
          initAt,
          endAt,
          period,
          url,
          thankMessage,
          paymentTypes,
          valueDonation1: this.valueDonation1,
          valueDonation2: this.valueDonation2,
          valueDonation3: this.valueDonation3,
          valueDonation4: this.valueDonation4,
          meta: this.meta,
        }),
        validation_messages: {
          title: [{ type: 'required', message: 'Preencha o campo descrição.' }],
          // endAt: [
          //   { type: 'required', message: 'Preencha o campo data de início.' },
          // ],
          // initAt: [
          //   { type: 'required', message: 'Preencha o campo data final.' },
          // ],
          paymentTypes: [
            {
              type: 'required',
              message: 'Selecione o tipo de pagamento.',
            },
          ],
          url: [
            {
              type: 'required',
              message: 'Preencha o campo da URL da vaquinha.',
            },
          ],
          thankMessage: [
            {
              type: 'required',
              message: 'Digite uma mensagem de agradecimento.',
            },
          ],
          period: [],
          // meta: [
          //   {
          //     type: 'required',
          //     message: 'Digite a meta a ser atingida.',
          //   },
          // ],
          valueDonation1: [
            {
              type: 'valorMenorQue20',
              message: 'O valor tem que ser entre R$20,00 e R$1064,00',
            },
          ],
          valueDonation2: [
            {
              type: 'valorMenorQue20',
              message: 'O valor tem que ser entre R$20,00 e R$1064,00',
            },
          ],
          valueDonation3: [
            {
              type: 'valorMenorQue20',
              message: 'O valor tem que ser entre R$20,00 e R$1064,00',
            },
          ],
          valueDonation4: [
            {
              type: 'valorMenorQue20',
              message: 'O valor tem que ser entre R$20,00 e R$1064,00',
            },
          ],
        },
      },
    ];
    this.social();
    this.setDefaultValue();
  }

  setDefaultValue() {
    this.vaquinha = this.data.edit ? this.data : this.data.vaquinha;

    const form = this.formVaquinha[0].builder.controls;
    form.valueDonation1.value = 'R$ ' + 20 || '';
    form.valueDonation2.value = 'R$ ' + 40 || '';
    form.valueDonation3.value = 'R$ ' + 50 || '';
    form.valueDonation4.value = 'R$ ' + 100 || '';

    const socialMedia = this.socialMediaForm[0].builder.controls;
    form.url.value = this.vaquinha.url;
    form.thankMessage.value = this.vaquinha.thankMessage;
    form.title.value = this.vaquinha.title;
    if (this.vaquinha.valueDonation) {
      form.valueDonation1.value = 'R$ ' + this.vaquinha.valueDonation[0] || '';
      form.valueDonation2.value = 'R$ ' + this.vaquinha.valueDonation[1] || '';
      form.valueDonation3.value = 'R$ ' + this.vaquinha.valueDonation[2] || '';
      form.valueDonation4.value = 'R$ ' + this.vaquinha.valueDonation[3] || '';
    }
    socialMedia.facebook.value = this.vaquinha.socialMedias.facebook;
    socialMedia.instagram.value = this.vaquinha.socialMedias.instagram;
    socialMedia.twitter.value = this.vaquinha.socialMedias.twitter;
    socialMedia.youtube.value = this.vaquinha.socialMedias.youtube;
    socialMedia.youtubevideo.value = this.vaquinha.socialMedias.youtubevideo;

    this.model = this.vaquinha.description;
    if (parseFloat(this.vaquinha.meta)) {
      form.meta.value = Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(parseFloat(this.vaquinha.meta));
    }
    if (this.data.type == 'Normal') {
      if (parseFloat(this.vaquinha.meta)) {
        form.meta.value = Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(parseFloat(this.vaquinha.meta));
      }
      // console.log('body date', this.vaquinha.initAt);
      form.initAt.value = this.datePipe.transform(
        this.vaquinha.initAt,
        'yyyy-MM-dd'
      );
      form.endAt.value = this.datePipe.transform(
        this.vaquinha.endAt,
        'yyyy-MM-dd'
      );
    }
  }

  back() {
    this.Event.emit({
      back: true,
    });
  }

  async next() {
    const form = this.formVaquinha[0].builder;
    const socialMedias = {
      facebook: this.socialMediaForm[0].builder.controls.facebook.value,
      instagram: this.socialMediaForm[0].builder.controls.instagram.value,
      twitter: this.socialMediaForm[0].builder.controls.twitter.value,
      youtube: this.socialMediaForm[0].builder.controls.youtube.value,
      youtubevideo: this.socialMediaForm[0].builder.controls.youtubevideo.value,
    };

    const isEleitoral =
      this.data.type == 'Normal'
        ? [
            // { field: 'meta', message: 'Prencha o campo de meta.' },
            { field: 'endAt', message: 'Preencha o campo data final.' },
            { field: 'initAt', message: 'Preencha o campo data de início.' },
          ]
        : [];

    const verify: any = [
      {
        field: 'description',
        message: 'A descrição deve ter no mínimo 100 caracteres.',
        manual: true,
      },
      !this.shouldSkipPaymentMethod
        ? {
            field: 'paymentTypes',
            message: 'Selecione o tipo de pagamento.',
            manual: true,
          }
        : null,
      {
        field: 'thankMessage',
        message: 'Digite uma mensagem de agradecimento.',
      },
      { field: 'url', message: 'Selecione o final da URL da vaquinha.' },
      ...isEleitoral,
      { field: 'title', message: 'Preencha o campo título.' },
      {
        field: 'valueDonation1',
        message: 'O valor tem que ser entre R$20,00 e R$1064,00',
      },
      {
        field: 'valueDonation2',
        message: 'O valor tem que ser entre R$20,00 e R$1064,00',
      },
      {
        field: 'valueDonation3',
        message: 'O valor tem que ser entre R$20,00 e R$1064,00',
      },
      {
        field: 'valueDonation4',
        message: 'O valor tem que ser entre R$20,00 e R$1064,00',
      },
    ].filter((item) => item);

    const ok: Array<any> = verify.map((item) => {
      if (item.manual) {
        if (
          this.selectedPaymentTypes.length == 0 &&
          item.field == 'paymentTypes'
        ) {
          this.utils.toast({
            message: item.message,
            action: 'Erro',
          });
          return true;
        }
        if (this.model.length <= 100 && item.field == 'description') {
          this.utils.toast({
            message: item.message,
            action: 'Erro',
          });
          return true;
        }
      } else if (form.controls[`${item.field}`]['invalid']) {
        form.controls[`${item.field}`]['touched'] = true;
        this.utils.toast({
          message: item.message,
          action: 'Erro',
        });
        return true;
      }
    });

    let [err, checkURL]: any = ['', ''];
    if (this.data.edit && this.data.url != form.value.url) {
      [err, checkURL] = await this.utils.tryCatch(
        this.vaquinhaProvider.getURL(form.value.url)
      );
    } else {
      checkURL = { message: 'ok' };
    }
    if (!this.data.edit) {
      [err, checkURL] = await this.utils.tryCatch(
        this.vaquinhaProvider.getURL(form.value.url)
      );
    }

    if (checkURL.message != 'ok') {
      // form.value.url = '';
      return this.utils.toast({
        message: 'Essa URL já existe, por favor selecione outra',
        action: '❌',
      });
    }
    let valueDonations = [];
    if (
      form.value.valueDonation1 ||
      form.value.valueDonation2 ||
      form.value.valueDonation3 ||
      form.value.valueDonation4
    ) {
      const verify = (value) => {
        if (value.includes('R$')) {
          return true;
        }
        return false;
      };
      valueDonations = [
        parseInt(
          form.value.valueDonation1
            .trim()
            .split('R$')[1]
            .replaceAll(/[.,]/g, '')
        ) ?? null,
        parseInt(
          form.value.valueDonation2
            .trim()
            .split('R$')[1]
            .replaceAll(/[.,]/g, '')
        ) ?? null,
        parseInt(
          form.value.valueDonation3
            .trim()
            .split('R$')[1]
            .replaceAll(/[.,]/g, '')
        ) ?? null,
        parseInt(
          form.value.valueDonation4
            .trim()
            .split('R$')[1]
            .replaceAll(/[.,]/g, '')
        ) ?? null,
      ];
    }
    if (this.selectedPaymentTypes.length == 0) {
      this.selectedPaymentTypes = this.vaquinha.paymentTypes;
    }

    if (this.vaquinha && this.selectedTransparency[0] == undefined) {
      this.selectedTransparency[0] = this.vaquinha.transparency;
    }

    if (this.selectedTransparency[0] == undefined) {
      this.selectedTransparency[0] = false;
    }

    const finalValue = {
      ...form.value,
      valueDonation: valueDonations,
      socialMedias,
      description: this.model,
      paymentTypes: this.selectedPaymentTypes,
      transparency: this.selectedTransparency[0],
    };

    if (
      !form.value.valueDonation1 ||
      !form.value.valueDonation2 ||
      !form.value.valueDonation3 ||
      !form.value.valueDonation4
    ) {
      finalValue.valueDonation = [20, 40, 50, 100];
      delete finalValue.valueDonation1;
      delete finalValue.valueDonation2;
      delete finalValue.valueDonation3;
      delete finalValue.valueDonation4;
    }

    if (finalValue.meta) {
      let value = finalValue.meta
        .trim()
        .split('R$')[1]
        .replaceAll('.', '')
        .replaceAll(/[,]/g, '.');
      finalValue.meta = value;
      console.log('metavaluee', value);
    }

    if (
      this.selectedElectionPeriod.hasOwnProperty('initAt') &&
      this.selectedElectionPeriod.hasOwnProperty('endAt')
    ) {
      finalValue.initAt = this.selectedElectionPeriod.initAt;
      finalValue.endAt = this.selectedElectionPeriod.endAt;
    }

    if (this.data.edit && this.data.name == 'Normal') {
      if (
        moment(this.data.initAt).toDate().getTime() ==
        new Date(finalValue.initAt).getTime()
      )
        delete finalValue.initAt;
      if (
        moment(this.data.endAt).toDate().getTime() ==
        new Date(finalValue.endAt).getTime()
      )
        delete finalValue.endAt;
    } else {
      if (
        moment(this.data.initAt).toDate().getTime() ==
        moment(finalValue.initAt).toDate().getTime()
      )
        delete finalValue.initAt;
      if (
        moment(this.data.endAt).toDate().getTime() ==
        moment(finalValue.endAt).toDate().getTime()
      )
        delete finalValue.endAt;
    }

    if (!ok.includes(true)) {
      this.Event.emit(finalValue);
    }
  }
}
