<div class="home-content">
    <div class="content">
        <h1 class="textMain col-md-6">Receba <strong> doações</strong> agora.</h1>
        <br>
        <button class="button" (click)="createVaq()">
            <div class="insideButton">
                <i class="fa  fa-thumbs-up icon"></i> <span class="paddingText">Criar vaquinha</span>
            </div>
        </button>
        <br>
        <div class="content2 text">
                <div>
                    <h3 class="money"> {{ totalVaquinha.amountVaquinha }}</h3>
                    <span class="font">Projetos ajudados</span>
                </div>
                <div>
                    <h3 class="money">{{ totalVaquinha.total| currency : 'R$'  }}</h3>
                    <span class="font">Total arrecadado</span>
                </div>
                <div>
                    <h3 class="money">{{ totalVaquinha.amountDonation }}</h3>
                    <span class="font">Doações recebidas</span>
                </div>
        </div>
    </div>
    <div class="container-card">


    <div class="card">
    <div  *ngFor= "let i of vaquinha">
        <div class="second-card">
            <div class="img"
                style="background-image: url({{ i.cover.host + i.cover.full }})">
            </div>
            <div class="card-description">
                <div class="cardImage">
                        <div class="percent">
                            <img src="../../assets/porquinho.png" >
                            <svg>
                                <circle cx="15" cy="15" r="15"></circle>
                                <circle cx="15" cy="15" r="15"></circle>
                            </svg>
                        </div>
                </div>
                <h1>{{ i.title }}</h1>
                <div class="porcent">
                    <div>
                        <p>{{ i.hitTarget > 100 ? 100 : i.hitTarget}}%</p>
                    </div>
                    <div class="barra">
                        <div style="width: {{i.hitTarget > 100 ? 100 : i.hitTarget}}%"></div>
                    </div>
                </div>
                <div class="text-meta">
                    <div>
                        <p class="meta">meta <span>{{ i.meta| currency : 'R$'  }}</span></p>
                    </div>
                    <div *ngIf="i.amountDonation && i.amountDonation != 0; else elseBlock">
                        <p class="donate">{{ i.amountDonation }} pessoas <span>já doaram</span></p>
                    </div>
                    <ng-template #elseBlock>
                    <div>
                        <p class="donate"><span>Sejá</span> o primeiro <span>á doar!</span></p>
                    </div>
                </ng-template>
                </div>
                    <button class="redirect-button" (click)="navigate(i)"><img class="icon2"
                    src="../../assets/porquinho2.png" > &nbsp;Doar agora!</button>
            </div>
        </div>
    </div>
  </div>
</div>
<div (click)="expand()" *ngIf="!finalSearch" class="showMore">

    <span class="input-group-addon material-icons">keyboard_arrow_down</span>

    <a>Ver Mais</a>
</div>
</div>
