import { Component, OnInit } from "@angular/core";

@Component({
  selector: "fale-conosco-modal",
  templateUrl: "./fale-conosco-modal.component.html",
  styleUrls: ["../app.component.scss", "./fale-conosco-modal.component.scss"]
})
export class FaleConoscoModalComponent implements OnInit {
  public title: string;
  public content: string;

  constructor() { }

  ngOnInit() {
	// this.title = localStorage.getItem("err-title") || "pagina não encontrada!";
	// this.content = localStorage.getItem("err-content") || "verifique a url e tente novamente.";
  }

}
