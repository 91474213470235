import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { DefaultFooterComponent } from './default-footer/default-footer';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [DefaultFooterComponent],
    imports: [
        CommonModule,
        MatToolbarModule,
        RouterModule
    ],
    exports: [DefaultFooterComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})

export class FooterModule {}