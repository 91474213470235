import { Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs';
@Injectable({
	providedIn: "root"
})
export class ScrollService {
	private _navItemSource = new BehaviorSubject<string>('');
	navItem$ = this._navItemSource.asObservable();
	constructor(
	) { }

	changeNav(string) {
		this._navItemSource.next(string);
	}
}