import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CandidateService } from '../../providers/candidate.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UtilsProvider } from '../../commons/utils';
import { FaleConoscoModalComponent } from "../fale-conosco-modal/fale-conosco-modal.component";
import { ModalDefaultComponent } from "../modal-default/modal-default.component";
import { ENV } from 'src/environments/environment-variables.token';

@Component({
	selector: 'app-donators-list',
	templateUrl: './donators-list.component.html',
	styleUrls: ['./donators-list.component.scss']
})
export class DonatorsListComponent implements OnInit {
	name: string;
	cpf: number;
	date: string;
	donationMethod: string;
	value: string;
	candidato: any;

	public donationsList: any;

	public tableLabels = {
		name: 'Nome',
		cpf: 'CPF',
		date: 'Data',
		donationMethod: 'Forma de Doação',
		value: 'Valor',
		candidate: 'Candidato'
	};

	constructor(
		private candidateService: CandidateService,
		private activatedRoute: ActivatedRoute,
		public dialog: MatDialog,
		private router: Router,
		private utils: UtilsProvider,
		@Inject(ENV) public ENV: any,
	) {

	}

	ngOnInit() {
		window.scrollTo(0, 0)
		this.ENV.active = 'list'
		this.candidato = JSON.parse(localStorage.getItem('user'));
		console.log("Candidato: ", this.candidato)
		this.candidateService.getDonationsByUser({ user: this.candidato.user._id }).then((res) => {
			console.log("GROUPED: ", res)
			this.donationsList = res;
		}).catch(e => {
			this.utils.toast({ message: 'Não foi possível buscar informações do candidato' });
		});

	}

	public showDonate(donate: any) {
		console.log(donate)


		let formaPagamento
		if (donate.type == 'credito') formaPagamento = "Crédito"
		else if (donate.type == 'debito') formaPagamento = "Débito"
		else if (donate.type == 'boleto') formaPagamento = "Boleto"

		const candidate = donate.userRef
		const params = {
			candidateEmail: candidate.email,
			userEmail: donate.donatorInfo.Email,
			candidateCpf: candidate.cpf,
			userCpf: donate.donatorInfo.Identity,
			partido: candidate.partido,
			cargo: candidate.cargo,
			candidateName: candidate.name,
			userName: donate.donatorInfo.nomecompleto ? donate.donatorInfo.nomecompleto : donate.donatorInfo.Name,
			value: "R$" + String(donate.donationValue).replace(".", ","),
			date: donate.updatedAt,
			pagamento: formaPagamento,
			//error: donate.paymentInfo.ResponseDetail.Description
		}

		var date = new Date(params.date).toLocaleDateString() + " - " +
			new Date(params.date).toLocaleTimeString()
		var template;
		if (params.candidateName) template = `<p><b>Candidato(a):</b> ${params.candidateName}</p>`
		if (params.candidateCpf) template += `<p><b>CPF do Candidato:</b> ${params.candidateCpf}</p>`
		if (params.partido) template += `<p><b>Partido:</b> ${params.partido}</p>`
		if (params.cargo) template += `<p><b>Cargo:</b> ${params.cargo}</p>`
		if (params.userName) template += `<p><b>Nome do Doador:</b> ${params.userName}</p>`
		if (params.userCpf) template += `<p><b>CPF do Doador:</b> ${params.userCpf}</p>`
		if (params.date) template += `<p><b>Data:</b> ${date}</p>`
		if (params.value) template += `<p><b>Valor:</b> ${params.value}</p>`
		if (params.pagamento) template += `<p><b>Forma de Pagamento:</b> ${params.pagamento}</p>`

		const dialogRef = this.dialog.open(ModalDefaultComponent, {
			autoFocus: false,
			data: {
				title: "Dados do Recibo",
				message: template
			}
		});

		dialogRef.afterClosed().subscribe(result => {

		});
	}

	getName(donator: any) {
		return String(donator.donatorInfo.Name).toLowerCase()
	}
}


