import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
@Injectable({
	providedIn: 'root'
})
export class PagseguroService {
    constructor(
        private http: HttpService
    ) {}

    createRequest(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.get('api/pagamentos/getSession', false).then(res => {
                resolve(res);
            })
            .catch(err => reject(err));
        });
    }
    // createRequest(): Promise<any> {
    //     return new Promise((resolve, reject) => {
    //     //    /sessions/?email=info@moux.com.br&token=F71A5BEED454473296B33A62A0D7D918
    //         this.http.post('sessions/?email=info@moux.com.br&token=F71A5BEED454473296B33A62A0D7D918', false, {}, 'https://ws.sandbox.pagseguro.uol.com.br/v2').then(res => {
    //             resolve(res);
    //         })
    //         .catch(err => reject(err));
    //     });
    // }
}
