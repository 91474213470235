<div class="form">
  <default-form  [forms]="formVaquinha" [step]="0"></default-form>
  <default-form [forms]="socialMediaForm" [step]="0"></default-form>
  <div class="card-body">
    <span>Descrição</span>
    <ckeditor [editor]="Editor" [(ngModel)]="model"
    [config]="configCkEditor"
    ></ckeditor>
    <br>
  </div>
</div>
<div class="btn-container">
    <button mat-flat-button class="btn1"  (click)="back()">Voltar</button>

    <button mat-flat-button class="btn2"  (click)="next()">Continuar</button>
</div>
