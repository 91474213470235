<div class="all-content">

    
        <mat-card class="col-lg-6 col-lg-offset-3 col-md-6 col-md-offset-3 col-sm-12 col-xs-12">
            <div class="form">
                <div #cadastro class="header">
                    <img class="icon" src="../../assets/cclogo.png" /><br>
                    <h1>
                        Conheça nossa solução para doações presenciais e maquininha!
                    </h1>
                </div>
                <p>Comece a arrecadar recursos para a sua campanha com doações presenciais utilizando maquininha de cartão de crédito e débito!</p>

                <p>O processo é muito simples, faça sua solicitação agora e <b>entraremos em contato por Whatsapp.</b></p>  
                <h3>Taxas</h3>
                <div class="row boxtaxas">
                    
                    <div class="center col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <h2>4,99%</h2>
                        <span>Por transação</span>
                    </div>
                     <div class="center col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <h2>R$799,00</h2>
                        <span>Taxa de adesão</span>
                    </div>                   
                </div>
                <h3>Dúvidas frequentes</h3>
                <ul>
                    <li>É necessário ser cadastrado no QueroApoiar para solicitar a adesão à solução de doações presenciais</li>
                    <li>Devido a alta demanda, os pedidos serão analisados e respondidos individualmente por Whatsapp</li>
                    <li>Toda transação realizada possui taxa única de 4,99%.</li>
                    <li>Toda doação realizada precisa ser cadastrada no Painel de Administração, para isso, é necessário que o operador das maquininhas possua um celular com acesso à internet para efetuar o registro das transações</li>
                    <li>Doações recebidas via maquininha podem demorar até 72 horas úteis para constar no painel de doações</li>
                    <li>Doações não identificadas serão canceladas e estornadas ao doador</li>
                    <li>As maquininhas deverão ser devolvidas ao final do período eleitoral</li>
                    <li>O frete do envio da maquininha está incluso na taxa de adesão</li>
                </ul>
                <h3>Faça parte e receba sua maquininha</h3>
                <default-form (keyup.enter)="performClick(enviar)" [forms]="contatoForm" [step]="contatoFormStep"></default-form>
                <div class="btn-container">
                    <button #enviar [disabled]="isDisabled" mat-flat-button class="btn" (click)="onSubmit(contatoForm)">Tenho interesse</button>
                </div>
            </div>
        </mat-card>
</div>